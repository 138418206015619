import { ADD_NEW_COMMUNITY, REMOVE_COMMUNITY } from "../constants/communityConstants";

export const addComunity = (community) => async (dispatch, getState) => {
  dispatch({
    type: ADD_NEW_COMMUNITY,
    payload: community,
  });
  localStorage.setItem(
    "gsprklCommunities",
    JSON.stringify(getState().community.communities)
  );
};
export const removeComunity = (communityId) => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_COMMUNITY,
    payload: communityId,
  });
  localStorage.setItem(
    "gsprklCommunities",
    JSON.stringify(getState().community.communities)
  );
};
