export const validateUserProfile = (
  username,
  gender,
  age,
  languageKnow,
  languageInterested,
  country,
  identity
) => {
  let errors = {};

  if (username.trim() === "") {
    errors.username = "Username should not be empty";
  }

  if (gender.trim() === "") {
    errors.gender = "Please choose your gender";
  }

  if (age.trim() === "") {
    errors.age = "Age should not be empty";
  }

  if (!languageKnow.length) {
    errors.languageKnow = "Please choose languages that you know";
  }
  if (!languageInterested.length) {
    errors.languageInterested = "Please choose languages that you interested in";
  }

  if (country.trim() === "") {
    errors.country = "Country must not be empty";
  }

  if (identity.trim() === "") {
    errors.identity = "Please choose your identity";
  }

  return {
    errors,
    valid: Object.keys(errors).length < 1,
  };
};
