// import { useParams } from "react-router-dom";
import connections from "../../utils/connections.json";
import SearchCard from "../../components/SearchCard";
// import { useParams } from "react-router";
import "./Search.css";

const Search = () => {
  // let { search } = useParams();

  return (
    <div>
      <div className="search__container">
        {connections.map((connection) => (
          <SearchCard connection={connection} key={connection.id} />
        ))}
      </div>
    </div>
  );
};

export default Search;
