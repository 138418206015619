import { useState } from "react";
import { useHistory } from "react-router-dom";
import { truncate } from "../../utils/truncate";
import moment from "moment";
import UpgradePremiumPopup from "../UpgradePremiumPopup";
// import ChannelDropdownDetails from "../ChannelDropdownDetails";
import "./ContentCard.css";
const ContentCard = ({ id, img, title, channelName, premium, publishedAt }) => {
  const [upgradePremium, setUpgradePremium] = useState(false);
  const history = useHistory();

  const handleClick = () => {
    if (!premium) {
      history.push(`/videos/watch/${id}`);
    } else {
      setUpgradePremium(true);
    }
  };

  return (
    <div className="contentCard">
      <div className="contentCard__img" onClick={handleClick}>
        <div className={`contentCard__mainImg ${premium && "premium-video"}`}>
          <img src={img} alt={title} className="contentCard__thumbnail" />
        </div>
        <div className="contentCard__about flex-row">
          <small>2.6M views</small>
          <small>
            {publishedAt ? moment(publishedAt).fromNow() : "3 days ago"}
          </small>
        </div>
      </div>
      <div className="contentCard__desc">
        <h3 className="contentCard__descHeader" onClick={handleClick}>
          {truncate(title, 55)}
        </h3>
        <div className="contentCard__descChannel flex-row">
          <small>{channelName}</small>
          <svg
            width="14"
            height="14"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.45898 13L11.7292 18.2708L22 8"
              stroke="#077aff"
              strokeWidth="3.54167"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>
      {upgradePremium && (
        <UpgradePremiumPopup closePopup={() => setUpgradePremium(false)} />
      )}
    </div>
  );
};

export default ContentCard;
