import { TOPIC_ADD } from "../constants/topicConstants";

export const topicReducer = (state = { topicVideos: [] }, action) => {
  switch (action.type) {
    case TOPIC_ADD:
      return {
        topicVideos: action.payload,
      };
    default:
      return state;
  }
};
