import { useState } from "react";
import "./Gift.css";

const Gift = () => {
  const [giftOnetime, setGiftOnetime] = useState("first");

  return (
    <div className="flex-column gift">
        
      <div className="logo-container rounded">
        <svg viewBox="0 0 65 58" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M32.634 34.7621C35.7903 34.7621 38.349 32.2034 38.349 29.0471C38.349 25.8907 35.7903 23.332 32.634 23.332C29.4776 23.332 26.9189 25.8907 26.9189 29.0471C26.9189 32.2034 29.4776 34.7621 32.634 34.7621Z" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M32.6342 17.4297C40.3074 17.4297 47.4356 18.5307 52.8103 20.381C59.2859 22.6104 63.2674 25.9896 63.2674 29.0495C63.2674 32.2381 59.0478 35.8281 52.0938 38.1321C46.8364 39.874 39.918 40.7833 32.6342 40.7833C25.1663 40.7833 18.0946 39.9298 12.778 38.113C6.05145 35.8139 2 32.1774 2 29.0495C2 26.0145 5.80151 22.6613 12.1862 20.4354C17.5808 18.5545 24.885 17.4297 32.6332 17.4297H32.6342Z"
            stroke-width="2.73408"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.5184 23.2716C26.3518 16.6246 30.8665 10.9997 35.1544 7.26811C40.3207 2.77214 45.2372 1.01139 47.8877 2.53986C50.6501 4.1328 51.6519 9.58171 50.1738 16.7566C49.0573 22.1813 46.3877 28.6284 42.7489 34.9383C39.018 41.4075 34.746 47.1072 30.5165 50.8041C25.1646 55.4826 19.9903 57.1757 17.2807 55.613C14.6515 54.0979 13.646 49.1287 14.9073 42.4857C15.973 36.8728 18.6475 29.9835 22.5172 23.2715L22.5184 23.2716Z"
            stroke-width="2.73408"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.5286 34.9557C18.6844 28.3162 16.0671 21.5944 14.9746 16.0159C13.6595 9.29465 14.5893 4.15572 17.2375 2.62281C19.9971 1.02519 25.2181 2.87845 30.696 7.74237C34.8377 11.4197 39.0905 16.9513 42.74 23.2552C46.4815 29.7182 49.2858 36.266 50.3767 41.7765C51.7571 48.7499 50.6399 54.0781 47.9328 55.6452C45.3061 57.1658 40.4995 55.5559 35.3742 51.1453C31.0438 47.419 26.4108 41.6611 22.5286 34.9556V34.9557Z"
            stroke-width="2.73408"
          />
        </svg>
      </div>
      <h1>Select a gift membership</h1>
      <div>
        <div className="flex-row gift__card">
          <div>
            <h3>You're a good teacher</h3>
            <p>
              Send an one time gift to appreciate the priceless values, provided
              by the teacher you benefited form
            </p>
          </div>
          <div className="gift__radio flex-column">
            <label htmlFor="gift-onetime-first" className="flex-row">
              <input
                type="radio"
                className="gift__radioInput"
                id="gift-onetime-first"
                onChange={() => setGiftOnetime("first")}
                checked={giftOnetime === "first"}
                name="gift"
                data-value={0.99}
              />
              <span className="gift__radioControl"></span>
              <div className="gift__radioText flex-row">
                <strong>$0.99 </strong> / One time
              </div>
            </label>
            <label htmlFor="gift-onetime-second" className="flex-row">
              <input
                type="radio"
                className="gift__radioInput"
                id="gift-onetime-second"
                onChange={() => setGiftOnetime("second")}
                checked={giftOnetime === "second"}
                name="gift"
                data-value={4.99}
              />
              <span className="gift__radioControl"></span>
              <div className="gift__radioText flex-row">
                <strong>$4.99 </strong> / One time
              </div>
            </label>
          </div>
        </div>
        <div className="flex-row gift__card">
          <div>
            <h3>I'm your die hard friend fan</h3>
            <p>
              Receive exclusive perks from @username such as access to private
              communities, meetings and more.
            </p>
          </div>
          <div className="gift__radio flex-column">
            <label htmlFor="gift-month-first" className="flex-row">
              <input
                type="radio"
                className="gift__radioInput"
                id="gift-month-first"
                onChange={() => setGiftOnetime("third")}
                checked={giftOnetime === "third"}
                name="gift"
                data-value={4.99}
              />
              <span className="gift__radioControl"></span>
              <div className="gift__radioText flex-row">
                <strong>$0.99 </strong> / Month
              </div>
            </label>
            <label htmlFor="gift-month-two" className="flex-row">
              <input
                type="radio"
                className="gift__radioInput"
                id="gift-month-two"
                onChange={() => setGiftOnetime("fourth")}
                checked={giftOnetime === "fourth"}
                name="gift"
                data-value={0.99}
              />
              <span className="gift__radioControl"></span>
              <div className="gift__radioText flex-row">
                <strong>$4.99 </strong> / Month
              </div>
            </label>
          </div>
        </div>
      </div>
      <p className="gift__term">
        The one time payments are non refundable. But when it comes to the
        monthly plan, you can cancel the membership plan at any time you want.
      </p>
      <button className="btn primary">Send Gift</button>
    </div>
  );
};

export default Gift;
