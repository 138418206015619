import { useState } from "react";
import "./SettingPassword.css";

const SettingPassword = () => {
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [twoStepAuth, setTwoStepAuth] = useState(false);

  return (
    <div className="settingPassword">
      <form className="settingPassword__formContainer">
        <div className="settingPassword__profileInputSections flex-row">
          <div className="input__container flex-column">
            <input
              type={showPassword ? "text" : "password"}
              id="current-password"
              value={password}
              className="input"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label htmlFor="current-password" className="input__label">
              Current Password
            </label>
            <svg
              class={`show-password-icon ${showPassword && "hide"}`}
              width="20"
              height="20"
              viewBox="0 0 48 48"
              onClick={() => setShowPassword(true)}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.0055 8.00098C32.2775 8.00698 39.7055 13.805 43.8775 23.513C44.0415 23.891 44.0415 24.319 43.8775 24.697C39.7075 34.407 32.2775 40.205 24.0055 40.211H23.9935C15.7215 40.205 8.2935 34.407 4.1215 24.697C3.9595 24.319 3.9595 23.891 4.1215 23.513C8.2935 13.805 15.7235 8.00698 23.9935 8.00098H24.0055ZM23.9995 11.001C17.1275 11.003 10.8595 15.889 7.1395 24.105C10.8595 32.323 17.1255 37.209 23.9995 37.211C30.8735 37.209 37.1395 32.323 40.8595 24.105C37.1395 15.889 30.8735 11.003 23.9995 11.001ZM23.9989 16.2826C28.3129 16.2826 31.8229 19.7926 31.8229 24.1066C31.8229 28.4186 28.3129 31.9266 23.9989 31.9266C19.6849 31.9266 16.1769 28.4186 16.1769 24.1066C16.1769 19.7926 19.6849 16.2826 23.9989 16.2826ZM23.9989 19.2826C21.3389 19.2826 19.1769 21.4466 19.1769 24.1066C19.1769 26.7646 21.3389 28.9266 23.9989 28.9266C26.6589 28.9266 28.8229 26.7646 28.8229 24.1066C28.8229 21.4466 26.6589 19.2826 23.9989 19.2826Z"
              />
            </svg>
            <svg
              class={`hide-password-icon ${!showPassword && "hide"}`}
              width="20"
              height="20"
              onClick={() => setShowPassword(false)}
              viewBox="0 0 49 49"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M41.0994 6.64311C41.6854 7.22911 41.6854 8.17911 41.0994 8.76511L9.55145 40.3111C9.25945 40.6051 8.87545 40.7511 8.49145 40.7511C8.10745 40.7511 7.72345 40.6051 7.43145 40.3111C6.84545 39.7251 6.84545 38.7771 7.43145 38.1911L11.3753 34.2496C8.54771 31.6633 6.14908 28.1769 4.38765 24.0715C4.22365 23.6915 4.22365 23.2635 4.38765 22.8855C6.42965 18.1575 9.30565 14.2435 12.7056 11.5695C19.2913 6.35761 27.9493 6.08096 34.802 10.8202L38.9795 6.64311C39.5655 6.05711 40.5134 6.05711 41.0994 6.64311ZM40.359 16.2851C41.807 18.2071 43.079 20.4291 44.141 22.8811C44.307 23.2611 44.307 23.6931 44.141 24.0711C39.949 33.7851 32.519 39.5831 24.265 39.5831C22.391 39.5831 20.527 39.2791 18.727 38.6811C17.941 38.4191 17.515 37.5691 17.777 36.7831C18.039 35.9951 18.885 35.5771 19.675 35.8331C21.169 36.3311 22.713 36.5831 24.265 36.5831C31.121 36.5831 37.387 31.6991 41.125 23.4771C40.213 21.4791 39.151 19.6691 37.963 18.0891C37.465 17.4271 37.597 16.4851 38.259 15.9871C38.919 15.4891 39.861 15.6251 40.359 16.2851ZM14.5636 13.9255C11.6916 16.1855 9.22565 19.4775 7.40565 23.4815C8.99254 26.9864 11.0773 29.9422 13.4988 32.125L17.7744 27.8498C16.9045 26.57 16.4364 25.061 16.4364 23.4807C16.4364 19.1607 19.9484 15.6467 24.2644 15.6467C25.8304 15.6467 27.3622 16.1235 28.6376 16.9853L32.6407 12.9825C26.935 9.29406 19.9906 9.63054 14.5636 13.9255ZM30.7396 23.1267C31.5556 23.2727 32.0976 24.0527 31.9516 24.8687C31.3776 28.0547 28.8536 30.5827 25.6696 31.1627C25.5796 31.1787 25.4876 31.1867 25.3996 31.1867C24.6896 31.1867 24.0576 30.6787 23.9256 29.9547C23.7776 29.1407 24.3176 28.3587 25.1336 28.2107C27.0936 27.8547 28.6456 26.2987 28.9976 24.3367C29.1456 23.5227 29.9256 22.9867 30.7396 23.1267ZM24.2644 18.6467C21.6024 18.6467 19.4364 20.8147 19.4364 23.4807C19.4364 24.2515 19.6179 24.9955 19.96 25.6636L26.4512 19.1716C25.7829 18.831 25.0312 18.6467 24.2644 18.6467Z"
              />
            </svg>
          </div>
          <div className="settingPassword__profileInputContainer">
            <div className="input__container flex-column">
              <input
                type={showNewPassword ? "text" : "password"}
                id="new-password"
                value={newPassword}
                className="input"
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <label htmlFor="new-password" className="input__label">
                New Password
              </label>
              <svg
                class={`show-password-icon ${showNewPassword && "hide"}`}
                width="20"
                height="20"
                viewBox="0 0 48 48"
                onClick={() => setShowNewPassword(true)}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M24.0055 8.00098C32.2775 8.00698 39.7055 13.805 43.8775 23.513C44.0415 23.891 44.0415 24.319 43.8775 24.697C39.7075 34.407 32.2775 40.205 24.0055 40.211H23.9935C15.7215 40.205 8.2935 34.407 4.1215 24.697C3.9595 24.319 3.9595 23.891 4.1215 23.513C8.2935 13.805 15.7235 8.00698 23.9935 8.00098H24.0055ZM23.9995 11.001C17.1275 11.003 10.8595 15.889 7.1395 24.105C10.8595 32.323 17.1255 37.209 23.9995 37.211C30.8735 37.209 37.1395 32.323 40.8595 24.105C37.1395 15.889 30.8735 11.003 23.9995 11.001ZM23.9989 16.2826C28.3129 16.2826 31.8229 19.7926 31.8229 24.1066C31.8229 28.4186 28.3129 31.9266 23.9989 31.9266C19.6849 31.9266 16.1769 28.4186 16.1769 24.1066C16.1769 19.7926 19.6849 16.2826 23.9989 16.2826ZM23.9989 19.2826C21.3389 19.2826 19.1769 21.4466 19.1769 24.1066C19.1769 26.7646 21.3389 28.9266 23.9989 28.9266C26.6589 28.9266 28.8229 26.7646 28.8229 24.1066C28.8229 21.4466 26.6589 19.2826 23.9989 19.2826Z"
                />
              </svg>
              <svg
                class={`hide-password-icon ${!showNewPassword && "hide"}`}
                width="20"
                height="20"
                onClick={() => setShowNewPassword(false)}
                viewBox="0 0 49 49"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M41.0994 6.64311C41.6854 7.22911 41.6854 8.17911 41.0994 8.76511L9.55145 40.3111C9.25945 40.6051 8.87545 40.7511 8.49145 40.7511C8.10745 40.7511 7.72345 40.6051 7.43145 40.3111C6.84545 39.7251 6.84545 38.7771 7.43145 38.1911L11.3753 34.2496C8.54771 31.6633 6.14908 28.1769 4.38765 24.0715C4.22365 23.6915 4.22365 23.2635 4.38765 22.8855C6.42965 18.1575 9.30565 14.2435 12.7056 11.5695C19.2913 6.35761 27.9493 6.08096 34.802 10.8202L38.9795 6.64311C39.5655 6.05711 40.5134 6.05711 41.0994 6.64311ZM40.359 16.2851C41.807 18.2071 43.079 20.4291 44.141 22.8811C44.307 23.2611 44.307 23.6931 44.141 24.0711C39.949 33.7851 32.519 39.5831 24.265 39.5831C22.391 39.5831 20.527 39.2791 18.727 38.6811C17.941 38.4191 17.515 37.5691 17.777 36.7831C18.039 35.9951 18.885 35.5771 19.675 35.8331C21.169 36.3311 22.713 36.5831 24.265 36.5831C31.121 36.5831 37.387 31.6991 41.125 23.4771C40.213 21.4791 39.151 19.6691 37.963 18.0891C37.465 17.4271 37.597 16.4851 38.259 15.9871C38.919 15.4891 39.861 15.6251 40.359 16.2851ZM14.5636 13.9255C11.6916 16.1855 9.22565 19.4775 7.40565 23.4815C8.99254 26.9864 11.0773 29.9422 13.4988 32.125L17.7744 27.8498C16.9045 26.57 16.4364 25.061 16.4364 23.4807C16.4364 19.1607 19.9484 15.6467 24.2644 15.6467C25.8304 15.6467 27.3622 16.1235 28.6376 16.9853L32.6407 12.9825C26.935 9.29406 19.9906 9.63054 14.5636 13.9255ZM30.7396 23.1267C31.5556 23.2727 32.0976 24.0527 31.9516 24.8687C31.3776 28.0547 28.8536 30.5827 25.6696 31.1627C25.5796 31.1787 25.4876 31.1867 25.3996 31.1867C24.6896 31.1867 24.0576 30.6787 23.9256 29.9547C23.7776 29.1407 24.3176 28.3587 25.1336 28.2107C27.0936 27.8547 28.6456 26.2987 28.9976 24.3367C29.1456 23.5227 29.9256 22.9867 30.7396 23.1267ZM24.2644 18.6467C21.6024 18.6467 19.4364 20.8147 19.4364 23.4807C19.4364 24.2515 19.6179 24.9955 19.96 25.6636L26.4512 19.1716C25.7829 18.831 25.0312 18.6467 24.2644 18.6467Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="settingPassword__twoStep flex-row">
          <div className="settingPassword__twoStepDesc">
            <div className="settingPassword__twoStepHeader">
              <h3>Two Factor Authentication</h3>
              <span>Recommended</span>
            </div>
            <p>
              To help keep your account secure, we'll ask you to submit a code
              when using a new device to log in. We'll send the code via email.
            </p>
          </div>
          <div
            className={`settingPassword__dropdownToggle ${
              twoStepAuth && "active"
            }`}
            onClick={() => setTwoStepAuth((twoStepAuth) => !twoStepAuth)}
          >
            <input
              type="checkbox"
              checked={twoStepAuth}
              onChange={() => setTwoStepAuth((twoStepAuth) => !twoStepAuth)}
            />
            <div className="settingPassword__dropdownToggleIcon item-center rounded"></div>
          </div>
        </div>
        <button
          className={`settingPassword__btnUpdate btn ${
            password !== "" && newPassword !== "" && "primary"
          }`}
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default SettingPassword;
