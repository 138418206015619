import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  addNewConnection,
  removeFromConnection,
} from "../../actions/connectionActions";
import "./SearchCard.css";

const SearchCard = ({ connection }) => {
  const [follow, setFollow] = useState(false);
  const { connections } = useSelector((state) => state.connection);
  const dispatch = useDispatch();
  const history = useHistory();
  const connected = connections.find((c) => connection.id === c?.id);
  const handleProfile = (e) => {
    if (!e.target.classList.contains("btn")) {
      history.push(
        `/${connection.connection.username.split("/")[0]}/${connection.id}`
      );
    }
  };

  const handleFollowConnection = () => {
    if (connected || follow) {
      setFollow(false);
      dispatch(removeFromConnection(connection.id));
    } else {
      setFollow(true);
      dispatch(addNewConnection(connection));
    }
  };

  return (
    <div className="search__card flex-row" onClick={handleProfile}>
      <div className="flex-row">
        <div className="search__cardImgProfile">
          <img src={connection.connection.img} alt="" className="rounded" />
        </div>

        <div className="search__cardDetail flex-row">
          <div className="search__cardDetailProfile flex-column">
            <div>
              <h3 className="flex-row">
                {connection.connection.name}{" "}
                {connection.level && (
                  <img
                    src={`/images/emblems/${connection.level.toLowerCase()}.png`}
                    alt={connection.level}
                    className="search__cardUserBadges"
                  />
                )}
              </h3>
              <span>{connection.identity.role}</span>
            </div>
            <button
              className={`btn ${
                follow || connected ? "search__cardBtnFollowed" : "primary"
              }`}
              onClick={handleFollowConnection}
            >
              <svg viewBox="0 0 49 49" xmlns="http://www.w3.org/2000/svg">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.0397 29.4606L20.2779 29.4606L20.8059 29.4621C25.2898 29.486 35.7937 29.8936 35.7937 36.8226C35.7937 43.2467 27.1669 44.1052 20.4565 44.1376L19.2736 44.1371C14.7897 44.1131 4.28574 43.7056 4.28574 36.7786C4.28574 30.2186 13.2757 29.4606 20.0397 29.4606ZM20.0397 32.4606C11.5777 32.4606 7.28574 33.9146 7.28574 36.7786C7.28574 39.6706 11.5777 41.1386 20.0397 41.1386C28.5017 41.1386 32.7937 39.6846 32.7937 36.8226C32.7937 33.9266 28.5017 32.4606 20.0397 32.4606ZM38.6939 16.386C39.5219 16.386 40.1939 17.058 40.1939 17.886L40.1937 20.3946L42.7857 20.3962C43.6137 20.3962 44.2857 21.0682 44.2857 21.8962C44.2857 22.7242 43.6137 23.3962 42.7857 23.3962L40.1937 23.3946L40.1939 25.908C40.1939 26.736 39.5219 27.408 38.6939 27.408C37.8659 27.408 37.1939 26.736 37.1939 25.908L37.1937 23.3946L34.6057 23.3962C33.7777 23.3962 33.1057 22.7242 33.1057 21.8962C33.1057 21.0682 33.7777 20.3962 34.6057 20.3962L37.1937 20.3946L37.1939 17.886C37.1939 17.058 37.8659 16.386 38.6939 16.386ZM20.0397 4.54857C25.9057 4.54857 30.6757 9.32057 30.6757 15.1846C30.6757 21.0486 25.9057 25.8206 20.0397 25.8206H19.9777C17.1397 25.8106 14.4797 24.6966 12.4857 22.6886C10.4897 20.6786 9.39574 18.0106 9.40567 15.1786C9.40567 9.32057 14.1757 4.54857 20.0397 4.54857ZM20.0397 7.54857C15.8317 7.54857 12.4057 10.9746 12.4057 15.1846C12.3977 17.2226 13.1817 19.1326 14.6117 20.5746C16.0437 22.0146 17.9517 22.8126 19.9837 22.8206L20.0397 24.3026V22.8206C24.2497 22.8206 27.6757 19.3946 27.6757 15.1846C27.6757 10.9746 24.2497 7.54857 20.0397 7.54857Z"
                />
              </svg>

              {follow || connected
                ? `${
                    connection.identity.job === "Genius"
                      ? "Connected"
                      : "Following"
                  }`
                : `${
                    connection.identity.job === "Genius" ? "Connect" : "Follow"
                  }`}
            </button>
          </div>
        </div>
      </div>
      <div className="search__cardProfileProject flex-row">
        <img src="/images/pictures/search/profile-project1.png" alt="" />
        <img src="/images/pictures/search/profile-project2.png" alt="" />
        <img src="/images/pictures/search/profile-project3.png" alt="" />
      </div>
    </div>
  );
};

export default SearchCard;
