import { useEffect } from "react";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import thumbnails from "../../utils/thumbnails.json";
import ContentCard from "../ContentCard";
import "./ContentSection.css";

const ContentSection = ({ id }) => {
  const [videos, setVideos] = useState(thumbnails);

  const allTeacherVideos =
    id && thumbnails.filter((thumb) => thumb.channel.id === id);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = () => {
    setVideos([...videos, ...thumbnails]);
  };
  return (
    <div className="contentSection ">
      {id ? (
        <div className={`contentSection__content ${allTeacherVideos.length < 4 && "less-content"}`}>
          {allTeacherVideos.map((thumb) => (
            <ContentCard
              title={thumb.title}
              key={thumb.id}
              id={thumb.id}
              img={thumb.img}
              premium={thumb.premium}
              channelName={thumb.channel.name}
              imgChannel={thumb.channel.img}
            />
          ))}
        </div>
      ) : (
        <InfiniteScroll
          dataLength={videos.length}
          next={fetchData}
          hasMore={true}
          style={{ overflow: "initial" }}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>Yay! You have seen it all</b>
            </p>
          }
          loader={
            <div className="loading">
              <div className="ball one"></div>
              <div className="ball two"></div>
              <div className="ball three"></div>
            </div>
          }
        >
          <div className={`contentSection__content ${videos.length < 4 && "less-content"}`}>
            {videos.map((thumb) => (
              <ContentCard
                title={thumb.title}
                key={thumb.id}
                id={thumb.id}
                img={thumb.img}
                premium={thumb.premium}
                channelName={thumb.channel.name}
                imgChannel={thumb.channel.img}
              />
            ))}
          </div>
        </InfiniteScroll>
      )}
    </div>
  );
};

export default ContentSection;
