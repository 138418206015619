import { useSelector } from "react-redux";
import "./InterestedCard.css";

const InterestedCard = ({
  id,
  img,
  category,
  selectAll,
  setSelectedTopic,
  selectedTopic,
  handleChanged,
}) => {
  const { topicVideos } = useSelector((state) => state.topic);
  const topicAdd = () => {
    const existTopic = topicVideos.find((st) => id === st.id);
    if (existTopic) {
      setSelectedTopic((selectedTopic) =>
        selectedTopic.map((st) => st.id === id && id)
      );
    } else {
      setSelectedTopic([...selectedTopic, { id, img, category }]);
    }
  };

  const topicDelete = () => {
    setSelectedTopic(selectedTopic.filter((topic) => topic.id !== id));
  };

  const selected = selectedTopic.find((e) => e.id === id);

  const handleClick = () => {
    handleChanged();
    if (selected) {
      topicDelete();
    } else {
      topicAdd();
    }
  };

  return (
    <div className="interestedCard " onClick={handleClick}>
      {(selectAll || selected) && (
        <div className="interestedCard__selected">
          <svg
            width="14"
            height="10"
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2 7.73589L6.11252 11.8484L15.9609 2"
              stroke="white"
              strokeWidth="3"
              strokeLinecap="round"
            />
          </svg>
        </div>
      )}

      <img src={img} alt={category} className="interestedCard__img" />
      <p className="interestedCard__title">{category}</p>
    </div>
  );
};

export default InterestedCard;
