import { BrowserRouter as Router, Route } from "react-router-dom";
import WatchVideo from "./pages/WatchVideo";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Shop from "./pages/Shop";
import Videos from "./pages/Videos";
import ProductDetail from "./pages/ProductDetail";
import Cart from "./pages/Cart";
import PaymentMethod from "./pages/PaymentMethod";
import Delivery from "./pages/Delivery";
import SuccessfulOder from "./pages/SuccessfulOder";
import Quiz from "./pages/Quiz";
import Connections from "./pages/Connections";
import Profile from "./pages/Profile";
import Communities from "./pages/Communities";
import Search from "./pages/Search";
import Refer from "./pages/Refer";
import Track from "./pages/Track";
import ContactSupport from "./pages/ContactSupport";
import ImagesPage from "./pages/ImagesPage";
import Gift from "./pages/Gift";
import Report from "./pages/Report";
import PopupUserInfo from "./components/PopupUserInfo";
import { useEffect } from "react";
import axios from "axios";
import EarnMoney from "./pages/EarnMoney";
import SearchImages from "./components/SearchImages";
import Credits from "./pages/Credits";

function App() {
  useEffect(() => {
    if (localStorage.getItem("gsprklUserInfo")) {
      const { token } = JSON.parse(localStorage.getItem("gsprklUserInfo"));
      const fetchUserProfile = async () => {
        const res = await axios.get(
          "https://genius-park-stag.herokuapp.com/api/user/profile",
          {
            headers: {
              Authentication: `Bearer ${token}`,
            },
          }
        );
        console.log("ads", res);
      };
      fetchUserProfile();
    }
  }, []);

  return (
    <div className="App">
      <PopupUserInfo />
      <Router>
        <div className="container-header">
          <Header />
        </div>
        <div className="container">
          <Sidebar />
          <div className="container-content">
            <Route component={Profile} exact path="/" />
            <Route component={Profile} exact path="/:profile/:id" />
            <Route component={Videos} exact path="/videos" />
            <Route component={Videos} exact path="/videos/teacher/:id" />
            <Route component={WatchVideo} exact path="/videos/watch/:id" />
            <Route component={Shop} exact path="/shop" />
            <Route component={ProductDetail} exact path="/shop/details/:id" />
            <Route component={Cart} exact path="/cart" />
            <Route component={PaymentMethod} exact path="/payment" />
            <Route component={Delivery} exact path="/delivery" />
            <Route component={SuccessfulOder} exact path="/successful-order" />
            <Route component={Quiz} exact path="/quiz" />
            <Route component={Connections} exact path="/connections" />
            <Route component={Communities} exact path="/communities" />
            <Route component={Search} exact path="/search/:search" />
            <Route
              component={SearchImages}
              exact
              path="/images/search/:search"
            />
            <Route component={Search} exact path="/search" />
            <Route component={Refer} exact path="/refer" />
            <Route component={Track} exact path="/track" />
            <Route component={ContactSupport} exact path="/contact-support" />
            <Route component={ImagesPage} exact path="/images" />
            <Route component={Gift} exact path="/gift" />
            <Route component={Report} exact path="/report" />
            <Route component={EarnMoney} exact path="/earn-money" />
            <Route component={Credits} exact path="/credits" />
          </div>
        </div>
      </Router>
    </div>
  );
}

export default App;
