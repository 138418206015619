import { useState } from "react";
import { truncate } from "../../utils/truncate";
import UpgradePremiumPopup from "../UpgradePremiumPopup";
import "./CommunitiesCard.css";

const CommunitiesCard = ({ content }) => {
  const [upgradePremium, setUpgradePremium] = useState(false);
  const userInfo =
    localStorage.getItem("gsprklUserInfo") &&
    JSON.parse(localStorage.getItem("gsprklUserInfo"));

  const handleClickCommunities = (e) => {
    if (userInfo?.plan === "free-plan" || !userInfo?.plan) {
      e.preventDefault();
      setUpgradePremium(true);
    }
  };

  return (
    <>
      <a
        href={`${
          userInfo?.plan !== "free-plan" || userInfo?.plan
            ? content.id === 1
              ? "https://discord.gg/zjFYrkFJYA"
              : "https://discord.com/app"
            : window.location.href
        }`}
        target={
          userInfo?.plan !== "free-plan" || userInfo?.plan
            ? "_blank"
            : "_parent"
        }
        rel="noreferrer"
        onClick={handleClickCommunities}
      >
        <div
          className={`communitiesCard
        ${
          (userInfo?.plan === "free-plan" || !userInfo?.plan) &&
          "communities-lock"
        }`}
        >
          <div className="flex-row ">
            <img src={content.img} alt={content.title} />
            <div>
              <h3>{content.title}</h3>
              <span>{content.subtitle}</span>
            </div>
          </div>
          <p>{truncate(content.description, 96)}</p>
        </div>
      </a>
      {upgradePremium && (
        <UpgradePremiumPopup closePopup={() => setUpgradePremium(false)} />
      )}
    </>
  );
};

export default CommunitiesCard;
