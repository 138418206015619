import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { removeFromConnection } from "../../actions/connectionActions";
import TopupGiftPopup from "../../components/TopupGiftPopup";
import "./Connections.css";

const Connections = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [openGiftPopup, setOpenGiftPopup] = useState(false);
  const { connections } = useSelector((state) => state.connection);
  const removeConnection = (connection) => {
    dispatch(removeFromConnection(connection.id));
  };
  return (
    <div className="connections">
      {connections.length <= 0 ? (
        <div className="connections__initial flex-column">
          <img
            src="https://gist.githubusercontent.com/faustabmnyh/070481fe272d83fc3cc0cd704121a794/raw/e1b074dce544075a557f91449c3cd435db510582/connection.svg"
            alt=""
          />
          <h1>
            <span>Hey, Abraham!</span> You Don't Have Any Connections Yet!
          </h1>
          <p>
            You can connect to other geniuses and teachers by just searching
            their names.
          </p>
        </div>
      ) : (
        <div className="connections__table">
          <h3>My Connections</h3>
          <div className="connections__tableContainer">
            <table>
              <thead>
                <tr>
                  <th>Connection</th>
                  <th>Identity</th>
                  <th>Status</th>
                  <th>Connected</th>
                  <th>Function</th>
                </tr>
              </thead>
              <tbody>
                {connections.map((connection) => (
                  <tr key={connection.id}>
                    <td
                      className="connections__connection flex-row"
                      onClick={() => history.push(`/profile/${connection.id}`)}
                    >
                      <img
                        src={connection.connection.img}
                        className="rounded"
                        alt=""
                      />{" "}
                      <div>
                        <h4>{connection.connection.name}</h4>
                        <span>{connection.connection.username}</span>
                      </div>
                    </td>
                    <td className="connections__identity">
                      <span>{connection.identity.job}</span>
                      {connection.identity.role}
                    </td>
                    <td className={`connections__status ${connection.status}`}>
                      <span>{connection.status}</span>
                    </td>
                    <td>{connection.connected}</td>
                    <td className="connections__function flex-row">
                      <a
                        href="https://discord.com/app"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          className="header__rightMessage"
                          fill="#4D607E"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.9185 20.2173L18.9185 20.2173C18.7281 20.2961 18.4884 20.3464 18.1006 20.3728C17.7074 20.3997 17.2071 20.4 16.5 20.4H12C7.36081 20.4 3.6 16.6392 3.6 12C3.6 7.36081 7.36081 3.6 12 3.6C16.6392 3.6 20.4 7.36081 20.4 12V16.5C20.4 17.2071 20.3997 17.7074 20.3728 18.1006C20.3464 18.4884 20.2961 18.7281 20.2173 18.9185L20.2173 18.9185C19.9738 19.5065 19.5065 19.9738 18.9185 20.2173Z"
                            stroke="#4D607E"
                            stroke-width="1.3"
                          />
                          <path
                            d="M9 11H15"
                            stroke="#fff"
                            stroke-width="1.3"
                            stroke-linecap="round"
                          />
                          <path
                            d="M12 15H15"
                            stroke="#fff"
                            stroke-width="1.3"
                            stroke-linecap="round"
                          />
                        </svg>
                      </a>
                      <div onClick={() => history.push("/credits?gift")}>
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M24.8 6.5C25 5.7 25 4.5 23.6 3.2C22.8 2.4 22 2 21.1 2C18.6 2 16.8 5.5 15.9 7.8C15 5.5 13.2 2 10.7 2C9.8 2 9 2.4 8.2 3.2C6.8 4.5 6.8 5.7 7 6.5C7.9 9.5 14.3 10.7 15.6 10.9H15.7C15.8 10.9 15.8 10.9 15.9 10.9C16 10.9 16 10.9 16.1 10.9H16.2C17.5 10.7 23.9 9.5 24.8 6.5ZM8.7 6C8.5 5.5 8.8 5 9.4 4.4C9.9 4 10.3 3.7 10.7 3.7C12.1 3.7 13.6 6.4 14.5 8.9C11.9 8.3 9 7.2 8.7 6ZM23.1 6C22.7 7.2 19.8 8.3 17.3 8.9C18.1 6.7 19.6 3.7 21.1 3.7C21.5 3.7 21.9 3.9 22.4 4.4C23 5 23.3 5.5 23.1 6Z"
                            fill="#4D607E"
                          />
                          <path
                            d="M4.4 17.2H14.9V12H6.1C5.1 12 4.3 12.8 4.3 13.8V17.2H4.4Z"
                            fill="#4D607E"
                          />
                          <path
                            d="M17.2 11.9999V17.1999H27.7V13.6999C27.7 12.6999 26.9 11.8999 25.9 11.8999H17.2V11.9999Z"
                            fill="#4D607E"
                          />
                          <path
                            d="M5.5 30H14.8V19.3C14.8 18.8 14.4 18.3 13.8 18.3H6.5C6 18.3 5.5 18.7 5.5 19.3V30Z"
                            fill="#4D607E"
                          />
                          <path
                            d="M17.2 30H26.5V19.3C26.5 18.8 26.1 18.3 25.5 18.3H18.2C17.7 18.3 17.2 18.7 17.2 19.3V30Z"
                            fill="#4D607E"
                          />
                        </svg>
                      </div>
                      <div onClick={() => removeConnection(connection)}>
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 48 48"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M37.8781 17.3943C38.2769 17.3943 38.6387 17.5683 38.9246 17.8623C39.1911 18.1763 39.3253 18.5663 39.2864 18.9783C39.2864 19.1142 38.2205 32.5941 37.6116 38.2681C37.2304 41.75 34.9858 43.864 31.6188 43.922C29.0298 43.98 26.4993 44 24.0076 44C21.3622 44 18.7753 43.98 16.2641 43.922C13.01 43.844 10.7634 41.692 10.4016 38.2681C9.77525 32.5741 8.72879 19.1142 8.70934 18.9783C8.68989 18.5663 8.82215 18.1763 9.09058 17.8623C9.35511 17.5683 9.73635 17.3943 10.137 17.3943H37.8781ZM28.1295 4C29.8976 4 31.477 5.23399 31.9341 6.99397L32.2609 8.45396C32.5254 9.64394 33.5563 10.4859 34.7428 10.4859H40.5742C41.3523 10.4859 42 11.1319 42 11.9539V12.7139C42 13.5159 41.3523 14.1819 40.5742 14.1819H7.42771C6.64772 14.1819 6 13.5159 6 12.7139V11.9539C6 11.1319 6.64772 10.4859 7.42771 10.4859H13.2591C14.4437 10.4859 15.4746 9.64394 15.7411 8.45596L16.0465 7.09197C16.5211 5.23399 18.083 4 19.8705 4H28.1295Z"
                            fill="#4D607E"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {/* open gift topup */}
      {openGiftPopup && (
        <TopupGiftPopup type="Gift" setOpenPopup={setOpenGiftPopup} />
      )}
    </div>
  );
};

export default Connections;
