import { useState } from "react";
import SettingAccount from "../SettingAccount";
import SettingPassword from "../SettingPassword";
import SettingTopics from "../SettingTopics";
import "./SettingPopup.css";

const SettingPopup = ({ onClick }) => {
  const [menu, setMenu] = useState("account");
  return (
    <>
      <div className="settingPopup" onClick={onClick}>
        <div className="settingPopup__container">
          <div className="settingPopup__header flex-row">
            <svg
              width="12"
              height="12"
              viewBox="0 0 24 24"
              fill="none"
              className="sidebar__moreIcon"
              onClick={onClick}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 4L8 12L16 20"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h1>Settings</h1>
          </div>
          <div className="settingPopup__body flex-row">
            <aside className="settingPopup__bodyNav">
              <ul className="settingPopup__bodyNavItems flex-column">
                <li
                  className={`settingPopup__bodyNavItem ${
                    menu === "account" && "active"
                  }`}
                  onClick={() => setMenu("account")}
                >
                  Account
                </li>
                <li
                  className={`settingPopup__bodyNavItem ${
                    menu === "password" && "active"
                  }`}
                  onClick={() => setMenu("password")}
                >
                  Password
                </li>
                <li
                  className={`settingPopup__bodyNavItem ${
                    menu === "interests" && "active"
                  }`}
                  onClick={() => setMenu("interests")}
                >
                  Interests
                </li>
              </ul>
            </aside>

            <div className="settingPopup__mainContent">
              {menu === "account" && <SettingAccount />}
              {menu === "password" && <SettingPassword />}
              {menu === "interests" && <SettingTopics editSet />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingPopup;
