import { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addCategoriesSearch } from "../../actions/categoriesActions";
import "./Categories.css";

const Categories = ({ getCategories, center, relative, type, tabImage }) => {
  const categoryContainer = useRef(null);
  const mainCategories = useRef(null);
  const dispatch = useDispatch();
  const [navShow, setNavShow] = useState({
    left: false,
    right: true,
  });
  // const [selectedCategory, setSelectedCategory] = useState([]);
  const [allCategories] = useState(getCategories);
  const {
    searchCategories,
    videosCategories,
    gameCategories,
    shopCategories,
    communitiesCategories,
  } = useSelector((state) => state.categories);

  const activeCategories =
    type === "search"
      ? searchCategories
      : type === "videos"
      ? videosCategories
      : type === "game"
      ? gameCategories
      : type === "shop"
      ? shopCategories
      : communitiesCategories;

  const addRemoveCategory = (item) => {
    dispatch(addCategoriesSearch(item, type));
  };

  const arrowRight = () => {
    let { scrollWidth, clientWidth } = categoryContainer.current;
    setNavShow({ ...navShow, left: true });
    if (
      clientWidth + categoryContainer.current.scrollLeft >=
      scrollWidth - 450
    ) {
      setNavShow({ ...navShow, right: false });
      if (window.innerWidth < 900) {
        mainCategories.current.style.padding = "0 1.5em";
      } else {
        mainCategories.current.style.padding = "1em 2em";
      }
    }
    categoryContainer.current.scrollLeft += 120;
  };
  const arrowLeft = () => {
    if (window.innerWidth < 900) {
      mainCategories.current.style.padding = "0 calc(1.5em + 1rem) 0 1.5em";
    } else {
      mainCategories.current.style.padding = "1em calc(2em + 1rem) 1em 2em";
    }
    categoryContainer.current.scrollLeft -= 120;
    setNavShow({ ...navShow, right: true });
    if (categoryContainer.current.scrollLeft === 0) {
      setNavShow({ ...navShow, left: false });
    }
  };

  const handleScroll = () => {
    let { scrollWidth, clientWidth } = categoryContainer.current;
    setNavShow({
      left: categoryContainer.current.scrollLeft === 0 ? false : true,
      right:
        clientWidth + categoryContainer.current.scrollLeft >= scrollWidth - 75
          ? false
          : true,
    });
  };

  useEffect(() => {
    let { scrollWidth, clientWidth } = categoryContainer.current;
    setNavShow({
      left: categoryContainer.current.scrollLeft === 0 ? false : true,
      right:
        clientWidth + categoryContainer.current.scrollLeft >= scrollWidth - 75
          ? false
          : true,
    });

    if (tabImage) {
      dispatch(
        addCategoriesSearch(
          {
            id: 5,
            category: "Images",
          },
          type
        )
      );
    }
  }, [tabImage, type, dispatch]);

  return (
    <div
      className={`categories ${relative && "relative"}`}
      ref={mainCategories}
    >
      {navShow.left && (
        <div className="categories__nav left" onClick={arrowLeft}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 4L8 12L16 20"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}

      <ul
        className={`categories__items flex-row ${center && "center"}`}
        ref={categoryContainer}
        onScroll={handleScroll}
      >
        {allCategories.map((category) => (
          <li
            className={`categories__item flex-row ${
              activeCategories.find((e) => e.id === category.id)
                ? "active"
                : activeCategories.length === 0 && category.default && "active"
            }`}
            key={category.id}
            onClick={() => addRemoveCategory(category)}
          >
            {category.icon && (
              <div className="categories__itemImg primary">
                <img src={category.icon} alt={category.category} />
              </div>
            )}
            {category.category}
          </li>
        ))}
      </ul>
      {navShow.right && (
        <div className="categories__nav right" onClick={arrowRight}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 4L8 12L16 20"
              stroke="white"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Categories;
