import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addComunity } from "../../actions/communityActions";
import { addQuiz } from "../../actions/quizActions";
import CommunitiesCard from "../CommunitiesCard";
import interested from "../../utils/interested.json";
import QuizCard from "../QuizCard";
import "./TeacherCreateCard.css";

const TeacherCreateCard = ({ selectedRow, setCreateCard, type }) => {
  const dispatch = useDispatch();
  const [showTopic, setShowTopic] = useState(false);
  const [errors, setErrors] = useState({});
  const [topic, setTopic] = useState(
    selectedRow ? selectedRow.topic : `Select ${type.toLowerCase()} topic`
  );
  const [createTextDesc] = useState(
    type === "Quiz"
      ? "A quiz is a great way to interact with students and drive them to your website."
      : "A community is a great way to interact with your students."
  );

  const [topics] = useState(
    type === "Quiz"
      ? [
          { id: "1", category: "Design" },
          { id: "2", category: "Test" },
          { id: "3", category: "Trello" },
          { id: "4", category: "Prototype" },
        ]
      : interested
  );
  const [values, setValues] = useState({
    name: selectedRow ? selectedRow.name : "",
    destinationLink: selectedRow ? selectedRow.destinationLink : "",
    description: selectedRow ? selectedRow.description : "",
  });

  // check if input not null
  const isHaveInput =
    values.description.trim() !== "" &&
    values.name.trim() !== "" &&
    values.destinationLink.trim() !== "" &&
    topic !== `Select ${type.toLowerCase()} topic`;

  // handle change input
  const handleChange = (e) => {
    if (e.target.name === "destinationLink" && type === "Community") {
      const regexDiscordLink =
        /(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]/g;
      if (!e.target.value.match(regexDiscordLink)) {
        setErrors({
          destinationLink:
            "Your link not matches, link must be discord.gg/abcdef",
        });
      } else {
        setErrors({});
        setValues({
          ...values,
          [e.target.name]: e.target.value,
        });
      }
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleUploadIcon = (e) => {
    // handle this later
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append("image", file);
    try {
      // for later to send to the backend
      // const { data } = await Axios.post("", bodyFormData, {
      //   headers: {
      //     "Content-Type": "multipart/form-data",
      //     Authorization: `Bearer ${aboutUser.token}`,
      //   },
      // });
      // setLoadingUpload(true);
      // setImage(data);
      // setLoadingUpload(false);
    } catch (err) {
      // setLoadingUpload(true);
      // setErrorUpload(err.message);
      // setLoadingUpload(false);
    }
  };

  const chooseDropdown = (e) => {
    console.log("hai");
    setTopic(e.target.innerText);
    setShowTopic(false);
  };

  const handlePublishQuiz = (e) => {
    e.preventDefault();
    if (isHaveInput) {
      setCreateCard();
      if (type === "Quiz") {
        dispatch(
          addQuiz({
            ...values,
            id: new Date(),
            topic,
            status: "active",
            created: new Date().toISOString().substr(0, 10),
          })
        );
      } else {
        dispatch(
          addComunity({
            ...values,
            id: new Date(),
            topic,
            status: "active",
            created: new Date().toISOString().substr(0, 10),
          })
        );
      }
    }
  };

  useEffect(() => {
    document.onclick = (e) => {
      if (e.target.closest(`#${type}-topic`) === null) {
        setShowTopic(false);
      }
    };
  }, [type]);
  return (
    <div className="flex-row teacherCreateCard">
      <div className="teacherCreateCard__create">
        <div>
          <h1>Create A {type === "Quiz" ? "Quizzes" : "Community"}</h1>
          <p>{createTextDesc}</p>
        </div>
        <form className="flex-column" onSubmit={handlePublishQuiz}>
          <label htmlFor={`${type}-name-input`}>
            {type} Name
            <input
              type="text"
              name="name"
              onChange={handleChange}
              value={values.name}
              maxLength={type === "Quiz" ? "35" : "16"}
              id={`${type}-name-input`}
              placeholder={`Please enter ${type.toLowerCase()} name`}
            />
          </label>
          <span className="teacherCreateCard__wordLimit">
            {values.name.length}/{type === "Quiz" ? "35" : "16"}
          </span>
          <label htmlFor={`${type}-destination-input`}>
            {type === "Quiz"
              ? "Destination Link"
              : "Discord Server Link (set link to never expire)"}

            <input
              type="text"
              name="destinationLink"
              onChange={handleChange}
              value={values.destinationLink}
              id={`${type}-destination-input`}
              placeholder={
                type === "Quiz"
                  ? "Please enter the destination link"
                  : "Please enter the discord invitation link"
              }
            />
          </label>
          {!errors.length && errors.destinationLink && (
            <span className="text-input-error">{errors.destinationLink}</span>
          )}
          <div className="teacherCreateCard__twoInputs flex-row">
            <label htmlFor={`${type}-topic-input`}>
              {type} Topic
              <div
                className="teacherCreateCard__dropdownSearch"
                id={`${type}-topic`}
              >
                <div
                  className={`dropdown-container flex-row ${
                    showTopic && "expand"
                  }`}
                  onClick={() => setShowTopic((showTopic) => !showTopic)}
                >
                  <div className="flex-row ">
                    <p
                      className={`${
                        topic !== `Select ${type.toLowerCase()} topic` &&
                        "active"
                      }`}
                    >
                      {topic}
                    </p>
                  </div>

                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    fill="none"
                    className="teacherCreateCard__dropdownSearchArrow"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 4L8 12L16 20"
                      stroke="black"
                      strokeWidth="4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div
                  className={`dropdown-item-container flex-column ${
                    showTopic && "expand"
                  }`}
                >
                  {topics
                    .sort((a, b) => a.category.localeCompare(b.category))
                    .map((topic) => (
                      <label
                        htmlFor={`${type}-${topic.id}`}
                        className="flex-row"
                        onClick={chooseDropdown}
                      >
                        {topic.category}
                        <input
                          type="radio"
                          value={topic.category}
                          id={`quiz-${topic.id}`}
                        />
                      </label>
                    ))}
                </div>
              </div>
            </label>
            <label htmlFor="">
              {type === "Quiz" ? "Quiz Icon" : "Community Icon"}
              <input type="file" accept="image/*" onChange={handleUploadIcon} />
            </label>
          </div>

          <label htmlFor={`${type}-desc-input`}>
            Description
            <textarea
              type="text"
              name="description"
              onChange={handleChange}
              value={values.description}
              id={`${type}-desc-input`}
              maxLength={type === "Quiz" ? "130" : "96"}
              placeholder="Please enter description"
            />
          </label>
          <span className="teacherCreateCard__wordLimit">
            {values.description.length}/{type === "Quiz" ? "130" : "96"}
          </span>
          <button className={`btn ${isHaveInput && "primary"}`}>
            {selectedRow ? "Updated" : "Publish"}
          </button>
        </form>
      </div>
      <div className="teacherCreateCard__preview flex-column">
        <h2>Live Preview of Your {type}</h2>
        {type === "Quiz" ? (
          <div className="teacherCreateCard__previewCard">
            <QuizCard
              content={{
                title: values.name,
                link: values.destinationLink,
                description: values.description,
                img: "https://gist.githubusercontent.com/faustabmnyh/070481fe272d83fc3cc0cd704121a794/raw/e1b074dce544075a557f91449c3cd435db510582/image-add.svg",
                // title: values.name,
              }}
            />
          </div>
        ) : (
          <div className="teacherCreateCard__previewCard community">
            <CommunitiesCard
              content={{
                title: values.name,
                // for subtitle using the username teacher
                subtitle: "Abraham Benjamin",
                link: values.destinationLink,
                description: values.description,
                img: "https://gist.githubusercontent.com/faustabmnyh/070481fe272d83fc3cc0cd704121a794/raw/e1b074dce544075a557f91449c3cd435db510582/image-add.svg",
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TeacherCreateCard;
