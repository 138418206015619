import { useState } from "react";
import "./CommentCard.css";

const CommentCard = () => {
  const [liked, setLiked] = useState(false);
  const [unliked, setUnliked] = useState(false);

  const likeVideo = () => {
    if (unliked) {
      setUnliked(false);
    }
    setLiked(true);
    if (liked) {
      setLiked(false);
    }
  };
  const unlikeVideo = () => {
    if (liked) {
      setLiked(false);
    }
    setUnliked(true);
    if (unliked) {
      setUnliked(false);
    }
  };
  return (
    <div className="commentCard flex-row">
      <img src="/images/pictures/test.png" alt="" className="rounded" />
      <div className="commentCard__content">
        <div className="commentCard__header flex-row">
          <h3>Max Mas</h3>
          <small>1 year ago</small>
        </div>
        <p>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi
          suscipit unde sint et adipisci doloremque, quaerat incidunt culpa,
          delectus voluptate ipsam voluptas alias laudantium eligendi commodi
          ut. Molestias, rem repellat.
        </p>
        <div className="commentCard__action flex-row">
          <div
            className={`commentCard__actionLike flex-row ${liked && "liked"}`}
            onClick={likeVideo}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="2" y="2" width="20" height="20" rx="10" fill="#a4a4a6" />
              <path
                d="M16 12L12 8L8 12"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 16V8"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {liked ? "501" : "500"}
          </div>
          <div
            className={`commentCard__actionUnlike flex-row ${
              unliked && "unliked"
            }`}
            onClick={unlikeVideo}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                width="20"
                height="20"
                rx="10"
                transform="matrix(1 0 0 -1 2 22)"
                fill="#a4a4a6"
              />
              <path
                d="M16 12L12 16L8 12"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M12 8V16"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <small className="commentCard__reply">Reply</small>
        </div>
      </div>
    </div>
  );
};

export default CommentCard;
