import { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { addToCart } from "../../actions/cartActions";
import { buyNow } from "../../actions/orderActions";
import products from "../../utils/products.json";
import "./ProductDetail.css";

const ProductDetail = () => {
  let { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [qty, setQty] = useState(1);
  const [colorSelected, setColorSelected] = useState("");
  const [sizesSelected, setSizeSelected] = useState("");
  const [tab, setTab] = useState("about");
  const productDetails = products.find((product) => product.id === Number(id));
  const item = {
    ...productDetails,
    qty,
    colors: productDetails?.colors
      ? colorSelected
        ? colorSelected
        : productDetails?.colors[0]
      : [],
    sizes: productDetails?.sizes
      ? sizesSelected
        ? sizesSelected
        : productDetails?.sizes[0]
      : [],
  };

  const addProduct = () => {
    dispatch(addToCart(item));
  };

  const handleBuyNow = () => {
    history.push("/delivery");
    dispatch(buyNow(item));
  };

  return (
    <div className="productDetail flex-row">
      <div className="productDetail__imgContainer">
        <img src={productDetails.img} alt={productDetails.title} />
      </div>

      <div className="productDetail__details flex-column">
        <div className="productDetail__detailsTop flex-row">
          {/* <span className="productDetail__kind">Popular</span> */}
          <h1>{productDetails.title}</h1>
          <div className="productDetail__price flex-row">
            ${productDetails.price.toFixed(2)}
          </div>
        </div>
        {/* <div className="productDetail__detailsRating">
          <Rating rating={4.5} />
        </div> */}
        <ul className="productDetail__tabs flex-row">
          <li
            className={`${tab === "about" && "active"}`}
            onClick={() => setTab("about")}
          >
            About
          </li>
          <li
            className={`${tab === "details" && "active"}`}
            onClick={() => setTab("details")}
          >
            Details
          </li>
          <li
            className={`${tab === "shipping" && "active"}`}
            onClick={() => setTab("shipping")}
          >
            Shipping
          </li>
        </ul>
        <p className="productDetail__detailsDesc">
          {productDetails.description}
        </p>
        <div className="productDetail__attribute flex-row">
          {productDetails.colors && (
            <div className="productDetail__ColorsContainer flex-column">
              <p>Colors</p>
              <ul className="flex-row">
                {productDetails.colors.map((clr) => (
                  <li
                    onClick={() => setColorSelected(clr)}
                    className={`bg-${clr} productDetail__ColorsColor rounded ${
                      colorSelected === clr && "selected"
                    }`}
                  >
                    <svg
                      width="12"
                      height="7"
                      viewBox="0 0 15 11"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.5 4.5L6 9L14 1"
                        stroke="#fff"
                        stroke-width="3"
                        stroke-linecap="round"
                      />
                    </svg>
                  </li>
                ))}
              </ul>
            </div>
          )}
          {productDetails.sizes && (
            <div className="productDetail__SizesContainer flex-column">
              <p>Sizes</p>
              <ul className="flex-row">
                {productDetails.sizes.map((size) => (
                  <li
                    onClick={() => setSizeSelected(size)}
                    className={`productDetail__Sizes rounded item-center ${
                      sizesSelected === size && "selected"
                    }`}
                  >
                    {size}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="productDetail__detailsQty flex-column">
          <p className="productDetail__detailsQtyTitle">Quantity</p>
          <div className="productDetail__detailsQtyContainer flex-row">
            <div className="productDetail__detailsQtyCountContainer flex-row">
              <span
                className="productDetail__detailsQtyMinus"
                onClick={() => setQty(qty <= 1 ? 1 : qty - 1)}
              >
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#757679"
                    stroke-width="7"
                    stroke-linecap="round"
                  />
                </svg>
              </span>
              <p className="productDetail__detailsQtyCount">{qty}</p>
              <span
                className="productDetail__detailsQtyPlus"
                onClick={() =>
                  setQty(qty < productDetails.available ? qty + 1 : qty)
                }
              >
                <svg
                  width="10"
                  height="10"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 12H19"
                    stroke="#757679"
                    stroke-width="7"
                    stroke-linecap="round"
                  />
                  <path
                    d="M12 19V5"
                    stroke="#757679"
                    stroke-width="7"
                    stroke-linecap="round"
                  />
                </svg>
              </span>
            </div>
            <span>Available: {productDetails.available}</span>
          </div>
        </div>

        <div className="productDetail__bottom flex-row">
          <button className="btn primary" onClick={handleBuyNow}>
            Buy Now
          </button>
          <button className="btn" onClick={addProduct}>
            <svg
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8526 37.8157C16.5113 37.8157 17.8703 39.2036 17.8703 40.9181C17.8703 42.6121 16.5113 44 14.8526 44C13.1739 44 11.815 42.6121 11.815 40.9181C11.815 39.2036 13.1739 37.8157 14.8526 37.8157ZM37.3351 37.8157C38.9938 37.8157 40.3528 39.2036 40.3528 40.9181C40.3528 42.6121 38.9938 44 37.3351 44C35.6564 44 34.2975 42.6121 34.2975 40.9181C34.2975 39.2036 35.6564 37.8157 37.3351 37.8157ZM5.55645 4.00024L5.76011 4.01748L10.5264 4.7502C11.2059 4.8747 11.7055 5.44415 11.7654 6.13809L12.1451 10.71C12.2051 11.3651 12.7247 11.855 13.3642 11.855H40.3532C41.5722 11.855 42.3716 12.2836 43.171 13.2225C43.9703 14.1613 44.1102 15.5084 43.9304 16.731L42.0318 30.1201C41.6721 32.6938 39.5138 34.5899 36.9758 34.5899H15.1728C12.5148 34.5899 10.3166 32.5101 10.0967 29.8159L8.25817 7.56681L5.24052 7.03614C4.44114 6.89327 3.88158 6.09727 4.02147 5.28087C4.16136 4.44609 4.94075 3.89297 5.76011 4.01748L5.55645 4.00024ZM33.7783 19.4047H28.2426C27.4032 19.4047 26.7438 20.0782 26.7438 20.9355C26.7438 21.7723 27.4032 22.4662 28.2426 22.4662H33.7783C34.6176 22.4662 35.2771 21.7723 35.2771 20.9355C35.2771 20.0782 34.6176 19.4047 33.7783 19.4047Z"
                fill="#a4a4a6"
              />
            </svg>
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
