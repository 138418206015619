import axios from "axios";
import { useEffect, useState } from "react";
import { validateUserProfile } from "../../utils/validate";
import AvatarIntialUser from "../AvatarIntialUser";
import SettingTopics from "../SettingTopics";
import "./PopupUserInfo.css";

const PopupUserInfo = () => {
  const [next, setNext] = useState("");
  const [username, setUsername] = useState("");
  const [gender, setGender] = useState("");
  const [showGender, setShowGender] = useState(false);
  const [identity, setIdentity] = useState("");
  const [age, setAge] = useState("");
  const [showIdentity, setShowIdentity] = useState(false);
  const [showLanguageKnow, setShowLanguageKnow] = useState(false);
  const [showLanguageInterested, setShowLanguageInterested] = useState(false);
  const [selectedLanguagesKnow, setSelectedLanguagesKnow] = useState([]);
  const [selectedLanguagesInterested, setSelectedLanguagesInterested] =
    useState([]);
  const [showCountry, setShowCountry] = useState(false);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [errors, setErrors] = useState({});

  const name = "Abraham Bejamin";

  const languageOptions = [
    {
      id: "1",
      language: "English",
    },
    {
      id: "2",
      language: "Spanish",
    },
    {
      id: "3",
      language: "French",
    },
    {
      id: "4",
      language: "Hindi",
    },
    {
      id: "5",
      language: "Bengali",
    },
    {
      id: "6",
      language: "Arabic",
    },
    {
      id: "7",
      language: "Portuguese",
    },
    {
      id: "8",
      language: "Russian",
    },
    {
      id: "9",
      language: "Japanese",
    },
  ];

  const handleSelectedLanguagesKnow = (language) => {
    setSelectedLanguagesKnow((selectedLanguages) => {
      const existLanguage = selectedLanguages.find(
        (selectedLanguage) => selectedLanguage.language === language.language
      );
      if (existLanguage) {
        return selectedLanguages.filter(
          (selectedLanguage) => selectedLanguage.id !== language.id
        );
      }
      return [...selectedLanguages, language];
    });
  };
  const handleSelectedLanguagesInterested = (language) => {
    setSelectedLanguagesInterested((selectedLanguages) => {
      const existLanguage = selectedLanguages.find(
        (selectedLanguage) => selectedLanguage.language === language.language
      );
      if (existLanguage) {
        return selectedLanguages.filter(
          (selectedLanguage) => selectedLanguage.id !== language.id
        );
      }
      return [...selectedLanguages, language];
    });
  };

  const handleNext = (version) => {
    if (window.innerWidth < 720 && version !== "mobile ver") {
      window.location.href = "#user-info-input";
    } else {
      if (next === "") {
        setNext("Parental Consent");
        window.location.href = "#user-info-left";
      }
      if (next === "Parental Consent") {
        setNext("Choose Topic");
      }
    }
  };

  const handleChooseCountry = (name) => {
    setCountry(name);
    setShowCountry(false);
  };

  const handleChangeCountry = (e) => {
    setCountry(e.target.value);

    const items = document.querySelectorAll(".popupUserInfo__countryLists li");
    const inputValue = e.target.value.toUpperCase();
    items.forEach((item, i) => {
      let txtValue = item.textContent || item.innerText;
      if (txtValue.toUpperCase().indexOf(inputValue) > -1) {
        item.style.display = "";
      } else {
        item.style.display = "none";
      }
    });
  };

  // handle next
  const handleContinue = () => {
    const { valid, errors } = validateUserProfile(
      username,
      gender,
      age,
      selectedLanguagesKnow,
      selectedLanguagesInterested,
      country,
      identity
    );

    setErrors(errors);
    if (next === "Choose Topic") {
      if (identity === "student") {
        window.location.href = "https://gs-landing-page.pages.dev/pricing";
      }
      return setNext("Close popup");
    } else {
      if (valid) {
        if (parseInt(age) < 13) {
          if (parseInt(age) < 7) {
            setErrors({ ...errors, age: "Minimun Age 7 years old" });
            return;
          }
          const script = document.createElement("script");
          script.type = "text/javascript";
          script.src = "//js-na1.hs-scripts.com/20480110.js";
          script.id = "hs-script-loader";
          script.async = true;
          script.defer = true;
          document.body.appendChild(script);
          return handleNext("Parental Consent");
        }
        return setNext("Choose Topic");
      }
    }
  };

  const handleSelectIdentity = (identity) => {
    setShowIdentity(false);
    setIdentity(identity);
  };
  const handleSelectGender = (gender) => {
    setShowGender(false);
    setGender(gender);
  };

  useEffect(() => {
    document.onclick = (e) => {
      if (
        e.target.closest(".popupUserInfo__languagesInputContainer") === null
      ) {
        setShowLanguageKnow(false);
      }
      if (
        e.target.closest(
          ".popupUserInfo__languagesInputContainer.interested-in"
        ) === null
      ) {
        setShowLanguageInterested(false);
      }
      if (e.target.closest(".popupUserInfo__country") === null) {
        setShowCountry(false);
      }
      if (e.target.closest(".popupUserInfo__identity") === null) {
        setShowIdentity(false);
      }
      if (e.target.closest(".popupUserInfo__gender") === null) {
        setShowGender(false);
      }
    };

    // get countries
    async function fetchData() {
      const request = await axios.get("https://restcountries.com/v3.1/all");
      setCountries(request.data);
    }

    fetchData();
  }, []);

  return (
    next !== "Close popup" && (
      <div className="popupUserInfo">
        <div
          className={`popupUserInfo__container ${
            next === "Parental Consent" && "parental-consent"
          } 
          ${next === "Choose Topic" && "choose-topic"} flex-row`}
        >
          <section
            className="popupUserInfo__leftSection flex-column"
            id="user-info-left"
          >
            {next === "Parental Consent" ? (
              <img
                src={
                  "https://gist.githubusercontent.com/faustabmnyh/070481fe272d83fc3cc0cd704121a794/raw/d9e247b4c6a4c9bc2a67d8becaba7e4d7219819b/shield-protection.svg"
                }
                alt="geniusparkle avatar"
              />
            ) : (
              <AvatarIntialUser name={name} rounded="rounded" />
            )}
            <div className="popupUserInfo__leftSectionText flex-column">
              <h1>
                {next === "Parental Consent"
                  ? "Parental Consent Needed"
                  : next === "Choose Topic"
                  ? "Choose Your Interests"
                  : `Hello, ${name.length > 8 ? name.split(" ")[0] : name}`}
              </h1>
              <span>
                {next === "Parental Consent"
                  ? "Don't worry, just get your parent to consent on behalf of you"
                  : next === "Choose Topic"
                  ? "Btw, in case you don't know your passion yet, the select all."
                  : "We need some more info to give you personalized experience."}{" "}
              </span>
            </div>
            <span
              className="popupUserInfo__btnArrow rounded"
              onClick={handleContinue}
            >
              <svg viewBox="0 0 9 15" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.47992 7.24977C8.47992 6.98977 8.38993 6.72977 8.18993 6.52977L2.05993 0.309766C1.66993 -0.0902344 1.03992 -0.0902344 0.649922 0.309766C0.259922 0.709766 0.259922 1.34976 0.649922 1.73976L6.07993 7.24977L0.649922 12.7598C0.259922 13.1498 0.259922 13.7998 0.649922 14.1898C1.03992 14.5898 1.66993 14.5898 2.05993 14.1898L8.18993 7.96977C8.38993 7.76977 8.47992 7.50977 8.47992 7.24977Z" />
              </svg>
            </span>
          </section>
          <section
            className="popupUserInfo__rightSection flex-column"
            id="user-info-input"
          >
            {next === "Parental Consent" ? (
              <p className="popupUserInfo__rightSectionParentalConsent">
                Since you’re under 13, we need your parent to review our privacy
                policy and consent on behalf of you.
                <br />
                <br />
                If you can’t get your parent to consent on behalf of you within
                next 24 hours, we’ll delete your account and personal
                information from our database.
                <br />
                <br />
                If you’re the parent, then click on the message button that just
                appeared, type “ZOOM” and send. Then our customer support team
                will send you a zoom meeting link through which you can verify
                you’re the parent and consent on behalf of your child.
              </p>
            ) : next === "Choose Topic" ? (
              <div className="popupUserInfo__chooseTopics">
                <SettingTopics initialSet />
              </div>
            ) : (
              <>
                <div className="input__container">
                  <input
                    type="text"
                    placeholder="Enter a username"
                    required
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className={`input ${errors && errors.username && "error"}`}
                    id="user-username"
                  />
                  <label htmlFor="user-username" className="input__label">
                    Enter a Username
                  </label>
                  {errors && errors.username && (
                    <span className="input__textError">{errors.username}</span>
                  )}
                </div>
                <div
                  className={`input__container ${
                    showGender && "open-dropdown"
                  } ${
                    gender !== "" && "selected-gender"
                  } popupUserInfo__gender`}
                >
                  <div
                    onClick={() => setShowGender(true)}
                    className={`input popupUserInfo__dropdown ${
                      errors && errors.gender && "error"
                    }`}
                  >
                    {gender}
                  </div>
                  <ul
                    className={`popupUserInfo__dropdownLists ${
                      showGender && "show"
                    }`}
                  >
                    <li>
                      <input type="radio" name="gender" id="gender-male" />
                      <label
                        onClick={() => handleSelectGender("male")}
                        htmlFor="gender-male"
                      >
                        Male
                      </label>
                    </li>
                    <li>
                      <input type="radio" id="gender-female" />
                      <label
                        onClick={() => handleSelectGender("female")}
                        htmlFor="gender-female"
                      >
                        Female
                      </label>
                    </li>
                  </ul>

                  <label
                    htmlFor="languages-one"
                    className="input__label"
                    onClick={() => setShowGender(true)}
                  >
                    {gender !== "" && (
                      <img
                        src={`https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/ae63ef6b6f0607c839c3f0dfae2f4b70a517fccb/${gender}.svg`}
                        alt={gender}
                      />
                    )}
                    Choose your gender
                  </label>
                  {errors && errors.gender && (
                    <span className="input__textError">{errors.gender}</span>
                  )}
                </div>
                <div className="input__container">
                  <input
                    type="text"
                    id="user-age"
                    value={age}
                    onChange={(e) =>
                      setAge(
                        e.target.value
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      )
                    }
                    maxLength="2"
                    placeholder="How old are you?"
                    className={`input ${errors && errors.age && "error"}`}
                    required
                  />
                  <label htmlFor="user-age" className="input__label">
                    What's your Age
                  </label>
                  {errors && errors.age && (
                    <span className="input__textError">{errors.age}</span>
                  )}
                </div>
                <div
                  className={`input__container ${
                    showLanguageKnow && "open-language"
                  } ${
                    selectedLanguagesKnow.length && "selected-language"
                  } popupUserInfo__languagesInputContainer`}
                  onClick={() => setShowLanguageKnow(true)}
                >
                  <div
                    className={`input popupUserInfo__dropdown ${
                      errors && errors.languageKnow && "error"
                    }`}
                  >
                    <ul className="popupUserInfo__selectedLanguages">
                      {selectedLanguagesKnow.map((selectedLanguage) => (
                        <li className="popupUserInfo__selectedLanguage">
                          {selectedLanguage.language}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <ul
                    className={`popupUserInfo__dropdownLists ${
                      showLanguageKnow && "show"
                    }`}
                  >
                    {languageOptions.map((languageOption) => (
                      <li key={languageOption.id}>
                        <input
                          type="checkbox"
                          id={`${languageOption.language}-${languageOption.id}`}
                        />
                        <label
                          onClick={() =>
                            handleSelectedLanguagesKnow(languageOption)
                          }
                          htmlFor={`${languageOption.language}-${languageOption.id}`}
                        >
                          <span></span>
                          {languageOption.language}
                        </label>
                      </li>
                    ))}
                  </ul>

                  <label htmlFor="languages-one" className="input__label">
                    Language you know
                  </label>
                  {errors && errors.languageKnow && (
                    <span className="input__textError">
                      {errors.languageKnow}
                    </span>
                  )}
                </div>
                <div
                  className={`input__container ${
                    showLanguageInterested && "open-language"
                  } ${
                    selectedLanguagesInterested.length && "selected-language"
                  } popupUserInfo__languagesInputContainer interested-in`}
                  onClick={() => setShowLanguageInterested(true)}
                >
                  <div
                    className={`input popupUserInfo__dropdown ${
                      errors && errors.languageInterested && "error"
                    }`}
                  >
                    <ul className="popupUserInfo__selectedLanguages">
                      {selectedLanguagesInterested.map((selectedLanguage) => (
                        <li className="popupUserInfo__selectedLanguage">
                          {selectedLanguage.language}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <ul
                    className={`popupUserInfo__dropdownLists ${
                      showLanguageInterested && "show"
                    }`}
                  >
                    {languageOptions.map((languageOption) => (
                      <li key={languageOption.id}>
                        <input
                          type="checkbox"
                          id={`${languageOption.language}-${languageOption.id}-interested-in`}
                        />
                        <label
                          onClick={() =>
                            handleSelectedLanguagesInterested(languageOption)
                          }
                          htmlFor={`${languageOption.language}-${languageOption.id}-interested-in`}
                        >
                          <span></span>
                          {languageOption.language}
                        </label>
                      </li>
                    ))}
                  </ul>

                  <label htmlFor="languages-one" className="input__label">
                    Language you interested in
                  </label>
                  {errors && errors.languageInterested && (
                    <span className="input__textError">
                      {errors.languageInterested}
                    </span>
                  )}
                </div>
                <div className="input__container popupUserInfo__country">
                  <input
                    type="text"
                    placeholder="Enter your country name"
                    required
                    value={country}
                    onChange={handleChangeCountry}
                    id="user-country"
                    className={`input ${errors && errors.country && "error"}`}
                    onKeyPress={() => setShowCountry(true)}
                  />
                  <label htmlFor="user-country" className="input__label">
                    Country
                  </label>
                  <ul
                    className={`popupUserInfo__countryLists ${
                      showCountry && "show"
                    }`}
                  >
                    {countries.map((country) => (
                      <li
                        key={country.car.cca2}
                        onClick={() => handleChooseCountry(country.name.common)}
                      >
                        {country.name.common}
                      </li>
                    ))}
                  </ul>
                  {errors && errors.country && (
                    <span className="input__textError">{errors.country}</span>
                  )}
                </div>
                <div
                  className={`input__container ${
                    showIdentity && "open-dropdown"
                  } ${
                    identity !== "" && "selected-identity"
                  } popupUserInfo__identity`}
                >
                  <div
                    onClick={() => setShowIdentity(true)}
                    className={`input popupUserInfo__dropdown ${
                      errors && errors.identity && "error"
                    }`}
                  >
                    {identity}
                  </div>
                  <ul
                    className={`popupUserInfo__dropdownLists ${
                      showIdentity && "show"
                    }`}
                  >
                    <li>
                      <input
                        type="radio"
                        name="identity"
                        id="student-identity"
                      />
                      <label
                        onClick={() => handleSelectIdentity("student")}
                        htmlFor="student-identity"
                      >
                        Student
                      </label>
                    </li>
                    <li>
                      <input
                        type="radio"
                        name="identity"
                        id="teacher-identity"
                      />
                      <label
                        onClick={() => handleSelectIdentity("teacher")}
                        htmlFor="teacher-identity"
                      >
                        Teacher
                      </label>
                    </li>
                    <li onClick={() => handleSelectIdentity("parent")}>
                      <input
                        type="radio"
                        name="identity"
                        id="parent-identity"
                      />
                      <label htmlFor="parent-identity">Parent</label>
                    </li>
                  </ul>

                  <label
                    htmlFor="languages-one"
                    className="input__label"
                    onClick={() => setShowIdentity(true)}
                  >
                    Choose your identity
                  </label>
                  {errors && errors.identity && (
                    <span className="input__textError">{errors.identity}</span>
                  )}
                </div>
              </>
            )}
            <button
              className="popupUserInfo__nextBtnMobile btn primary"
              onClick={() => handleNext("mobile ver")}
            >
              Next
            </button>
          </section>
        </div>
      </div>
    )
  );
};

export default PopupUserInfo;
