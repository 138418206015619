import { useHistory } from "react-router";
import CompletedSteps from "../../components/CompletedSteps";
import "./SuccessfulOder.css";

const SuccessfulOder = () => {
  const histroy = useHistory();

  return (
    <div className="successfullOrder">
      <CompletedSteps stepOne stepTwo stepThree stepFour />
      <div className="successfullOrder__container flex-column">
        <svg
          width="100"
          height="100"
          viewBox="0 0 100 100"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27 47.9461L43.5269 64.4731L73 35"
            stroke="#28C76F"
            strokeWidth="4.5098"
            strokeLinecap="round"
          />
          <rect
            x="2.5"
            y="2.5"
            width="95"
            height="95"
            rx="47.5"
            stroke="#28C76F"
            strokeWidth="5"
          />
        </svg>
        <h1>
          Congratulations!
          <br />
          Your order has been accepted!
        </h1>
        <p>
          Your order will be delivered to the address you provided within
          XX/XX/XXXX. You can also track your order to stay updated throughout
          the process. Thank you for shopping with us.
        </p>
        <button className="btn primary" onClick={() => histroy.push("/track")}>
          Track order
        </button>
      </div>
    </div>
  );
};

export default SuccessfulOder;
