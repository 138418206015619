import { useState } from "react";
import { useHistory } from "react-router";
import "./ImagesPage.css";
const ImagesPage = () => {
  const [searchName, setSearchName] = useState("");
  const history = useHistory();
  const handleSearch = (e) => {
    e.preventDefault();
    history.push(`/images/search/${searchName}`);
  };

  return (
    <div className="imagesPage">
      <div className="imagesPage__hero">
        <img
          src="https://images.pexels.com/photos/716281/pexels-photo-716281.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940"
          alt="Group of People in Art Exhibit"
          className="imagesPage__bg"
        />
        <div className="imagesPage__main flex-column">
          <h1>Hey, Abraham! Need Some Cool Images For Your Presentation?</h1>
          <p>
            Choose the image you need from the library of more than a million
            free to use images to use in your next project presentation
          </p>
          <form className="flex-row" onSubmit={handleSearch}>
            <svg
              width="20"
              height="20"
              className="header__searchIcon"
              viewBox="0 0 30 30"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                r="6.2"
                transform="matrix(-0.819152 0.573576 0.573576 0.819152 13.4948 12.4952)"
                stroke="#4D4F5C"
                strokeWidth="1"
              />
              <rect
                width="1"
                height="7.13287"
                rx="1"
                transform="matrix(-0.819152 0.573576 0.573576 0.819152 17.6426 16.6729)"
                fill="#4D4F5C"
              />
            </svg>
            <input
              type="text"
              value={searchName}
              onChange={(e) => setSearchName(e.target.value)}
              placeholder="Search free hight-resolution photos"
            />
          </form>
        </div>
        <div className="imagesPage__attribute flex-row">
          <span>
            Photo by{" "}
            <a
              href="https://www.pexels.com/@zhuhehuai"
              target="_blank"
              rel="noreferrer"
            >
              祝 鹤槐
            </a>
          </span>
          <span>
            Read more about the{" "}
            <a
              href="https://unsplash.com/license"
              target="_blank"
              rel="noreferrer"
            >
              Unsplash License
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ImagesPage;
