import { truncate } from "../../utils/truncate";
import "./ProfileProjects.css";
import thumbnails from "../../utils/thumbnails.json";
import { useState } from "react";
import ViewProjects from "../ViewProjects";
import interested from "../../utils/interested.json";
import { useHistory, useParams } from "react-router";
import Categories from "../Categories";

const ProfileProjects = ({ userProfile, setTab }) => {
  let { profile } = useParams();
  const [openProjects, setOpenProjects] = useState(false);
  const history = useHistory();
  const userGenius = profile === "g";
  const userTeacher = profile === "t";
  const videosTeacher =
    userProfile &&
    thumbnails.filter((thumb) => thumb.channel.id === userProfile.id);

  const [profileProjects] = useState(
    userGenius
      ? userProfile?.projects
      : userTeacher
      ? videosTeacher
      : [
          {
            id: 1,
            img: "/images/pictures/projects/profile/interior-modern.jpg",
            description:
              "As Uber works through a huge amount of internal management turmoil.",
            projects_ranking:
              "https://gist.githubusercontent.com/geniusparkle-team/a78775c79f9d3291e3360c4cb3bbb71c/raw/e1815c9584fb2a8c49638c6d34a373d18b2ffe9a/2.svg",
            title: "Modern",
            contributors: [
              {
                id: "3",
                username: "g/austrin_j",
                name: "Austrin James",
                email: "aaahames@gmail.com",
                img: "https://images.unsplash.com/photo-1506968695017-764f86a9f9ec?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
              },
              {
                id: "3141",
                username: "g/teach",
                name: "Marshall D Teach",
                email: "marshalldteach@gmail.com",
                img: "https://source.unsplash.com/u6OnpbMuZAs",
              },
              {
                id: "134235",
                username: "g/monkeyd",
                name: "Monkey D Luffy",
                email: "monkeydluffy@gmail.com",
                img: "https://source.unsplash.com/BmGikCHuoBU",
              },
              {
                id: "14124",
                username: "g/dragon",
                name: "Dragon",
                email: "dragon@gmail.com",
                img: "https://source.unsplash.com/qyAWIbWfvtA",
              },
              {
                id: "12124124",
                username: "g/emily",
                name: "Emily",
                email: "emily@gmail.com",
                img: "https://source.unsplash.com/URn7-JupQ6Q",
              },
            ],
          },
          {
            id: 2,
            projects_ranking:
              "https://gist.githubusercontent.com/geniusparkle-team/a78775c79f9d3291e3360c4cb3bbb71c/raw/e1815c9584fb2a8c49638c6d34a373d18b2ffe9a/4.svg",
            img: "/images/pictures/projects/profile/interior-scandinavian.jpg",
            description:
              "Music is something that every person has his or her own specific opinion about.",
            title: "Scandinavian",
            contributors: [
              {
                id: "3",
                username: "g/austrin_j",
                name: "Austrin James",
                email: "aaahames@gmail.com",
                img: "https://images.unsplash.com/photo-1506968695017-764f86a9f9ec?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
              },
              {
                id: "3141",
                username: "g/teach",
                name: "Marshall D Teach",
                email: "marshalldteach@gmail.com",
                img: "https://source.unsplash.com/u6OnpbMuZAs",
              },
              {
                id: "134235",
                username: "g/monkeyd",
                name: "Monkey D Luffy",
                email: "monkeydluffy@gmail.com",
                img: "https://source.unsplash.com/BmGikCHuoBU",
              },
              {
                id: "14124",
                username: "g/dragon",
                name: "Dragon",
                email: "dragon@gmail.com",
                img: "https://source.unsplash.com/qyAWIbWfvtA",
              },
              {
                id: "12124124",
                username: "g/emily",
                name: "Emily",
                email: "emily@gmail.com",
                img: "https://source.unsplash.com/URn7-JupQ6Q",
              },
            ],
          },
          {
            id: 3,
            img: "/images/pictures/projects/profile/interior-minimalist.jpg",
            description:
              "Different people have different taste, and various types of music.",
            projects_ranking:
              "https://gist.githubusercontent.com/geniusparkle-team/a78775c79f9d3291e3360c4cb3bbb71c/raw/e1815c9584fb2a8c49638c6d34a373d18b2ffe9a/2.svg",
            title: "Minimalist",
            contributors: [
              {
                id: "3",
                username: "g/austrin_j",
                name: "Austrin James",
                email: "aaahames@gmail.com",
                img: "https://images.unsplash.com/photo-1506968695017-764f86a9f9ec?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80",
              },
              {
                id: "3141",
                username: "g/teach",
                name: "Marshall D Teach",
                email: "marshalldteach@gmail.com",
                img: "https://source.unsplash.com/u6OnpbMuZAs",
              },
              {
                id: "134235",
                username: "g/monkeyd",
                name: "Monkey D Luffy",
                email: "monkeydluffy@gmail.com",
                img: "https://source.unsplash.com/BmGikCHuoBU",
              },
              {
                id: "14124",
                username: "g/dragon",
                name: "Dragon",
                email: "dragon@gmail.com",
                img: "https://source.unsplash.com/qyAWIbWfvtA",
              },
              {
                id: "12124124",
                username: "g/emily",
                name: "Emily",
                email: "emily@gmail.com",
                img: "https://source.unsplash.com/URn7-JupQ6Q",
              },
            ],
          },
        ]
  );

  console.log(userProfile);

  // https://gist.githubusercontent.com/geniusparkle-team/a78775c79f9d3291e3360c4cb3bbb71c/raw/e1815c9584fb2a8c49638c6d34a373d18b2ffe9a/1.svg

  const handleOpen = () => {
    if (userTeacher) {
      history.push(`/videos/teacher/${userProfile.id}`);
    }
    setOpenProjects(true);
  };

  return (
    <div className="profileProjects">
      {userGenius && (
        <Categories
          getCategories={[
            {
              id: 0,
              default: true,
              category: "All",
              icon: "https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/426aa188217db7bb6ee29426086bd5fd92c528bf/icon-all.svg",
            },
            ...interested,
          ]}
          type="videos"
          relative
        />
      )}

      <div
        className={`profileProjects__container ${
          userGenius && "genius-projects"
        }`}
      >
        {/* <h3>{userTeacher ? "Playlist" : "Projects"}</h3> */}
        <ul className="profileProjects__items">
          {profileProjects?.map(
            (project, i) =>
              i < 3 && (
                <li
                  className="flex-column profileProjects__item"
                  key={project.id}
                >
                  <div className="profileProjects__ranking">
                    <img src={project.projects_ranking} alt={project.title} />
                  </div>
                  <div>
                    <div
                      className={`profileProjects__imgContainer ${
                        project.premium && "premium-video"
                      }`}
                    >
                      <span className="profileProjects__darkBg"></span>
                      <img
                        src={project.img}
                        alt={project.title}
                        className="profileProjects__imgThumbnail"
                      />
                      {project.contributors && (
                        <ul className="profileProjects__contributors flex-row">
                          {project.contributors.map((contributor) => (
                            <li>
                              <img
                                src={contributor.img}
                                alt={contributor.name}
                              />
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <h4 className="flex-row">{truncate(project.title, 20)}</h4>
                    <p>{truncate(project.description, 100)}</p>
                  </div>
                  <button className="btn" onClick={handleOpen}>
                    {userTeacher ? "View Playlist" : "View Project"}
                  </button>
                </li>
              )
          )}
          {!userProfile && (
            <li
              className="profileProjects__create"
              onClick={() => setTab("overview")}
            >
              {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/azq0S0DKS50" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
              <svg
                width="15"
                height="15"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 12H19"
                  stroke="#757679"
                  stroke-width="5"
                  stroke-linecap="round"
                />
                <path
                  d="M12 19V5"
                  stroke="#757679"
                  stroke-width="5"
                  stroke-linecap="round"
                />
              </svg>
              New {userTeacher ? "Playlist" : "Project"}
            </li>
          )}
        </ul>
        {openProjects && (
          <ViewProjects
            setOpenProjects={setOpenProjects}
            projects={userTeacher ? videosTeacher : profileProjects}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileProjects;
