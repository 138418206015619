import "./ReferRewards.css";

const ReferRewards = () => {
  return (
    <div className="referRewards">
      <h1>Get Additional Rewards For Referrals</h1>
      <ul className="referRewards__map flex-column">
        <div className="flex-row">
          <div className="referRewards__arrow">
            <svg viewBox="0 0 120 125" xmlns="http://www.w3.org/2000/svg">
              <path d="M47.4627 94.4916H5.17065C2.40923 94.4916 0.170654 92.2531 0.170654 89.4916L0.170654 34.7802C0.170654 32.0188 2.40922 29.7802 5.17065 29.7802H47.4627C50.2241 29.7802 52.4627 27.5416 52.4627 24.7802V5.90049C52.4627 1.44597 57.8484 -0.784882 60.9982 2.36494L117.561 58.9272C119.513 60.8798 119.513 64.0457 117.561 65.9983L60.9982 122.561C57.8484 125.71 52.4627 123.48 52.4627 119.025V99.4916C52.4627 96.7302 50.2241 94.4916 47.4627 94.4916Z" />
            </svg>
          </div>
          <li className="flex-column">
            <div className="referRewards__imgContainer rounded">
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 30.0304C27.1336 30.0304 34 31.3687 34 36.5375C34 41.7063 27.0896 43 19 43C10.8664 43 4 41.6596 4 36.4929C4 31.3241 10.9084 30.0304 19 30.0304ZM37.9979 15C38.9904 15 39.7959 15.8189 39.7959 16.8232V19.1757H42.2021C43.1925 19.1757 44 19.9946 44 20.999C44 22.0033 43.1925 22.8222 42.2021 22.8222H39.7959V25.1768C39.7959 26.1811 38.9904 27 37.9979 27C37.0075 27 36.2 26.1811 36.2 25.1768V22.8222H33.7979C32.8055 22.8222 32 22.0033 32 20.999C32 19.9946 32.8055 19.1757 33.7979 19.1757H36.2V16.8232C36.2 15.8189 37.0075 15 37.9979 15ZM19 5C24.5091 5 28.9253 9.47326 28.9253 15.0537C28.9253 20.6341 24.5091 25.1074 19 25.1074C13.4909 25.1074 9.07474 20.6341 9.07474 15.0537C9.07474 9.47326 13.4909 5 19 5Z" />
              </svg>
            </div>
            0 Refer
          </li>
          <div className="referRewards__arrow">
            <svg viewBox="0 0 120 125" xmlns="http://www.w3.org/2000/svg">
              <path d="M47.4627 94.4916H5.17065C2.40923 94.4916 0.170654 92.2531 0.170654 89.4916L0.170654 34.7802C0.170654 32.0188 2.40922 29.7802 5.17065 29.7802H47.4627C50.2241 29.7802 52.4627 27.5416 52.4627 24.7802V5.90049C52.4627 1.44597 57.8484 -0.784882 60.9982 2.36494L117.561 58.9272C119.513 60.8798 119.513 64.0457 117.561 65.9983L60.9982 122.561C57.8484 125.71 52.4627 123.48 52.4627 119.025V99.4916C52.4627 96.7302 50.2241 94.4916 47.4627 94.4916Z" />
            </svg>
          </div>
          <li className="flex-column locked">
            <div className="referRewards__imgContainer rounded">
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 30.0304C27.1336 30.0304 34 31.3687 34 36.5375C34 41.7063 27.0896 43 19 43C10.8664 43 4 41.6596 4 36.4929C4 31.3241 10.9084 30.0304 19 30.0304ZM37.9979 15C38.9904 15 39.7959 15.8189 39.7959 16.8232V19.1757H42.2021C43.1925 19.1757 44 19.9946 44 20.999C44 22.0033 43.1925 22.8222 42.2021 22.8222H39.7959V25.1768C39.7959 26.1811 38.9904 27 37.9979 27C37.0075 27 36.2 26.1811 36.2 25.1768V22.8222H33.7979C32.8055 22.8222 32 22.0033 32 20.999C32 19.9946 32.8055 19.1757 33.7979 19.1757H36.2V16.8232C36.2 15.8189 37.0075 15 37.9979 15ZM19 5C24.5091 5 28.9253 9.47326 28.9253 15.0537C28.9253 20.6341 24.5091 25.1074 19 25.1074C13.4909 25.1074 9.07474 20.6341 9.07474 15.0537C9.07474 9.47326 13.4909 5 19 5Z" />
              </svg>
            </div>
            10 Refers
          </li>
        </div>
        <div>
          <li className="flex-column locked ">
            <div className="referRewards__imgContainer rounded">
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 30.0304C27.1336 30.0304 34 31.3687 34 36.5375C34 41.7063 27.0896 43 19 43C10.8664 43 4 41.6596 4 36.4929C4 31.3241 10.9084 30.0304 19 30.0304ZM37.9979 15C38.9904 15 39.7959 15.8189 39.7959 16.8232V19.1757H42.2021C43.1925 19.1757 44 19.9946 44 20.999C44 22.0033 43.1925 22.8222 42.2021 22.8222H39.7959V25.1768C39.7959 26.1811 38.9904 27 37.9979 27C37.0075 27 36.2 26.1811 36.2 25.1768V22.8222H33.7979C32.8055 22.8222 32 22.0033 32 20.999C32 19.9946 32.8055 19.1757 33.7979 19.1757H36.2V16.8232C36.2 15.8189 37.0075 15 37.9979 15ZM19 5C24.5091 5 28.9253 9.47326 28.9253 15.0537C28.9253 20.6341 24.5091 25.1074 19 25.1074C13.4909 25.1074 9.07474 20.6341 9.07474 15.0537C9.07474 9.47326 13.4909 5 19 5Z" />
              </svg>
            </div>
            25 Refers
          </li>
          <div className="referRewards__arrow">
            <svg viewBox="0 0 120 125" xmlns="http://www.w3.org/2000/svg">
              <path d="M47.4627 94.4916H5.17065C2.40923 94.4916 0.170654 92.2531 0.170654 89.4916L0.170654 34.7802C0.170654 32.0188 2.40922 29.7802 5.17065 29.7802H47.4627C50.2241 29.7802 52.4627 27.5416 52.4627 24.7802V5.90049C52.4627 1.44597 57.8484 -0.784882 60.9982 2.36494L117.561 58.9272C119.513 60.8798 119.513 64.0457 117.561 65.9983L60.9982 122.561C57.8484 125.71 52.4627 123.48 52.4627 119.025V99.4916C52.4627 96.7302 50.2241 94.4916 47.4627 94.4916Z" />
            </svg>
          </div>
          <li className="flex-column locked ">
            <div className="referRewards__imgContainer rounded">
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 30.0304C27.1336 30.0304 34 31.3687 34 36.5375C34 41.7063 27.0896 43 19 43C10.8664 43 4 41.6596 4 36.4929C4 31.3241 10.9084 30.0304 19 30.0304ZM37.9979 15C38.9904 15 39.7959 15.8189 39.7959 16.8232V19.1757H42.2021C43.1925 19.1757 44 19.9946 44 20.999C44 22.0033 43.1925 22.8222 42.2021 22.8222H39.7959V25.1768C39.7959 26.1811 38.9904 27 37.9979 27C37.0075 27 36.2 26.1811 36.2 25.1768V22.8222H33.7979C32.8055 22.8222 32 22.0033 32 20.999C32 19.9946 32.8055 19.1757 33.7979 19.1757H36.2V16.8232C36.2 15.8189 37.0075 15 37.9979 15ZM19 5C24.5091 5 28.9253 9.47326 28.9253 15.0537C28.9253 20.6341 24.5091 25.1074 19 25.1074C13.4909 25.1074 9.07474 20.6341 9.07474 15.0537C9.07474 9.47326 13.4909 5 19 5Z" />
              </svg>
            </div>
            50 Refers
          </li>
          <div className="referRewards__arrow">
            <svg viewBox="0 0 120 125" xmlns="http://www.w3.org/2000/svg">
              <path d="M47.4627 94.4916H5.17065C2.40923 94.4916 0.170654 92.2531 0.170654 89.4916L0.170654 34.7802C0.170654 32.0188 2.40922 29.7802 5.17065 29.7802H47.4627C50.2241 29.7802 52.4627 27.5416 52.4627 24.7802V5.90049C52.4627 1.44597 57.8484 -0.784882 60.9982 2.36494L117.561 58.9272C119.513 60.8798 119.513 64.0457 117.561 65.9983L60.9982 122.561C57.8484 125.71 52.4627 123.48 52.4627 119.025V99.4916C52.4627 96.7302 50.2241 94.4916 47.4627 94.4916Z" />
            </svg>
          </div>
          <li className="flex-column locked ">
            <div className="referRewards__imgContainer rounded">
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 30.0304C27.1336 30.0304 34 31.3687 34 36.5375C34 41.7063 27.0896 43 19 43C10.8664 43 4 41.6596 4 36.4929C4 31.3241 10.9084 30.0304 19 30.0304ZM37.9979 15C38.9904 15 39.7959 15.8189 39.7959 16.8232V19.1757H42.2021C43.1925 19.1757 44 19.9946 44 20.999C44 22.0033 43.1925 22.8222 42.2021 22.8222H39.7959V25.1768C39.7959 26.1811 38.9904 27 37.9979 27C37.0075 27 36.2 26.1811 36.2 25.1768V22.8222H33.7979C32.8055 22.8222 32 22.0033 32 20.999C32 19.9946 32.8055 19.1757 33.7979 19.1757H36.2V16.8232C36.2 15.8189 37.0075 15 37.9979 15ZM19 5C24.5091 5 28.9253 9.47326 28.9253 15.0537C28.9253 20.6341 24.5091 25.1074 19 25.1074C13.4909 25.1074 9.07474 20.6341 9.07474 15.0537C9.07474 9.47326 13.4909 5 19 5Z" />
              </svg>
            </div>
            100 Refers
          </li>
        </div>
        <div className="flex-row">
          <li className="flex-column locked">
            <div className="referRewards__imgContainer rounded">
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 30.0304C27.1336 30.0304 34 31.3687 34 36.5375C34 41.7063 27.0896 43 19 43C10.8664 43 4 41.6596 4 36.4929C4 31.3241 10.9084 30.0304 19 30.0304ZM37.9979 15C38.9904 15 39.7959 15.8189 39.7959 16.8232V19.1757H42.2021C43.1925 19.1757 44 19.9946 44 20.999C44 22.0033 43.1925 22.8222 42.2021 22.8222H39.7959V25.1768C39.7959 26.1811 38.9904 27 37.9979 27C37.0075 27 36.2 26.1811 36.2 25.1768V22.8222H33.7979C32.8055 22.8222 32 22.0033 32 20.999C32 19.9946 32.8055 19.1757 33.7979 19.1757H36.2V16.8232C36.2 15.8189 37.0075 15 37.9979 15ZM19 5C24.5091 5 28.9253 9.47326 28.9253 15.0537C28.9253 20.6341 24.5091 25.1074 19 25.1074C13.4909 25.1074 9.07474 20.6341 9.07474 15.0537C9.07474 9.47326 13.4909 5 19 5Z" />
              </svg>
            </div>
            250 Refers
          </li>
          <div className="referRewards__arrow">
            <svg viewBox="0 0 120 125" xmlns="http://www.w3.org/2000/svg">
              <path d="M47.4627 94.4916H5.17065C2.40923 94.4916 0.170654 92.2531 0.170654 89.4916L0.170654 34.7802C0.170654 32.0188 2.40922 29.7802 5.17065 29.7802H47.4627C50.2241 29.7802 52.4627 27.5416 52.4627 24.7802V5.90049C52.4627 1.44597 57.8484 -0.784882 60.9982 2.36494L117.561 58.9272C119.513 60.8798 119.513 64.0457 117.561 65.9983L60.9982 122.561C57.8484 125.71 52.4627 123.48 52.4627 119.025V99.4916C52.4627 96.7302 50.2241 94.4916 47.4627 94.4916Z" />
            </svg>
          </div>
          <li className="flex-column locked">
            <div className="referRewards__imgContainer rounded">
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 30.0304C27.1336 30.0304 34 31.3687 34 36.5375C34 41.7063 27.0896 43 19 43C10.8664 43 4 41.6596 4 36.4929C4 31.3241 10.9084 30.0304 19 30.0304ZM37.9979 15C38.9904 15 39.7959 15.8189 39.7959 16.8232V19.1757H42.2021C43.1925 19.1757 44 19.9946 44 20.999C44 22.0033 43.1925 22.8222 42.2021 22.8222H39.7959V25.1768C39.7959 26.1811 38.9904 27 37.9979 27C37.0075 27 36.2 26.1811 36.2 25.1768V22.8222H33.7979C32.8055 22.8222 32 22.0033 32 20.999C32 19.9946 32.8055 19.1757 33.7979 19.1757H36.2V16.8232C36.2 15.8189 37.0075 15 37.9979 15ZM19 5C24.5091 5 28.9253 9.47326 28.9253 15.0537C28.9253 20.6341 24.5091 25.1074 19 25.1074C13.4909 25.1074 9.07474 20.6341 9.07474 15.0537C9.07474 9.47326 13.4909 5 19 5Z" />
              </svg>
            </div>
            500 Refers
          </li>
          <div className="referRewards__arrow">
            <svg viewBox="0 0 120 125" xmlns="http://www.w3.org/2000/svg">
              <path d="M47.4627 94.4916H5.17065C2.40923 94.4916 0.170654 92.2531 0.170654 89.4916L0.170654 34.7802C0.170654 32.0188 2.40922 29.7802 5.17065 29.7802H47.4627C50.2241 29.7802 52.4627 27.5416 52.4627 24.7802V5.90049C52.4627 1.44597 57.8484 -0.784882 60.9982 2.36494L117.561 58.9272C119.513 60.8798 119.513 64.0457 117.561 65.9983L60.9982 122.561C57.8484 125.71 52.4627 123.48 52.4627 119.025V99.4916C52.4627 96.7302 50.2241 94.4916 47.4627 94.4916Z" />
            </svg>
          </div>
        </div>
        <div>
          <div className="referRewards__arrow">
            <svg viewBox="0 0 120 125" xmlns="http://www.w3.org/2000/svg">
              <path d="M47.4627 94.4916H5.17065C2.40923 94.4916 0.170654 92.2531 0.170654 89.4916L0.170654 34.7802C0.170654 32.0188 2.40922 29.7802 5.17065 29.7802H47.4627C50.2241 29.7802 52.4627 27.5416 52.4627 24.7802V5.90049C52.4627 1.44597 57.8484 -0.784882 60.9982 2.36494L117.561 58.9272C119.513 60.8798 119.513 64.0457 117.561 65.9983L60.9982 122.561C57.8484 125.71 52.4627 123.48 52.4627 119.025V99.4916C52.4627 96.7302 50.2241 94.4916 47.4627 94.4916Z" />
            </svg>
          </div>
          <li className="flex-column locked ">
            <div className="referRewards__imgContainer rounded">
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 30.0304C27.1336 30.0304 34 31.3687 34 36.5375C34 41.7063 27.0896 43 19 43C10.8664 43 4 41.6596 4 36.4929C4 31.3241 10.9084 30.0304 19 30.0304ZM37.9979 15C38.9904 15 39.7959 15.8189 39.7959 16.8232V19.1757H42.2021C43.1925 19.1757 44 19.9946 44 20.999C44 22.0033 43.1925 22.8222 42.2021 22.8222H39.7959V25.1768C39.7959 26.1811 38.9904 27 37.9979 27C37.0075 27 36.2 26.1811 36.2 25.1768V22.8222H33.7979C32.8055 22.8222 32 22.0033 32 20.999C32 19.9946 32.8055 19.1757 33.7979 19.1757H36.2V16.8232C36.2 15.8189 37.0075 15 37.9979 15ZM19 5C24.5091 5 28.9253 9.47326 28.9253 15.0537C28.9253 20.6341 24.5091 25.1074 19 25.1074C13.4909 25.1074 9.07474 20.6341 9.07474 15.0537C9.07474 9.47326 13.4909 5 19 5Z" />
              </svg>
            </div>
            750 Refers
          </li>
          <div className="referRewards__arrow">
            <svg viewBox="0 0 120 125" xmlns="http://www.w3.org/2000/svg">
              <path d="M47.4627 94.4916H5.17065C2.40923 94.4916 0.170654 92.2531 0.170654 89.4916L0.170654 34.7802C0.170654 32.0188 2.40922 29.7802 5.17065 29.7802H47.4627C50.2241 29.7802 52.4627 27.5416 52.4627 24.7802V5.90049C52.4627 1.44597 57.8484 -0.784882 60.9982 2.36494L117.561 58.9272C119.513 60.8798 119.513 64.0457 117.561 65.9983L60.9982 122.561C57.8484 125.71 52.4627 123.48 52.4627 119.025V99.4916C52.4627 96.7302 50.2241 94.4916 47.4627 94.4916Z" />
            </svg>
          </div>
          <li className="flex-column locked ">
            <div className="referRewards__imgContainer rounded">
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 30.0304C27.1336 30.0304 34 31.3687 34 36.5375C34 41.7063 27.0896 43 19 43C10.8664 43 4 41.6596 4 36.4929C4 31.3241 10.9084 30.0304 19 30.0304ZM37.9979 15C38.9904 15 39.7959 15.8189 39.7959 16.8232V19.1757H42.2021C43.1925 19.1757 44 19.9946 44 20.999C44 22.0033 43.1925 22.8222 42.2021 22.8222H39.7959V25.1768C39.7959 26.1811 38.9904 27 37.9979 27C37.0075 27 36.2 26.1811 36.2 25.1768V22.8222H33.7979C32.8055 22.8222 32 22.0033 32 20.999C32 19.9946 32.8055 19.1757 33.7979 19.1757H36.2V16.8232C36.2 15.8189 37.0075 15 37.9979 15ZM19 5C24.5091 5 28.9253 9.47326 28.9253 15.0537C28.9253 20.6341 24.5091 25.1074 19 25.1074C13.4909 25.1074 9.07474 20.6341 9.07474 15.0537C9.07474 9.47326 13.4909 5 19 5Z" />
              </svg>
            </div>
            1000 Refers
          </li>
        </div>
      </ul>
    </div>
  );
};

export default ReferRewards;
