import {
  ADD_AND_REMOVE_CATEGORIES_COMMUNITIES,
  ADD_AND_REMOVE_CATEGORIES_GAME,
  ADD_AND_REMOVE_CATEGORIES_SEARCH,
  ADD_AND_REMOVE_CATEGORIES_SHOP,
  ADD_AND_REMOVE_CATEGORIES_VIDEOS,
} from "../constants/categoriesConstants";

export const categoriesReducer = (
  state = {
    searchCategories: [],
    videosCategories: [],
    gameCategories: [],
    shopCategories: [],
    communitiesCategories: [],
  },
  action
) => {
  let existCategory;
  switch (action.type) {
    case ADD_AND_REMOVE_CATEGORIES_SEARCH:
      existCategory = state.searchCategories.find(
        (e) => e.id === action.payload.id
      );
      if (action.payload.category === "All") {
        return {
          ...state,
          searchCategories: [],
        };
      }
      if (existCategory) {
        return {
          ...state,
          searchCategories: state.searchCategories.filter(
            (category) => category.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        searchCategories: [...state.searchCategories, action.payload],
      };
    case ADD_AND_REMOVE_CATEGORIES_GAME:
      existCategory = state.gameCategories.find(
        (e) => e.id === action.payload.id
      );
      if (action.payload.category === "All") {
        return {
          ...state,
          gameCategories: [],
        };
      }
      if (existCategory) {
        return {
          ...state,
          gameCategories: state.gameCategories.filter(
            (category) => category.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        gameCategories: [...state.gameCategories, action.payload],
      };
    case ADD_AND_REMOVE_CATEGORIES_VIDEOS:
      existCategory = state.videosCategories.find(
        (e) => e.id === action.payload.id
      );
      if (action.payload.category === "All") {
        return {
          ...state,
          videosCategories: [],
        };
      }
      if (existCategory) {
        return {
          ...state,
          videosCategories: state.videosCategories.filter(
            (category) => category.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        videosCategories: [...state.videosCategories, action.payload],
      };
    case ADD_AND_REMOVE_CATEGORIES_COMMUNITIES:
      existCategory = state.communitiesCategories.find(
        (e) => e.id === action.payload.id
      );
      if (action.payload.category === "All") {
        return {
          ...state,
          communitiesCategories: [],
        };
      }
      if (existCategory) {
        return {
          ...state,
          communitiesCategories: state.communitiesCategories.filter(
            (category) => category.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        communitiesCategories: [...state.communitiesCategories, action.payload],
      };
    case ADD_AND_REMOVE_CATEGORIES_SHOP:
      existCategory = state.shopCategories.find(
        (e) => e.id === action.payload.id
      );
      if (action.payload.category === "All") {
        return {
          ...state,
          shopCategories: [],
        };
      }
      if (existCategory) {
        return {
          ...state,
          shopCategories: state.shopCategories.filter(
            (category) => category.id !== action.payload.id
          ),
        };
      }
      return {
        ...state,
        shopCategories: [...state.shopCategories, action.payload],
      };
    default:
      return state;
  }
};
