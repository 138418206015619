import { useState } from "react";
import "./Credits.css";

const Credits = () => {
  const [monthly, setMonthly] = useState(true);
  const isGift = window.location.search.substr(1) === "gift";
  return (
    <div className="credits">
      <h1>Hey, Abraham! Wanna buy some credits?</h1>
      <p className="credits__description">
        Credits are virtual currency of GeniuSparkle and you can use credits to
        upgrade your plan, buy anythings, you want and many more!
      </p>
      <div className="credits__toggle">
        <input
          type="checkbox"
          checked={!monthly}
          onChange={() => setMonthly((monthly) => !monthly)}
        />
        <span>Monthly</span>
        <span>{isGift ? "One Time" : "Yearly"}</span>
        <span className="credits__box"></span>
      </div>
      <ul className="credits__options">
        <li className="credits__option">
          <h4 className="flex-row">
            {isGift ? (
              <>
                <svg
                  viewBox="0 0 16 16"
                  fill="#ea3943"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.612 2.347L8 2.997L7.388 2.347C5.698 0.552003 2.958 0.552003 1.268 2.347C-0.421996 4.142 -0.421996 7.053 1.268 8.849L1.88 9.499L8 16L14.12 9.498L14.732 8.848C16.422 7.053 16.422 4.142 14.732 2.346C13.042 0.551003 10.302 0.551003 8.612 2.347Z" />
                </svg>
                Red Love
              </>
            ) : (
              "Value For Money"
            )}
          </h4>
          <div>
            <h3 className="credits__optionPrice">100 Credits</h3>
            <span className="credits__optionPriceYear">
              {isGift ? (
                `Sent ${monthly ? "Monthly" : "One Time"}`
              ) : (
                <>Purchase at {!monthly ? "$10.00/yr" : "$1.00/mo"}</>
              )}
            </span>
          </div>
          <button
            className="btn flex-row"
            onClick={() =>
              (window.location.href =
                "https://geniusparkle.com/pricing.html?upgrade")
            }
          >
            {isGift ? "Send Credits" : "Get Credits"}{" "}
            <svg
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.02002 5H15.02"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.02 9L15.02 5"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.02 1L15.02 5"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </li>
        <li className="credits__option best-price">
          <h4>
            {isGift ? (
              <>
                <svg
                  viewBox="0 0 16 16"
                  fill="#fff"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.612 2.347L8 2.997L7.388 2.347C5.698 0.552003 2.958 0.552003 1.268 2.347C-0.421996 4.142 -0.421996 7.053 1.268 8.849L1.88 9.499L8 16L14.12 9.498L14.732 8.848C16.422 7.053 16.422 4.142 14.732 2.346C13.042 0.551003 10.302 0.551003 8.612 2.347Z" />
                </svg>
                White Love
              </>
            ) : (
              "Value For Money"
            )}
          </h4>
          <div>
            <h3 className="credits__optionPrice">500 Credits</h3>
            <span className="credits__optionPriceYear">
              {isGift ? (
                `Sent ${monthly ? "Monthly" : "One Time"}`
              ) : (
                <>Purchase at {!monthly ? "$50.00/yr" : "$5.00/mo"}</>
              )}
            </span>
          </div>
          <button
            className="btn flex-row"
            onClick={() =>
              (window.location.href =
                "https://geniusparkle.com/pricing.html?upgrade")
            }
          >
            {isGift ? "Send Credits" : "Get Credits"}{" "}
            <svg
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.02002 5H15.02"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.02 9L15.02 5"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.02 1L15.02 5"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </li>
        <li className="credits__option">
          <h4>
            {isGift ? (
              <>
                <svg
                  viewBox="0 0 16 16"
                  fill="#000"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M8.612 2.347L8 2.997L7.388 2.347C5.698 0.552003 2.958 0.552003 1.268 2.347C-0.421996 4.142 -0.421996 7.053 1.268 8.849L1.88 9.499L8 16L14.12 9.498L14.732 8.848C16.422 7.053 16.422 4.142 14.732 2.346C13.042 0.551003 10.302 0.551003 8.612 2.347Z" />
                </svg>
                Black Love
              </>
            ) : (
              "Premium"
            )}
          </h4>
          <div>
            <h3 className="credits__optionPrice">2500 Credits</h3>
            <span className="credits__optionPriceYear">
              {isGift ? (
                `Sent ${monthly ? "Monthly" : "One Time"}`
              ) : (
                <>Purchase at {!monthly ? "$250.00/yr" : "$25.00/mo"}</>
              )}
            </span>
          </div>
          <button
            className="btn flex-row"
            onClick={() =>
              (window.location.href =
                "https://geniusparkle.com/pricing.html?upgrade")
            }
          >
            {isGift ? "Send Credits" : "Get Credits"}{" "}
            <svg
              viewBox="0 0 16 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.02002 5H15.02"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.02 9L15.02 5"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11.02 1L15.02 5"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Credits;
