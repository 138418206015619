import {
  SEARCH_UNSPLASH_FAIL,
  SEARCH_UNSPLASH_REQUEST,
  SEARCH_UNSPLASH_SUCCESS,
} from "../constants/searchConstants";

export const searchImagesUnsplashReducer = (state = {}, action) => {
  switch (action.type) {
    case SEARCH_UNSPLASH_REQUEST:
      return { loading: true };
    case SEARCH_UNSPLASH_SUCCESS:
      return {
        loading: false,
        success: true,
        images: action.payload,
      };
    case SEARCH_UNSPLASH_FAIL:
      return { loading: false, error: action.payload };

    default:
      return state;
  }
};
