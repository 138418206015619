import Categories from "../../components/Categories";
import ContentSection from "../../components/ContentSection";
import interested from "../../utils/interested.json";
import { useSelector } from "react-redux";
import VideosTeacher from "../../components/VideosTeacher";
import { useParams } from "react-router";
import "./Videos.css";

const Videos = () => {
  let { id } = useParams();
  const { topicVideos } = useSelector((state) => state.topic);
  const { mode } = useSelector((state) => state.mode);

  return (
    <div className="videos">
      {mode === "teacher" ? (
        <VideosTeacher />
      ) : (
        <>
          {!id && (
            <Categories
              getCategories={
                topicVideos.length
                  ? [
                      {
                        id: 0,
                        default: true,
                        category: "All",
                        icon: "https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/426aa188217db7bb6ee29426086bd5fd92c528bf/icon-all.svg",
                      },
                      ...topicVideos,
                    ]
                  : [
                      {
                        id: 0,
                        default: true,
                        category: "All",
                        icon: "https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/426aa188217db7bb6ee29426086bd5fd92c528bf/icon-all.svg",
                      },
                      ...interested,
                    ]
              }
              type="videos"
            />
          )}
          <div className={`videos__container ${id && "videos-teacher"}`}>
            <ContentSection id={id} />
          </div>
        </>
      )}
    </div>
  );
};

export default Videos;
