import { SET_MENU } from "../constants/menuConstant";

export const menuReducer = (state = { menu: "" }, action) => {
  switch (action.type) {
    case SET_MENU:
      const menuName =
        action.payload === ""
          ? "Dashboard"
          : action.payload.replace(/(?:_| |\b)(\w)/g, function (str, p1) {
              return p1.toUpperCase();
            });

      console.log(action.payload);

      return {
        menu: menuName,
      };
    default:
      return state;
  }
};
