import { ADD_NEW_QUIZ, REMOVE_QUIZ } from "../constants/quizConstants";

export const addQuiz = (quiz) => async (dispatch, getState) => {
  dispatch({
    type: ADD_NEW_QUIZ,
    payload: quiz,
  });
  localStorage.setItem(
    "gsprklQuizzes",
    JSON.stringify(getState().quiz.quizzes)
  );
};
export const removeQuiz = (quizId) => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_QUIZ,
    payload: quizId,
  });
  localStorage.setItem(
    "gsprklQuizzes",
    JSON.stringify(getState().quiz.quizzes)
  );
};
