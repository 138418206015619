import React from "react";

const Rating = ({ rating, number }) => {
  return (
    <>
      {number ? (
        <div className="rating flex-row">
          <span>{rating?.toFixed(1)}</span>
          <svg
            width="18"
            height="18"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M35.8368 28.64C35.3188 29.142 35.0808 29.868 35.1988 30.58L36.9768 40.42C37.1268 41.254 36.7748 42.098 36.0768 42.58C35.3928 43.08 34.4828 43.14 33.7368 42.74L24.8788 38.12C24.5708 37.956 24.2288 37.868 23.8788 37.858H23.3368C23.1488 37.886 22.9648 37.946 22.7968 38.038L13.9368 42.68C13.4988 42.9 13.0028 42.978 12.5168 42.9C11.3328 42.676 10.5428 41.548 10.7368 40.358L12.5168 30.518C12.6348 29.8 12.3968 29.07 11.8788 28.56L4.65677 21.56C4.05278 20.974 3.84278 20.094 4.11878 19.3C4.38678 18.508 5.07077 17.93 5.89677 17.8L15.8368 16.358C16.5928 16.28 17.2568 15.82 17.5968 15.14L21.9768 6.16C22.0808 5.96 22.2148 5.776 22.3768 5.62L22.5568 5.48C22.6508 5.376 22.7588 5.29 22.8788 5.22L23.0968 5.14L23.4368 5H24.2788C25.0308 5.078 25.6928 5.528 26.0388 6.2L30.4768 15.14C30.7968 15.794 31.4188 16.248 32.1368 16.358L42.0768 17.8C42.9168 17.92 43.6188 18.5 43.8968 19.3C44.1588 20.102 43.9328 20.982 43.3168 21.56L35.8368 28.64Z"
              fill="#FFB800"
            />
          </svg>
        </div>
      ) : (
        <div className="rating flex-row">
          <div>
            {rating >= 1 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.8368 28.64C35.3188 29.142 35.0808 29.868 35.1988 30.58L36.9768 40.42C37.1268 41.254 36.7748 42.098 36.0768 42.58C35.3928 43.08 34.4828 43.14 33.7368 42.74L24.8788 38.12C24.5708 37.956 24.2288 37.868 23.8788 37.858H23.3368C23.1488 37.886 22.9648 37.946 22.7968 38.038L13.9368 42.68C13.4988 42.9 13.0028 42.978 12.5168 42.9C11.3328 42.676 10.5428 41.548 10.7368 40.358L12.5168 30.518C12.6348 29.8 12.3968 29.07 11.8788 28.56L4.65677 21.56C4.05278 20.974 3.84278 20.094 4.11878 19.3C4.38678 18.508 5.07077 17.93 5.89677 17.8L15.8368 16.358C16.5928 16.28 17.2568 15.82 17.5968 15.14L21.9768 6.16C22.0808 5.96 22.2148 5.776 22.3768 5.62L22.5568 5.48C22.6508 5.376 22.7588 5.29 22.8788 5.22L23.0968 5.14L23.4368 5H24.2788C25.0308 5.078 25.6928 5.528 26.0388 6.2L30.4768 15.14C30.7968 15.794 31.4188 16.248 32.1368 16.358L42.0768 17.8C42.9168 17.92 43.6188 18.5 43.8968 19.3C44.1588 20.102 43.9328 20.982 43.3168 21.56L35.8368 28.64Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating < 1 && rating > 0.5 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M12.0513 10.9244C12.007 10.6574 11.857 11.1127 12.0513 10.9244V15.7146L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425V10.9244Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating === 0.5 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M9 10.443V14.1992C8.9849 14.198 8.96974 14.1972 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9V10.443Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating < 0.5 && rating > 0 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M6.5988 11V15.2859L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775V11Z"
                  fill="#FFB800"
                />
              </svg>
            ) : (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
              </svg>
            )}
          </div>
          <div>
            {rating >= 2 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.8368 28.64C35.3188 29.142 35.0808 29.868 35.1988 30.58L36.9768 40.42C37.1268 41.254 36.7748 42.098 36.0768 42.58C35.3928 43.08 34.4828 43.14 33.7368 42.74L24.8788 38.12C24.5708 37.956 24.2288 37.868 23.8788 37.858H23.3368C23.1488 37.886 22.9648 37.946 22.7968 38.038L13.9368 42.68C13.4988 42.9 13.0028 42.978 12.5168 42.9C11.3328 42.676 10.5428 41.548 10.7368 40.358L12.5168 30.518C12.6348 29.8 12.3968 29.07 11.8788 28.56L4.65677 21.56C4.05278 20.974 3.84278 20.094 4.11878 19.3C4.38678 18.508 5.07077 17.93 5.89677 17.8L15.8368 16.358C16.5928 16.28 17.2568 15.82 17.5968 15.14L21.9768 6.16C22.0808 5.96 22.2148 5.776 22.3768 5.62L22.5568 5.48C22.6508 5.376 22.7588 5.29 22.8788 5.22L23.0968 5.14L23.4368 5H24.2788C25.0308 5.078 25.6928 5.528 26.0388 6.2L30.4768 15.14C30.7968 15.794 31.4188 16.248 32.1368 16.358L42.0768 17.8C42.9168 17.92 43.6188 18.5 43.8968 19.3C44.1588 20.102 43.9328 20.982 43.3168 21.56L35.8368 28.64Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating < 2 && rating > 1.5 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M12.0513 10.9244C12.007 10.6574 11.857 11.1127 12.0513 10.9244V15.7146L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425V10.9244Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating === 1.5 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M9 10.443V14.1992C8.9849 14.198 8.96974 14.1972 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9V10.443Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating < 1.5 && rating > 1 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M6.5988 11V15.2859L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775V11Z"
                  fill="#FFB800"
                />
              </svg>
            ) : (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
              </svg>
            )}
          </div>
          <div>
            {rating >= 3 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.8368 28.64C35.3188 29.142 35.0808 29.868 35.1988 30.58L36.9768 40.42C37.1268 41.254 36.7748 42.098 36.0768 42.58C35.3928 43.08 34.4828 43.14 33.7368 42.74L24.8788 38.12C24.5708 37.956 24.2288 37.868 23.8788 37.858H23.3368C23.1488 37.886 22.9648 37.946 22.7968 38.038L13.9368 42.68C13.4988 42.9 13.0028 42.978 12.5168 42.9C11.3328 42.676 10.5428 41.548 10.7368 40.358L12.5168 30.518C12.6348 29.8 12.3968 29.07 11.8788 28.56L4.65677 21.56C4.05278 20.974 3.84278 20.094 4.11878 19.3C4.38678 18.508 5.07077 17.93 5.89677 17.8L15.8368 16.358C16.5928 16.28 17.2568 15.82 17.5968 15.14L21.9768 6.16C22.0808 5.96 22.2148 5.776 22.3768 5.62L22.5568 5.48C22.6508 5.376 22.7588 5.29 22.8788 5.22L23.0968 5.14L23.4368 5H24.2788C25.0308 5.078 25.6928 5.528 26.0388 6.2L30.4768 15.14C30.7968 15.794 31.4188 16.248 32.1368 16.358L42.0768 17.8C42.9168 17.92 43.6188 18.5 43.8968 19.3C44.1588 20.102 43.9328 20.982 43.3168 21.56L35.8368 28.64Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating < 3 && rating > 2.5 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M12.0513 10.9244C12.007 10.6574 11.857 11.1127 12.0513 10.9244V15.7146L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425V10.9244Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating === 2.5 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M9 10.443V14.1992C8.9849 14.198 8.96974 14.1972 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9V10.443Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating < 2.5 && rating > 2 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M6.5988 11V15.2859L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775V11Z"
                  fill="#FFB800"
                />
              </svg>
            ) : (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
              </svg>
            )}
          </div>
          <div>
            {rating >= 4 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.8368 28.64C35.3188 29.142 35.0808 29.868 35.1988 30.58L36.9768 40.42C37.1268 41.254 36.7748 42.098 36.0768 42.58C35.3928 43.08 34.4828 43.14 33.7368 42.74L24.8788 38.12C24.5708 37.956 24.2288 37.868 23.8788 37.858H23.3368C23.1488 37.886 22.9648 37.946 22.7968 38.038L13.9368 42.68C13.4988 42.9 13.0028 42.978 12.5168 42.9C11.3328 42.676 10.5428 41.548 10.7368 40.358L12.5168 30.518C12.6348 29.8 12.3968 29.07 11.8788 28.56L4.65677 21.56C4.05278 20.974 3.84278 20.094 4.11878 19.3C4.38678 18.508 5.07077 17.93 5.89677 17.8L15.8368 16.358C16.5928 16.28 17.2568 15.82 17.5968 15.14L21.9768 6.16C22.0808 5.96 22.2148 5.776 22.3768 5.62L22.5568 5.48C22.6508 5.376 22.7588 5.29 22.8788 5.22L23.0968 5.14L23.4368 5H24.2788C25.0308 5.078 25.6928 5.528 26.0388 6.2L30.4768 15.14C30.7968 15.794 31.4188 16.248 32.1368 16.358L42.0768 17.8C42.9168 17.92 43.6188 18.5 43.8968 19.3C44.1588 20.102 43.9328 20.982 43.3168 21.56L35.8368 28.64Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating < 4 && rating > 3.5 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M12.0513 10.9244C12.007 10.6574 11.857 11.1127 12.0513 10.9244V15.7146L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425V10.9244Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating === 3.5 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M9 10.443V14.1992C8.9849 14.198 8.96974 14.1972 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9V10.443Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating < 3.5 && rating > 3 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M6.5988 11V15.2859L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775V11Z"
                  fill="#FFB800"
                />
              </svg>
            ) : (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
              </svg>
            )}
          </div>
          <div>
            {rating >= 5 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M35.8368 28.64C35.3188 29.142 35.0808 29.868 35.1988 30.58L36.9768 40.42C37.1268 41.254 36.7748 42.098 36.0768 42.58C35.3928 43.08 34.4828 43.14 33.7368 42.74L24.8788 38.12C24.5708 37.956 24.2288 37.868 23.8788 37.858H23.3368C23.1488 37.886 22.9648 37.946 22.7968 38.038L13.9368 42.68C13.4988 42.9 13.0028 42.978 12.5168 42.9C11.3328 42.676 10.5428 41.548 10.7368 40.358L12.5168 30.518C12.6348 29.8 12.3968 29.07 11.8788 28.56L4.65677 21.56C4.05278 20.974 3.84278 20.094 4.11878 19.3C4.38678 18.508 5.07077 17.93 5.89677 17.8L15.8368 16.358C16.5928 16.28 17.2568 15.82 17.5968 15.14L21.9768 6.16C22.0808 5.96 22.2148 5.776 22.3768 5.62L22.5568 5.48C22.6508 5.376 22.7588 5.29 22.8788 5.22L23.0968 5.14L23.4368 5H24.2788C25.0308 5.078 25.6928 5.528 26.0388 6.2L30.4768 15.14C30.7968 15.794 31.4188 16.248 32.1368 16.358L42.0768 17.8C42.9168 17.92 43.6188 18.5 43.8968 19.3C44.1588 20.102 43.9328 20.982 43.3168 21.56L35.8368 28.64Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating < 5 && rating > 4.5 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M12.0513 10.9244C12.007 10.6574 11.857 11.1127 12.0513 10.9244V15.7146L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425V10.9244Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating === 4.5 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M9 10.443V14.1992C8.9849 14.198 8.96974 14.1972 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9V10.443Z"
                  fill="#FFB800"
                />
              </svg>
            ) : rating < 4.5 && rating > 4 ? (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
                <path
                  d="M6.5988 11V15.2859L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4443C4.73805 11.175 4.6488 10.9013 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775V11Z"
                  fill="#FFB800"
                />
              </svg>
            ) : (
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.4388 10.74C13.2445 10.9282 13.1553 11.2005 13.1995 11.4675L13.8663 15.1575C13.9225 15.4702 13.7905 15.7868 13.5288 15.9675C13.2723 16.155 12.931 16.1775 12.6513 16.0275L9.32955 14.295C9.21405 14.2335 9.0858 14.2005 8.95455 14.1968H8.7513C8.6808 14.2073 8.6118 14.2297 8.5488 14.2642L5.2263 16.005C5.06205 16.0875 4.87605 16.1168 4.6938 16.0875C4.2498 16.0035 3.95355 15.5805 4.0263 15.1343L4.6938 11.4442C4.73805 11.175 4.6488 10.9012 4.45455 10.71L1.74629 8.085C1.51979 7.86525 1.44104 7.53525 1.54454 7.2375C1.64504 6.9405 1.90154 6.72375 2.21129 6.675L5.9388 6.13425C6.2223 6.105 6.4713 5.9325 6.5988 5.6775L8.2413 2.31C8.2803 2.235 8.33055 2.166 8.3913 2.1075L8.4588 2.055C8.49405 2.016 8.53455 1.98375 8.57955 1.9575L8.6613 1.9275L8.7888 1.875H9.10455C9.38655 1.90425 9.6348 2.073 9.76455 2.325L11.4288 5.6775C11.5488 5.92275 11.782 6.093 12.0513 6.13425L15.7788 6.675C16.0938 6.72 16.357 6.9375 16.4613 7.2375C16.5595 7.53825 16.4748 7.86825 16.2438 8.085L13.4388 10.74Z"
                  fill="#F4F4F4"
                />
              </svg>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Rating;
