import moment from "moment";
import SwiperCore, { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import "./OverviewSlider.css";

SwiperCore.use([Pagination]);

const OverviewSlider = () => {
  return (
    <Swiper
      className="overviewSlider"
      spaceBetween={120}
      effect="fade"
      pagination={{
        clickable: true,
        el: ".overviewSlider__pagination",
      }}
      mousewheel={{ invert: false }}
    >
      <SwiperSlide className="overviewSlider__item swiper-slide">
        <div className="overviewSlider__img">
          <img src="/images/icons/discord.svg" alt="" />
        </div>
        <div className="overviewSlider__content">
          <span className="overviewSlider__code">
            {moment(new Date()).format("LL")}
          </span>
          <div className="overviewSlider__title">
            Hey Abraham! Join The Party In Our Discord!
          </div>
          <div className="overviewSlider__text">
            In our discord server, we have exclusive games, future updates,
            poils, support and a lot more going on. Come join us :{")"}
          </div>
          <a href="!#" className="overviewSlider__button">
            Take Me There!
          </a>
        </div>
      </SwiperSlide>
      <SwiperSlide className="overviewSlider__item swiper-slide">
        <div className="overviewSlider__img">
          <img
            src="https://res.cloudinary.com/muhammederdem/image/upload/v1535759871/jason-leung-798979-unsplash.jpg"
            alt=""
          />
        </div>
        <div className="overviewSlider__content">
          <span className="overviewSlider__code">
            {moment(new Date()).format("LL")}
          </span>
          <div className="overviewSlider__title">
            Hey, Abraham! Team Games Coming Soon. Excited?
          </div>
          <div className="overviewSlider__text">
            In team games, instead of playing with random geniuses every match,
            you can join matches with your own team :{")"}
          </div>
          <a href="!#" className="overviewSlider__button">
            Yeah, I'm Excited!
          </a>
        </div>
      </SwiperSlide>
      <div className="overviewSlider__pagination"></div>
    </Swiper>
  );
};

export default OverviewSlider;
