// import { useEffect } from "react";
import { useSelector } from "react-redux";
import ReferRewards from "../../components/ReferRewards";
import "./Refer.css";

const Refer = () => {
  const { mode } = useSelector((state) => state.mode);
  return (
    <div className="refer">
      <div className="refer__cta flex-row">
        <svg
          className="refer__ctaBackgroundImg"
          viewBox="0 0 656 280"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.15"
            d="M312.417 183.436C258.503 144.705 184.203 189.965 125.32 159.314C94.6825 143.366 88.4693 128.975 66.4842 105.185C52.3828 89.9258 51.0387 87.2693 37.6548 76.3554C24.2708 65.4415 0 52.8212 0 52.8212V12.813H633.659V279.338H482.452C482.452 279.338 401.69 280.596 363.015 247.567C339.493 227.478 340.016 203.263 312.417 183.436Z"
            fill="white"
          />
          <path
            opacity="0.15"
            d="M334.186 170.623C280.272 131.892 205.972 187.154 147.088 156.503C116.451 140.555 100.412 84.0368 91.4889 67.334C86.5994 58.1818 79.1335 49.9448 67.0068 43.5383C54.8801 37.1318 21.7686 40.0082 21.7686 40.0082V0H655.428V249.79H513.634C513.634 249.79 414.202 252.993 381.842 230.047C353.601 210.022 361.784 190.45 334.186 170.623Z"
            fill="white"
          />
        </svg>
        <img
          src="https://gist.githubusercontent.com/faustabmnyh/070481fe272d83fc3cc0cd704121a794/raw/e1b074dce544075a557f91449c3cd435db510582/email.svg"
          alt="email"
          className="refer__ctaImg"
        />
        <div className="refer__ctaAction">
          <h1>
            {mode === "teacher" || mode === "learning" || mode === "teaching"
              ? "Invite Students And Earn Commission"
              : "Invite Your Friends And Earn Money"}
          </h1>
          <form className="flex-row">
            <input type="text" placeholder="https:\\geniusparkle.com" />
            <button className="btn">Copy Link</button>
          </form>
        </div>
      </div>
      <div className="refer__works flex-column">
        <h1>How It Works</h1>
        <ul>
          <li>
            <div>
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M19 30.0304C27.1336 30.0304 34 31.3687 34 36.5375C34 41.7063 27.0896 43 19 43C10.8664 43 4 41.6596 4 36.4929C4 31.3241 10.9084 30.0304 19 30.0304ZM37.9979 15C38.9904 15 39.7959 15.8189 39.7959 16.8232V19.1757H42.2021C43.1925 19.1757 44 19.9946 44 20.999C44 22.0033 43.1925 22.8222 42.2021 22.8222H39.7959V25.1768C39.7959 26.1811 38.9904 27 37.9979 27C37.0075 27 36.2 26.1811 36.2 25.1768V22.8222H33.7979C32.8055 22.8222 32 22.0033 32 20.999C32 19.9946 32.8055 19.1757 33.7979 19.1757H36.2V16.8232C36.2 15.8189 37.0075 15 37.9979 15ZM19 5C24.5091 5 28.9253 9.47326 28.9253 15.0537C28.9253 20.6341 24.5091 25.1074 19 25.1074C13.4909 25.1074 9.07474 20.6341 9.07474 15.0537C9.07474 9.47326 13.4909 5 19 5Z" />
              </svg>
            </div>
            <h3>Invite</h3>
            <span>Invite your friends to GeniuSparkle</span>
          </li>
          <li>
            <div>
              <svg viewBox="0 0 49 49" xmlns="http://www.w3.org/2000/svg">
                <path d="M33.5073 6.61908C40.4051 6.61908 44.4762 10.5883 44.4762 17.3827H36.014V17.452C32.0866 17.452 28.9029 20.5561 28.9029 24.3851C28.9029 28.2141 32.0866 31.3182 36.014 31.3182H44.4762V31.9421C44.4762 38.6499 40.4051 42.6191 33.5073 42.6191H15.4451C8.54731 42.6191 4.4762 38.6499 4.4762 31.9421V17.296C4.4762 10.5883 8.54731 6.61908 15.4451 6.61908H33.5073ZM42.9829 20.3639C43.8076 20.3639 44.4762 21.0158 44.4762 21.8198V26.881C44.4666 27.6812 43.8036 28.3276 42.9829 28.3369H36.174C34.1858 28.363 32.4472 27.0358 31.9962 25.1477C31.7704 23.9757 32.0874 22.7662 32.8623 21.8435C33.6373 20.9208 34.7909 20.3792 36.014 20.3639H42.9829ZM36.974 22.7038H36.3162C35.9124 22.6992 35.5234 22.8524 35.2362 23.1292C34.9489 23.406 34.7873 23.7833 34.7873 24.1771C34.7873 25.0032 35.4689 25.6755 36.3162 25.685H36.974C37.8184 25.685 38.5029 25.0177 38.5029 24.1944C38.5029 23.3712 37.8184 22.7038 36.974 22.7038ZM25.2406 14.4015H13.9518C13.1143 14.4014 12.4326 15.0583 12.4229 15.8747C12.4229 16.7008 13.1045 17.3732 13.9518 17.3827H25.2406C26.085 17.3827 26.7695 16.7153 26.7695 15.8921C26.7695 15.0688 26.085 14.4015 25.2406 14.4015Z" />
              </svg>
            </div>
            <h3>Purchase</h3>
            <span>They join GeniuSparkle and purchase a plan</span>
          </li>
          <li>
            <div>
              <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
                <path d="M19.7305 5.77201C22.0714 3.41401 25.8927 3.41401 28.2536 5.75401L29.7141 7.21201C30.2743 7.77201 31.0346 8.09401 31.8349 8.09401H33.8956C37.2168 8.09401 39.9178 10.792 39.9178 14.112V16.174C39.9178 16.972 40.2379 17.732 40.7981 18.292L42.2387 19.732C43.3791 20.852 43.9993 22.372 43.9993 23.972C44.0193 25.572 43.3991 27.094 42.2787 28.232L40.7981 29.712C40.2379 30.272 39.9178 31.032 39.9178 31.834V33.892C39.9178 37.212 37.2168 39.914 33.8956 39.914H31.8349C31.0346 39.914 30.2743 40.232 29.7141 40.792L28.2736 42.232C27.0932 43.414 25.5526 43.992 24.0121 43.992C22.4715 43.992 20.9309 43.414 19.7505 42.254L18.29 40.792C17.7298 40.232 16.9695 39.914 16.1692 39.914H14.1085C10.7873 39.914 8.08628 37.212 8.08628 33.892V31.834C8.08628 31.032 7.76617 30.272 7.20597 29.692L5.76544 28.272C3.42459 25.934 3.40459 22.112 5.74544 19.754L7.20597 18.292C7.76617 17.732 8.08628 16.972 8.08628 16.152V14.112C8.08628 10.792 10.7873 8.09401 14.1085 8.09401H16.1692C16.9695 8.09401 17.7298 7.77201 18.29 7.21201L19.7305 5.77201ZM29.1539 27.392C28.1736 27.392 27.3933 28.174 27.3933 29.132C27.3933 30.112 28.1736 30.892 29.1539 30.892C30.1143 30.892 30.8946 30.112 30.8946 29.132C30.8946 28.174 30.1143 27.392 29.1539 27.392ZM30.3744 17.612C29.6941 16.934 28.5937 16.934 27.9135 17.612L17.6297 27.892C16.9495 28.572 16.9495 29.692 17.6297 30.372C17.9499 30.712 18.39 30.892 18.8502 30.892C19.3304 30.892 19.7705 30.712 20.0906 30.372L30.3744 20.094C31.0546 19.414 31.0546 18.292 30.3744 17.612ZM18.8702 17.112C17.9098 17.112 17.1096 17.892 17.1096 18.852C17.1096 19.834 17.9098 20.612 18.8702 20.612C19.8305 20.612 20.6108 19.834 20.6108 18.852C20.6108 17.892 19.8305 17.112 18.8702 17.112Z" />
              </svg>
            </div>
            <h3>Commission</h3>
            <span>You get 10% commission from that (one time)</span>
          </li>
          <li>
            <div>
              <svg viewBox="0 0 148 148" xmlns="http://www.w3.org/2000/svg">
                <path d="M74.2312 104.38C45.4695 104.38 22.0555 80.9664 22.0555 52.2047C22.0555 23.4141 45.4695 0 74.2312 0C102.993 0 126.407 23.4141 126.407 52.1758C126.407 80.9664 102.993 104.38 74.2312 104.38ZM74.2312 24.3102C58.8531 24.3102 46.3367 36.8266 46.3367 52.2047C46.3367 67.5828 58.8531 80.0703 74.2312 80.0703C89.6094 80.0703 102.126 67.5539 102.126 52.2047C102.126 36.7977 89.6094 24.3102 74.2312 24.3102Z" />
                <path d="M74.2312 72.3234C85.3585 72.3234 94.3789 63.303 94.3789 52.1758C94.3789 41.0485 85.3585 32.0281 74.2312 32.0281C63.104 32.0281 54.0836 41.0485 54.0836 52.1758C54.0836 63.303 63.104 72.3234 74.2312 72.3234Z" />
                <path d="M28.0969 88.0195L4.82734 127.534L26.6226 130.28L39.5727 148L62.6687 108.803C47.8687 106.78 36.4219 99.7266 28.0969 88.0195Z" />
                <path d="M119.903 88.0195L143.202 127.534L121.406 130.28L108.456 148L85.3601 108.803C100.131 106.78 111.578 99.7266 119.903 88.0195Z" />
              </svg>
            </div>
            <h3>Credit</h3>
            <span>The amount gets credited in your account</span>
          </li>
        </ul>
      </div>
      <ReferRewards />
    </div>
  );
};

export default Refer;
