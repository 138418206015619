import TeacherCreateCard from "../TeacherCreateCard";
import { useSelector } from "react-redux";
import { useState } from "react";
import TeacherTableCreate from "../TeacherTableCreate";
import "./QuizTeacher.css";

const QuizTeacher = () => {
  const [createQuiz, setCreateQuiz] = useState(false);
  const [selectedQuiz, setSelectedQuiz] = useState();
  const { quizzes } = useSelector((state) => state.quiz);

  const handleSelectedQuiz = (quiz) => {
    setCreateQuiz(true);
    setSelectedQuiz(quiz);
  };

  const handleCreateNewQuiz = () => {
    setCreateQuiz(true);
    setSelectedQuiz({
      name: "",
      destinationLink: "",
      description: "",
      topic: "Select quiz topic",
    });
  };

  const handleManageAccess = () => {
    setCreateQuiz(false);
    setSelectedQuiz({
      name: "",
      destinationLink: "",
      description: "",
      topic: "Select quiz topic",
    });
  };

  return (
    <div className="quizTeacher">
      {quizzes.length !== 0 && createQuiz && (
        <button className="btn quizTeacher__btn" onClick={handleManageAccess}>
          Manage Access
        </button>
      )}
      {quizzes.length === 0 || createQuiz ? (
        <TeacherCreateCard
          type="Quiz"
          setCreateCard={() => setCreateQuiz(false)}
          selectedRow={selectedQuiz}
        />
      ) : (
        <TeacherTableCreate
          type="Quiz"
          tableDatas={quizzes}
          handleCreateNewData={handleCreateNewQuiz}
          handleSelectedRow={handleSelectedQuiz}
        />
      )}
    </div>
  );
};

export default QuizTeacher;
