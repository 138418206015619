import { useEffect, useRef } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import CommentCard from "../../components/CommentCard";
import ContentCard from "../../components/ContentCard";
import thumbnails from "../../utils/thumbnails.json";
import "./WatchVideo.css";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewConnection,
  removeFromConnection,
} from "../../actions/connectionActions";
import axios from "axios";

const WatchVideo = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [showMore, setShowMore] = useState(false);
  const { connections } = useSelector((state) => state.connection);
  const [autoPlay, setAutoPlay] = useState(false);
  const [follow, setFollow] = useState(false);
  const [comment, setComment] = useState("");
  const [liked, setLiked] = useState(false);
  const [getVideos, setGetVideos] = useState(thumbnails);
  const [unliked, setUnliked] = useState(false);
  const commentBox = useRef(null);
  const history = useHistory();
  const watchVideoPlayedContainer = useRef(null);
  const addRowTextarea = (e) => {
    if (e.target.scrollTop !== 0) {
      e.target.style.height = e.target.scrollHeight + "px";
    }
  };
  const deleteRowTextarea = (e) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      // if (e.target.scrollHeight > 64) {
      let numberOfLineBreaks = (e.target.value.match(/\n/g) || []).length;
      // min-height + lines x line-height + padding + border
      let newHeight = 20 + numberOfLineBreaks * 20 + 28;
      e.target.style.height = newHeight + "px";
      // }
    }
  };

  let startProductBarPos = -1;

  const hai = (e) => {
    if (startProductBarPos < 0)
      startProductBarPos = findPosY(commentBox.current);

    if (e.target.scrollTop > startProductBarPos - 100) {
      console.log(commentBox);
      commentBox.current.classList.add("fix");
    } else {
      commentBox.current.classList.remove("fix");
    }

    function findPosY(obj) {
      let curtop = 0;
      if (typeof obj.offsetParent !== "undefined" && obj.offsetParent) {
        while (obj.offsetParent) {
          curtop += obj.offsetTop;
          obj = obj.offsetParent;
        }
        curtop += obj.offsetTop;
      } else if (obj.y) curtop += obj.y;
      return curtop;
    }
  };

  useEffect(() => {
    if (window.innerWidth >= 950) {
      const paddingComment =
        window
          .getComputedStyle(commentBox.current, null)
          .getPropertyValue("padding")
          .substr(5, 2) - 28;
      commentBox.current.style.width = `${
        watchVideoPlayedContainer.current.getBoundingClientRect().width +
        paddingComment
      }px`;
      console.log(
        watchVideoPlayedContainer.current.getBoundingClientRect().width +
          paddingComment
      );
      commentBox.current.style.opacity = "1";
      window.onresize = () => {
        commentBox.current.style.width = `${
          watchVideoPlayedContainer.current.getBoundingClientRect().width +
          paddingComment
        }px`;
      };
    }

    async function fecthData() {
      const { token } = JSON.parse(localStorage.getItem("gsprklUserInfo"));
      try {
        const { data } = await axios.get(
          `https://genius-park-stag.herokuapp.com/api/videos/all?access_token=${token}`
        );
        setGetVideos(data.items);
      } catch (err) {
        return err;
      }
    }
    fecthData();
  }, []);

  const likeVideo = () => {
    if (unliked) {
      setUnliked(false);
    }
    setLiked(true);
    if (liked) {
      setLiked(false);
    }
  };
  const unlikeVideo = () => {
    if (liked) {
      setLiked(false);
    }
    setUnliked(true);
    if (unliked) {
      setUnliked(false);
    }
  };

  const video = getVideos.find((thumb) => (thumb.id || thumb.videoId) === id);

  const connected = connections.find((c) => video?.channel?.id === c?.id);

  const handleFollowConnection = () => {
    if (connected || follow) {
      setFollow(false);
      dispatch(removeFromConnection(video.channel.id));
    } else {
      setFollow(true);
      dispatch(addNewConnection(video.channel));
    }
  };

  return (
    <div className="watchVideo flex-row">
      <div
        className="watchVideo__played"
        onScroll={hai}
        ref={watchVideoPlayedContainer}
      >
        <div className="watchVideo__playedVideo">
          <iframe
            src={`https://www.youtube.com/embed/${
              video?.videoId ? video.videoId : "NobBllmrb-Y"
            }?autoplay=1&enablejsapi=1`}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <div className="watchVideo__playedDetails">
          <div className="watchVideo__playedDetailsTitle flex-row">
            <div>
              <h1>{video?.title}</h1>
              <small>600,214 Views</small>
            </div>
            <div className="watchVideo__playedDetailsAction flex-row">
              <div
                className={`watchVideo__playedDetailsActionLike flex-row ${
                  liked && "liked"
                }`}
                onClick={likeVideo}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="2"
                    y="2"
                    width="20"
                    height="20"
                    rx="10"
                    fill="#a4a4a6"
                  />
                  <path
                    d="M16 12L12 8L8 12"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 16V8"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                {liked ? "501" : "500"}
              </div>
              <div
                className={`watchVideo__playedDetailsActionUnlike flex-row ${
                  unliked && "unliked"
                }`}
                onClick={unlikeVideo}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    width="20"
                    height="20"
                    rx="10"
                    transform="matrix(1 0 0 -1 2 22)"
                    fill="#a4a4a6"
                  />
                  <path
                    d="M16 12L12 16L8 12"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12 8V16"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="watchVideo__more">
            <div className="watchVideo__channel flex-row">
              <div className="watchVideo__channelInfo flex-row">
                <div className={`watchVideo__channelImg `}>
                  <img
                    src={
                      video?.channel?.img ||
                      "https://images.unsplash.com/photo-1554126807-6b10f6f6692a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80"
                    }
                    alt=""
                  />
                </div>
                <div>
                  <div className="flex-row">
                    <h4>{video?.channel?.name || "Abraham Benjamin"}</h4>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 26 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.45898 13L11.7292 18.2708L22 8"
                        stroke="#077aff"
                        strokeWidth="3.54167"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                  <small>1.7M Followers</small>
                </div>
              </div>
              <div className="flex-row watchVideo__channelInfoBtn">
                <button
                  className={`btn ${!follow && "primary"}`}
                  onClick={handleFollowConnection}
                >
                  {follow || connected ? (
                    "Following"
                  ) : (
                    <>
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 12H19"
                          stroke="#fff"
                          stroke-width="5"
                          stroke-linecap="round"
                        />
                        <path
                          d="M12 19V5"
                          stroke="#fff"
                          stroke-width="5"
                          stroke-linecap="round"
                        />
                      </svg>
                      Follow
                    </>
                  )}
                </button>
                <button
                  className="btn"
                  onClick={() => history.push("/credits?gift")}
                >
                  <div className="watchVideo__playedDetailsActionGift flex-row">
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.8 6.5C25 5.7 25 4.5 23.6 3.2C22.8 2.4 22 2 21.1 2C18.6 2 16.8 5.5 15.9 7.8C15 5.5 13.2 2 10.7 2C9.8 2 9 2.4 8.2 3.2C6.8 4.5 6.8 5.7 7 6.5C7.9 9.5 14.3 10.7 15.6 10.9H15.7C15.8 10.9 15.8 10.9 15.9 10.9C16 10.9 16 10.9 16.1 10.9H16.2C17.5 10.7 23.9 9.5 24.8 6.5ZM8.7 6C8.5 5.5 8.8 5 9.4 4.4C9.9 4 10.3 3.7 10.7 3.7C12.1 3.7 13.6 6.4 14.5 8.9C11.9 8.3 9 7.2 8.7 6ZM23.1 6C22.7 7.2 19.8 8.3 17.3 8.9C18.1 6.7 19.6 3.7 21.1 3.7C21.5 3.7 21.9 3.9 22.4 4.4C23 5 23.3 5.5 23.1 6Z"
                        fill="#077aff"
                      />
                      <path
                        d="M4.4 17.2H14.9V12H6.1C5.1 12 4.3 12.8 4.3 13.8V17.2H4.4Z"
                        fill="#077aff"
                      />
                      <path
                        d="M17.2 11.9999V17.1999H27.7V13.6999C27.7 12.6999 26.9 11.8999 25.9 11.8999H17.2V11.9999Z"
                        fill="#077aff"
                      />
                      <path
                        d="M5.5 30H14.8V19.3C14.8 18.8 14.4 18.3 13.8 18.3H6.5C6 18.3 5.5 18.7 5.5 19.3V30Z"
                        fill="#077aff"
                      />
                      <path
                        d="M17.2 30H26.5V19.3C26.5 18.8 26.1 18.3 25.5 18.3H18.2C17.7 18.3 17.2 18.7 17.2 19.3V30Z"
                        fill="#077aff"
                      />
                    </svg>
                    Gift
                  </div>
                </button>
              </div>
            </div>
            <div className={`watchVideo__desc ${showMore && "more"}`}>
              <p>{video?.description}</p>
            </div>
            <p
              className="watchVideo__descSeeMore"
              onClick={() => setShowMore((showMore) => !showMore)}
            >
              {showMore ? "Show Less" : "Show More"}
            </p>
          </div>
          <div className="watchVideo__comment">
            <div
              className="watchVideo__commentInputContainer flex-row"
              ref={commentBox}
            >
              <img src="/images/pictures/test.png" alt="" className="rounded" />
              {/* <span contentEditable="true" role="textbox"></span> */}
              <div className="watchVideo__commentAction flex-column">
                <textarea
                  onInput={addRowTextarea}
                  onKeyUp={deleteRowTextarea}
                  onChange={(e) => setComment(e.target.value)}
                  value={comment}
                  placeholder="Comment your thought"
                  className="watchVideo__commentInput"
                />
                {comment !== "" && (
                  <div className="flex-row">
                    <button className=" btn" onClick={() => setComment("")}>
                      Cancel
                    </button>
                    <button className="primary btn">Comment</button>
                  </div>
                )}
              </div>
            </div>
            <CommentCard />
            <CommentCard />
            <CommentCard />
            <CommentCard />
            <CommentCard />
            <CommentCard />
            <CommentCard />
            <CommentCard />
            <CommentCard />
            <CommentCard />
          </div>
        </div>
      </div>
      <div className="watchVideo__other flex-column">
        <div className="watchVideo__autoPlay flex-row">
          <p>Auto Play</p>
          <div
            className="watchVideo__toggle"
            onClick={() => setAutoPlay((autoPlay) => !autoPlay)}
          >
            <input
              type="checkbox"
              checked={autoPlay}
              onChange={() => setAutoPlay((autoPlay) => !autoPlay)}
            />
            <div className="watchVideo__toggleIcon item-center rounded"></div>
          </div>
        </div>
        {thumbnails.map(
          (thumb, i) =>
            (video?.id || video?.videoId) !== thumb.id && (
              <ContentCard
                title={thumb.title}
                key={thumb.id}
                id={thumb.id}
                img={thumb.img}
                channelName={thumb.channel.name}
                imgChannel={thumb.channel.img}
              />
            )
        )}
      </div>
    </div>
  );
};

export default WatchVideo;
