import "./NotificationsPopup.css";

const baseURLIcon =
  "https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/130f05eea10b559fbf7354adc3145a0950b3cfb8/";

const NotificationsPopup = ({ showNotifications }) => {
  return (
    <div className={`notificationsPopup ${showNotifications && "show"}`}>
      <ul>
        <li className="flex-row">
          <img src="/images/pictures/profile2.png" alt="" />
          <div className="notifications__messages">
            <h3>
              New messages <span>from Wane</span>
            </h3>
            <div className="notifications__time flex-row">
              <svg
                width="15"
                height="15"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 4C35.06 4 44 12.96 44 24C44 35.06 35.06 44 24 44C12.96 44 4 35.06 4 24C4 12.96 12.96 4 24 4ZM23.3 13.86C22.48 13.86 21.8 14.52 21.8 15.36V25.46C21.8 25.98 22.08 26.46 22.54 26.74L30.38 31.42C30.62 31.56 30.88 31.64 31.16 31.64C31.66 31.64 32.16 31.38 32.44 30.9C32.86 30.2 32.64 29.28 31.92 28.84L24.8 24.6V15.36C24.8 14.52 24.12 13.86 23.3 13.86Z"
                  fill="#757679"
                />
              </svg>
              <span>1 hours ago</span>
            </div>
          </div>
        </li>
        <li className="flex-row">
          <img src={`${baseURLIcon}spotify.svg`} alt="" />
          <div className="notifications__messages">
            <h3>
              New Album <span>by Shawn Mendes</span>
            </h3>
            <div className="notifications__time flex-row">
              <svg
                width="15"
                height="15"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 4C35.06 4 44 12.96 44 24C44 35.06 35.06 44 24 44C12.96 44 4 35.06 4 24C4 12.96 12.96 4 24 4ZM23.3 13.86C22.48 13.86 21.8 14.52 21.8 15.36V25.46C21.8 25.98 22.08 26.46 22.54 26.74L30.38 31.42C30.62 31.56 30.88 31.64 31.16 31.64C31.66 31.64 32.16 31.38 32.44 30.9C32.86 30.2 32.64 29.28 31.92 28.84L24.8 24.6V15.36C24.8 14.52 24.12 13.86 23.3 13.86Z"
                  fill="#757679"
                />
              </svg>
              <span>10 hours ago</span>
            </div>
          </div>
        </li>
        <li className="flex-row">
          <img src={`${baseURLIcon}card2.svg`} alt="" />
          <div className="notifications__messages">
            <h3>Payment successful completed</h3>
            <div className="notifications__time flex-row">
              <svg
                width="15"
                height="15"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24 4C35.06 4 44 12.96 44 24C44 35.06 35.06 44 24 44C12.96 44 4 35.06 4 24C4 12.96 12.96 4 24 4ZM23.3 13.86C22.48 13.86 21.8 14.52 21.8 15.36V25.46C21.8 25.98 22.08 26.46 22.54 26.74L30.38 31.42C30.62 31.56 30.88 31.64 31.16 31.64C31.66 31.64 32.16 31.38 32.44 30.9C32.86 30.2 32.64 29.28 31.92 28.84L24.8 24.6V15.36C24.8 14.52 24.12 13.86 23.3 13.86Z"
                  fill="#757679"
                />
              </svg>
              <span>2 days</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default NotificationsPopup;
