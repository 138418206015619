import "./AvatarIntialUser.css";

const AvatarIntialUser = ({ name, rounded }) => {
  const randomColor = () => {
    let color = "#";
    let randomHex = "123456ABCDEF";
    for (let i = 0; i < 6; i++) {
      color += randomHex[Math.floor(Math.random() * 16)];
    }

    return color;
  };
  console.log(randomColor());
  return (
    <div className={`avatarIntialUser ${rounded}`}>{name.substr(0, 2)}</div>
  );
};

export default AvatarIntialUser;
