import { useRef } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { changeTier } from "../../actions/missionAction";
import "./MissionCard.css";

const MissionCard = ({
  emblem,
  title,
  exp,
  status,
  baseURLBadges,
  clicked,
  completed,
}) => {
  const dispatch = useDispatch();
  const handleChangeTier = () => {
    if (clicked) {
      dispatch(changeTier(title));
    }
  };
  const missionProgress = useRef(null);
  const mainEmblem =
    title === "Radiant" ||
    title === "Genius" ||
    title === "Legend" ||
    title === "Conqueror";

  useEffect(() => {
    if (status === "Progress") {
      missionProgress.current.style.setProperty(
        "--mission-progress-persent",
        (title === "Genius" ||
          title === "Radiant" ||
          title === "Conqueror" ||
          title === "Legend") &&
          status === "Progress"
          ? `${(completed / 10) * 100}%`
          : `${(completed / 1000) * 100}%`
      );
    }
  }, [completed, status, title]);
  return (
    <div
      className={`missionCard flex-column ${clicked && "clicked"}`}
      onClick={handleChangeTier}
    >
      <img
        src={emblem}
        alt={title}
        className={`missionCard__emblem ${mainEmblem && "main-emblem"}`}
      />
      <h3 className="missionCard__title">{title}</h3>
      {!mainEmblem && (
        <img src={emblem} alt={title} className="missionCard__miniEmblem" />
      )}
      <span className="missionCard__desc">
        Won third place on a verfied forum competition
      </span>
      {(title === "Genius" ||
        title === "Radiant" ||
        title === "Conqueror" ||
        title === "Legend") && (
        <div className="missionCard__badges">
          <img
            src={`${baseURLBadges}badge-${title.toLowerCase()}-1.svg`}
            alt={title}
          />
          <img
            src={`${baseURLBadges}badge-${title.toLowerCase()}-2.svg`}
            alt={title}
          />
          <img
            src={`${baseURLBadges}badge-${title.toLowerCase()}-3.svg`}
            alt={title}
          />
        </div>
      )}
      <div
        className={`missionCard__progress ${
          status === "Unlocked!" || (status === "Progress" && "unlocked")
        }`}
        ref={missionProgress}
      ></div>
      <span className="missionCard__lock">
        {(title === "Genius" ||
          title === "Radiant" ||
          title === "Conqueror" ||
          title === "Legend") &&
        status === "Progress"
          ? `${completed}/10`
          : status === "Progress"
          ? `${completed}/1000`
          : status}
      </span>
      {exp && (
        <span className="missionCard__exp flex-row">
          <svg
            width="10"
            height="10"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 12H19"
              stroke="#46c5cb"
              stroke-width="7"
              stroke-linecap="round"
            />
            <path
              d="M12 19V5"
              stroke="#46c5cb"
              stroke-width="7"
              stroke-linecap="round"
            />
          </svg>
          {exp} EXP
        </span>
      )}
    </div>
  );
};

export default MissionCard;
