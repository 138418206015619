import {
  ADD_TO_CART,
  MINUS_COUNT,
  PLUS_COUNT,
  REMOVE_FROM_CART,
} from "../constants/cartConstants";

export const addToCart = (product) => async (dispatch, getState) => {
  dispatch({
    type: ADD_TO_CART,
    payload: product,
  });
  localStorage.setItem(
    "gsprklCartItems",
    JSON.stringify(getState().cart.cartItems)
  );
};

export const removeFromCart = (productId) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_FROM_CART,
    payload: productId,
  });
  localStorage.setItem(
    "gsprklCartItems",
    JSON.stringify(getState().cart.cartItems)
  );
};

export const minusCount = (productId) => (dispatch, getState) => {
  dispatch({
    type: MINUS_COUNT,
    payload: productId,
  });
  localStorage.setItem(
    "gsprklCartItems",
    JSON.stringify(getState().cart.cartItems)
  );
};
export const plusCount = (productId) => (dispatch, getState) => {
  dispatch({
    type: PLUS_COUNT,
    payload: productId,
  });
  localStorage.setItem(
    "gsprklCartItems",
    JSON.stringify(getState().cart.cartItems)
  );
};
