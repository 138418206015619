import { ADD_AND_REMOVE_CATEGORIES_COMMUNITIES, ADD_AND_REMOVE_CATEGORIES_GAME, ADD_AND_REMOVE_CATEGORIES_SEARCH, ADD_AND_REMOVE_CATEGORIES_SHOP, ADD_AND_REMOVE_CATEGORIES_VIDEOS } from "../constants/categoriesConstants";

export const addCategoriesSearch = (item, typeCategories) => (dispatch) => {
  let type;
  if (typeCategories === "search") type = ADD_AND_REMOVE_CATEGORIES_SEARCH;
  if (typeCategories === "videos") type = ADD_AND_REMOVE_CATEGORIES_VIDEOS;
  if (typeCategories === "shop") type = ADD_AND_REMOVE_CATEGORIES_SHOP;
  if (typeCategories === "communities") type = ADD_AND_REMOVE_CATEGORIES_COMMUNITIES;
  if (typeCategories === "game") type = ADD_AND_REMOVE_CATEGORIES_GAME;
  dispatch({
    type,
    payload: item,
  });
};
