import { CART_EMPTY } from "../constants/cartConstants";
import { BUY_NOW, CREATE_ORDER_SUCCESS } from "../constants/orderConstants";

export const createdOrder = (order) => async (dispatch) => {
  // later do the request when  backend ready
  dispatch({ type: CREATE_ORDER_SUCCESS, payload: order });

  // remove all the cartItems
  dispatch({ type: CART_EMPTY });
  localStorage.removeItem("gsprklCartItems");
};

export const buyNow = (item) => async (dispatch) => {
  dispatch({ type: BUY_NOW, payload: item });
};
