import Carousel, { Dots, slidesToShowPlugin } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { useState } from "react";
import "./ViewProjects.css";

const ViewProjects = ({ setOpenProjects, projects }) => {
  const [value, setValue] = useState(0);

  const handleChange = (value) => {
    setValue(JSON.parse(value));
  };

  const handleClose = (e) => {
    if (e.target !== e.currentTarget) return;
    setOpenProjects(false);
  };

  return (
    <div className="viewProjects flex-column">
      <div className="viewProjects__close" onClick={handleClose}></div>
      <Carousel
        value={value}
        onChange={handleChange}
        plugins={[
          "centered",
          "infinite",
          "arrows",
          {
            resolve: slidesToShowPlugin,
            options: {
              numberOfSlides: 2,
            },
          },
        ]}
      >
        {projects.map((project) => (
          <div
            className={`profileProjects__imgContainer video-show  ${
              project.premium && "premium-video"
            }`}
          >
            <img src={project.img} alt={project.title} />
          </div>
        ))}

        {/* <img
          src="/images/pictures/projects/profile/interior-scandinavian.jpg"
          alt=""
        />
        <img
          src="/images/pictures/projects/profile/interior-minimalist.jpg"
          alt=""
        /> */}
      </Carousel>
      <Dots value={value} onChange={handleChange} number={projects.length} />
      <div className="viewProjects__close bot" onClick={handleClose}></div>
    </div>
  );
};

export default ViewProjects;
