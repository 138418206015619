import "./ContactSupport.css";

const ContactSupport = () => {
  const handleLiveChat = () => {
    const getBody = document.getElementsByTagName("body");
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "//js-na1.hs-scripts.com/20480110.js";
    script.id = "hs-script-loader";
    script.async = true;
    script.defer = true;
    getBody[0].appendChild(script);
  };

  const handleClick = () => {
    window.HubSpotConversations.widget.remove();
  };
  return (
    <div className="contactSupport flex-column">
      <div className="contactSupport__container" onClick={handleLiveChat}>
        <div className="contactSupport__card" id="live-chat">
          <div className="contactSupport__image">
            <img
              src="https://gist.githubusercontent.com/faustabmnyh/070481fe272d83fc3cc0cd704121a794/raw/e1b074dce544075a557f91449c3cd435db510582/chat-bubble-one.svg"
              alt=""
            />
          </div>
          <h4>Livechat</h4>
          <span>
            The most easiest and effective way of getting your problem solved.
            Via livechat, you get reply ASAP.
          </span>
        </div>
        <a
          target="_blank"
          rel="noreferrer"
          onClick={handleClick}
          href="https://discord.gg/zjFYrkFJYA"
        >
          <div className="contactSupport__card">
            <div className="contactSupport__image">
              <img
                src="https://gist.githubusercontent.com/faustabmnyh/070481fe272d83fc3cc0cd704121a794/raw/e1b074dce544075a557f91449c3cd435db510582/chat-bubble-two.svg"
                alt=""
              />
            </div>
            <h4>Community</h4>
            <span>
              Through our community, you directly get help from other geniuses.
              Here you can expect some creative solutions.
            </span>
          </div>
        </a>
        <a
          target="_blank"
          onClick={handleClick}
          rel="noreferrer"
          href="https://gs-landing-page.pages.dev/contact-us"
        >
          <div className="contactSupport__card messenger">
            <div className="contactSupport__image">
              <img
                src="https://gist.githubusercontent.com/faustabmnyh/070481fe272d83fc3cc0cd704121a794/raw/e1b074dce544075a557f91449c3cd435db510582/chat-bubble-three.svg"
                alt=""
              />
            </div>
            <h4>Email</h4>
            <span>
              This is the old way of communicating yet effective. Even through
              email, you get reply from us under 10 minutes.
            </span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default ContactSupport;
