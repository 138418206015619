import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CompletedSteps from "../../components/CompletedSteps";
import "./Delivery.css";
const Delivery = () => {
  const [delivery, setDelivery] = useState("express");
  const [values, setValues] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    address: "",
    country: "",
    city: "",
    state: "",
    zipCode: "",
  });
  const history = useHistory();
  const { cartItems } = useSelector((state) => state.cart);
  const { buyItemNow } = useSelector((state) => state.orderCreate);

  const handleChange = (e) => {
    setValues({
      ...values,
      [e.target.name]:
        e.target.name === "phoneNumber" || e.target.name === "zipCode"
          ? e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1")
          : e.target.value,
    });
  };

  console.log(buyItemNow);

  useEffect(() => {
    if (cartItems.length === 0 && !buyItemNow) {
      history.push("/shop");
    }
  }, [cartItems, history, buyItemNow]);

  return (
    <div className="delivery">
      <CompletedSteps stepOne stepTwo />
      <div className="delivery__container flex-row">
        <div className="delivery__containerInformation">
          <h3>Contact Information</h3>
          <form className="delivery__form">
            <div className="delivery__profileInputSections flex-row">
              <div className="input__container flex-column">
                <input
                  type="text"
                  id="delivery-full-name"
                  value={values.fullName}
                  name="fullName"
                  onChange={handleChange}
                  required
                  placeholder="Enter your full name"
                  className="input"
                />
                <label htmlFor="delivery-full-name" className="input__label">
                  Full Name
                </label>
              </div>
              <div className="input__container flex-column">
                <input
                  type="text"
                  id="delivery-email"
                  value={values.email}
                  onChange={handleChange}
                  name="email"
                  required
                  placeholder="Enter your email"
                  className="input"
                />
                <label htmlFor="delivery-email" className="input__label">
                  Email
                </label>
              </div>
            </div>
            <div className="delivery__profileInputSections flex-row">
              <div className="input__container flex-column">
                <input
                  type="text"
                  id="delivery-phone"
                  onChange={handleChange}
                  name="phoneNumber"
                  value={values.phoneNumber}
                  required
                  placeholder="Enter your phone number"
                  className="input"
                />
                <label htmlFor="delivery-phone" className="input__label">
                  Phone Number
                </label>
              </div>

              <div className="input__container flex-column">
                <input
                  type="text"
                  id="delivery-address"
                  name="address"
                  value={values.address}
                  onChange={handleChange}
                  placeholder="Enter your address"
                  required
                  className="input"
                />
                <label htmlFor="delivery-address" className="input__label">
                  Address
                </label>
              </div>
            </div>
            <div className="delivery__profileInputSections flex-row">
              <div className="input__container flex-column">
                <input
                  type="text"
                  id="delivery-country"
                  onChange={handleChange}
                  name="country"
                  value={values.country}
                  placeholder="Enter your country"
                  required
                  className="input"
                />
                <label htmlFor="delivery-country" className="input__label">
                  Country
                </label>
              </div>
              <div className="input__container flex-column">
                <input
                  type="text"
                  id="delivery-city"
                  onChange={handleChange}
                  value={values.city}
                  name="city"
                  required
                  placeholder="Enter your city"
                  className="input"
                />
                <label htmlFor="delivery-city" className="input__label">
                  City
                </label>
              </div>
            </div>
            <div className="delivery__profileInputSections flex-row">
              <div className="input__container flex-column">
                <input
                  type="text"
                  id="delivery-state"
                  onChange={handleChange}
                  name="state"
                  value={values.state}
                  placeholder="Enter your state"
                  required
                  className="input"
                />
                <label htmlFor="delivery-state" className="input__label">
                  State
                </label>
              </div>
              <div className="input__container flex-column">
                <input
                  type="text"
                  id="delivery-zip-code"
                  name="zipCode"
                  onChange={handleChange}
                  value={values.zipCode}
                  maxLength={5}
                  placeholder="Enter your zip code"
                  required
                  className="input"
                />
                <label htmlFor="delivery-zip-code" className="input__label">
                  Zip Code
                </label>
              </div>
            </div>
          </form>
        </div>
        <div className="delivery__shippingMethod">
          <h3>Shipping Method</h3>
          <div className="delivery__radio flex-column">
            <label htmlFor="standard" className="flex-row">
              <input
                type="radio"
                className="delivery__radioInput"
                id="standard"
                onChange={() => setDelivery("standard")}
                checked={delivery === "standard"}
                name="shipping-method"
              />
              <span className="delivery__radioControl"></span>
              <div className="delivery__radioText flex-row">
                Standard (4 weeks) <strong>$9</strong>
              </div>
            </label>
            <label htmlFor="express" className="flex-row">
              <input
                type="radio"
                className="delivery__radioInput"
                id="express"
                onChange={() => setDelivery("express")}
                checked={delivery === "express"}
                name="shipping-method"
              />
              <span className="delivery__radioControl"></span>
              <div className="delivery__radioText flex-row">
                Express (5 days) <strong>$20</strong>
              </div>
            </label>
          </div>
        </div>
      </div>
      <div className="delivery__btnContainer flex-row">
        <button className="btn flex-row" onClick={() => history.push("/cart")}>
          <svg
            width="10"
            height="10"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 4L8 12L16 20"
              stroke="black"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Back to Cart
        </button>
        <button
          className="btn primary"
          onClick={() => history.push("/payment")}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Delivery;
