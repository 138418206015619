import { useEffect, useState } from "react";
import { truncate } from "../../utils/truncate";
import "./QuizCard.css";

const QuizCard = ({ content }) => {
  const [love, setLove] = useState(false);
  const [text, setText] = useState("");
  const [textCopied, setTextCopied] = useState("https://geniusparkle/marcus");
  const [checked, setChecked] = useState(true);
  const [showTeam, setShowTeam] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [inveted, setInveted] = useState([]);
  const [teams] = useState([
    { id: 1, name: "Marcus Riensma" },
    { id: 2, name: "Margariet Lapondi" },
    { id: 3, name: "Mariah Groose" },
    { id: 4, name: "Ariana Caya" },
    { id: 5, name: "Camila Grande" },
    { id: 6, name: "Georgiano" },
  ]);
  const [buttonClicked, setButtonClicked] = useState(false);
  const handleButtonClicked = (e) => {
    e.preventDefault();
    if (text !== "" || textCopied !== "") {
      setButtonClicked(true);
      setInveted([]);
      setShowTeam(false);
      if (checked) {
        setTextCopied("");
      } else {
        setText("");
      }
    }
  };

  const handleClickedInput = () => {
    if (!checked) {
      setShowTeam(true);
    }
  };

  const handleInveted = (name) => {
    setShowTeam(false);
    setText("");
    setInveted((inveted) => {
      const getName = name;
      const existTeam = inveted.find((i) => i.id === getName.id);
      if (existTeam) {
        return inveted.map((i) => (i.id === existTeam.id ? getName : i));
      } else {
        return [...inveted, getName];
      }
    });
  };

  const handleChange = (e) => {
    setText(e.target.value);
    if (!checked) {
      const items = document.querySelectorAll(
        ".quiz__inviteContainerPerson li"
      );
      const inputValue = e.target.value.toUpperCase();
      items.forEach((item, i) => {
        if (i !== 0) {
          let txtValue = item.textContent || item.innerText;
          if (txtValue.toUpperCase().indexOf(inputValue) > -1) {
            item.style.display = "";
          } else {
            item.style.display = "none";
          }
        }
      });
    }
  };

  const handleShowInvite = (e) => {
    if (e.target !== e.currentTarget) return;
    setShowInvite((showInvite) => !showInvite);
  };

  const handleRemoveTeam = (id) => {
    setInveted((inveted) => inveted.filter((i) => i.id !== id));
  };

  useEffect(() => {
    if (buttonClicked) {
      setTimeout(() => {
        setButtonClicked(false);
      }, 2000);
    }
    if (checked) {
      setShowTeam(false);
    }

    window.onclick = (e) => {
      if (e.target.closest(".quiz__inviteContainerAction") === null) {
        setShowTeam(false);
      }
    };
  }, [buttonClicked, checked, inveted]);
  return (
    <>
      <div className="quizCard">
        <a target="_blank" rel="noreferrer" href={content?.link}>
          <img src={content.img} alt="art" className="quizCard__mainImg" />
          <div>
            <h3 className="quizCard__title">{truncate(content.title, 35)}</h3>
            <p className="quizCard__desc">
              {truncate(content.description, 130)}
            </p>
          </div>
        </a>
        <div className="quizCard__bottom flex-row">
          <div className="quizCard__users flex-row">
            <img
              src="/images/pictures/test.png"
              alt=""
              className="quizCard__usersImg rounded"
            />
            <img
              src="/images/pictures/profile.png"
              alt=""
              className="quizCard__usersImg rounded"
            />
            <img
              src="https://source.unsplash.com/4FDsNcCR8iQ"
              alt=""
              className="quizCard__usersImg rounded"
            />
            <div className="quizCard__userMore rounded item-center">+32</div>
          </div>
          <div className="flex-row">
            <div
              className="quizCard__action flex-row"
              onClick={() => setLove((love) => !love)}
            >
              {love ? "35" : "34"}

              {!love ? (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M24.4694 8.07824C27.7214 6.02224 32.0414 5.45424 35.7734 6.65024C43.8914 9.26824 46.4114 18.1182 44.1574 25.1602C40.6794 36.2202 25.8254 44.4702 25.1954 44.8162C24.9714 44.9402 24.7234 45.0022 24.4754 45.0022C24.2274 45.0022 23.9814 44.9422 23.7574 44.8202C23.1314 44.4782 8.38543 36.3502 4.79143 25.1622C4.78943 25.1622 4.78943 25.1602 4.78943 25.1602C2.53342 18.1162 5.04543 9.26424 13.1554 6.65024C16.9634 5.41824 21.1134 5.96024 24.4694 8.07824ZM14.0754 9.50624C7.51343 11.6222 5.86542 18.6802 7.64742 24.2462C10.4514 32.9702 21.5294 40.0242 24.4734 41.7702C27.4274 40.0062 38.5854 32.8742 41.2994 24.2542C43.0814 18.6822 41.4274 11.6242 34.8554 9.50624C31.6714 8.48424 27.9574 9.10624 25.3934 11.0902C24.8574 11.5022 24.1134 11.5102 23.5734 11.1022C20.8574 9.06024 17.3094 8.46224 14.0754 9.50624Z"
                    fill="#C3C3C3"
                  />
                </svg>
              ) : (
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 49 49"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6826 5.29865C17.9426 5.33697 19.1626 5.55697 20.3446 5.95897H20.4626C20.5426 5.99697 20.6026 6.03897 20.6426 6.07697C21.0846 6.21897 21.5026 6.37897 21.9026 6.59897L22.6626 6.93897C22.9626 7.09897 23.3226 7.39697 23.5226 7.51897C23.7226 7.63697 23.9426 7.75897 24.1226 7.89697C26.3446 6.19897 29.0426 5.27897 31.8226 5.29865C33.0846 5.29865 34.3446 5.47697 35.5426 5.87897C42.9246 8.27897 45.5846 16.379 43.3626 23.459C42.1026 27.077 40.0426 30.379 37.3446 33.077C33.4826 36.817 29.2446 40.137 24.6826 42.997L24.1826 43.299L23.6626 42.977C19.0846 40.137 14.8226 36.817 10.9246 33.057C8.24459 30.359 6.18259 27.077 4.90259 23.459C2.64259 16.379 5.30259 8.27897 12.7646 5.83697C13.3446 5.63697 13.9426 5.49697 14.5426 5.41897H14.7826C15.3446 5.33697 15.9026 5.29865 16.4626 5.29865H16.6826Z"
                    fill="#ea3943"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
      </div>
      {showInvite && (
        <div className="quiz__invite" onClick={handleShowInvite}>
          <div className="quiz__inviteContainer">
            <h3>Invite people</h3>
            <span className="quiz__inviteContainerText">
              All invited people will be granted access to all sites within your
              organisation
            </span>
            <label className="quiz__toggle">
              <input
                type="checkbox"
                checked={checked}
                onChange={() => setChecked((checked) => !checked)}
              />
              <div></div>
              <span className="quiz__toggleName">Invite by name</span>
              <span className="quiz__toggleLink">Invite with link</span>
            </label>
            <div className="quiz__inviteContainerAction">
              <label htmlFor="add-team">Add to team</label>
              <form className="flex-row" onSubmit={handleButtonClicked}>
                <input
                  type="text"
                  value={checked ? textCopied : text}
                  onClick={handleClickedInput}
                  onChange={handleChange}
                />
                <button
                  className={`btn ${
                    (checked ? textCopied !== "" : text !== "") && "primary"
                  }`}
                >
                  {checked ? "Copy Link" : "Invite"}
                </button>
                {showTeam && (
                  <ul className="quiz__inviteContainerPerson">
                    {teams.map((team) => (
                      <li
                        className="flex-row"
                        key={team.id}
                        onClick={() => handleInveted(team)}
                      >
                        <img
                          src="/images/pictures/test.png"
                          alt=""
                          className="rounded"
                        />
                        <p>
                          {team.name} • <span>from your connections</span>
                        </p>
                      </li>
                    ))}
                  </ul>
                )}
              </form>
              {!checked && inveted.length !== 0 && (
                <ul className="quiz__invited flex-row">
                  {inveted.map((i) => (
                    <li className="flex-row" key={i.id}>
                      {i.name}
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        onClick={() => handleRemoveTeam(i.id)}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.5 9L9 1.5"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                        <path
                          d="M9 9L1.5 1.5"
                          stroke="black"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                    </li>
                  ))}
                </ul>
              )}
            </div>

            <div
              className={`quiz__inviteSuccess flex-row ${
                buttonClicked && "sent"
              }`}
            >
              {checked ? "Link copied!" : "Invitation sent!"}
              <div className="quiz__inviteSuccessIcon rounded">
                <svg
                  viewBox="0 0 13 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5 3.7807L5.35965 7.64035L11.5 1.5"
                    stroke="black"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QuizCard;
