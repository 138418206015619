import products from "../../utils/products.json";
import ProductCard from "../../components/ProductCard";
// import ShopFiltre from "../../components/ShopFiltre";
import Categories from "../../components/Categories";
import shopCategories from "../../utils/shopCategories.json";
import "./Shop.css";

const Shop = () => {
  return (
    <div className="shop">
      <Categories getCategories={shopCategories} center type="shop" />
      <div className="shop__main flex-row">
        {/* <ShopFiltre /> */}
        <div className="shop__mainContainer">
          {products.map((product) => (
            <ProductCard
              key={product.id}
              title={product.title}
              img={product.img}
              id={product.id}
              colors={product.colors}
              sizes={product.sizes}
              price={product.price}
              tags={product.tags}
              rating={product.rating}
              description={product.description}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Shop;
