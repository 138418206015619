import {
  BUY_NOW,
  CREATE_ORDER_FAIL,
  CREATE_ORDER_REQUEST,
  CREATE_ORDER_SUCCESS,
} from "../constants/orderConstants";

export const orderCreatedReducer = (
  state = { orders: [], order: {} },
  action
) => {
  switch (action.type) {
    case CREATE_ORDER_REQUEST:
      return { loading: true };
    case CREATE_ORDER_SUCCESS:
      //   for now I just store in localStorage for order history
      localStorage.setItem(
        "gsprklOrderItems",
        JSON.stringify([...state.orders, action.payload])
      );
      return {
        ...state,
        loading: false,
        success: true,
        // order: action.payload,
        orders: [...state.orders, action.payload],
      };
    case BUY_NOW:
      return {
        ...state,
        buyItemNow: action.payload,
      };
    case CREATE_ORDER_FAIL:
      return { loading: false, error: action.payload };
    //   case CREATE_ORDER_RESET:
    //     return {};
    default:
      return state;
  }
};
