import { useSelector } from "react-redux";
import QuizCard from "../../components/QuizCard";
import QuizHeader from "../../components/QuizHeader";
import QuizTeacher from "../../components/QuizTeacher";
import quizez from "../../utils/quizez.json";
import "./Quiz.css";

const Quiz = () => {
  const { mode } = useSelector((state) => state.mode);
  const teacherMode =
    mode === "teacher" || mode === "learning" || mode === "teaching";

  return (
    <>
      {teacherMode ? (
        <QuizTeacher />
      ) : (
        <div className="quiz">
          {quizez.map((quiz) => (
            <div className="quiz__container">
              <QuizHeader title={quiz.category} />
              <div className="quiz__content">
                {quiz.contents.map((content) => (
                  <QuizCard content={content} />
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Quiz;
