import { useSelector } from "react-redux";
import interested from "../../utils/interested.json";
import Categories from "../../components/Categories";
import communities from "../../utils/communities.json";
import CommunitiesCard from "../../components/CommunitiesCard";
import CommunitiesTeacher from "../../components/CommunitiesTeacher";
import "./Communities.css";

const Communities = () => {
  const { mode } = useSelector((state) => state.mode);
  const teacherMode =
    mode === "teacher" || mode === "learning" || mode === "teaching";

  return (
    <>
      {teacherMode ? (
        <CommunitiesTeacher />
      ) : (
        <div className="communities">
          <Categories
            getCategories={[
              {
                id: 0,
                default: true,
                category: "All",
                icon: "https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/426aa188217db7bb6ee29426086bd5fd92c528bf/icon-all.svg",
              },
              ...interested,
            ]}
            type="communities"
          />
          <div className="communities__container">
            {communities.map((content) => (
              <CommunitiesCard content={content} key={content.id} />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default Communities;
