import axios from "axios";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Masonry from "react-masonry-css";
import "./SearchImages.css";
const SearchImages = ({ searchName }) => {
  const [count, setCount] = useState(1);
  const [images, setImages] = useState([]);
  useEffect(() => {
    fetchImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchImages = async () => {
    setCount((count) => count + 1);
    try {
      const {
        data: { results },
      } = await axios.get(
        `https://api.unsplash.com/search/photos?page=${count}&query=${
          searchName || "random"
        }&client_id=${process.env.REACT_APP_UNSPLASH_ACCESS_KEY}`
      );
      setImages([...images, ...results]);
    } catch (err) {
      console.error(err);
      return err;
    }
  };

  return (
    <div className="searchImages">
      <InfiniteScroll
        dataLength={images.length}
        next={fetchImages}
        hasMore={true}
        style={{ overflow: "initial" }}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        loader={
          <div className="loading">
            <div className="ball one"></div>
            <div className="ball two"></div>
            <div className="ball three"></div>
          </div>
        }
      >
        <Masonry
          breakpointCols={{
            default: 4,
            1500: 3,
            // 900: 3,
            700: 2,
            500: 1,
          }}
          className="my-masonry-grid "
          columnClassName="my-masonry-grid_column"
        >
          {images?.map((image) => (
            <div className="searchImages__container" key={image.id}>
              <div className="searchImages__overlay">
                <a
                  href={image.links.download}
                  className="item-center rounded"
                  rel="noreferrer"
                  target="_blank"
                  download
                >
                  <svg
                    viewBox="0 0 14 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M13.1464 7.35355C13.4614 7.03857 13.2383 6.5 12.7929 6.5H9.5V1C9.5 0.723858 9.27614 0.5 9 0.5H5C4.72386 0.5 4.5 0.723858 4.5 1V6.5H1.20711C0.761654 6.5 0.538571 7.03857 0.853553 7.35355L6.64645 13.1464C6.84171 13.3417 7.15829 13.3417 7.35355 13.1464L13.1464 7.35355ZM1 15.5C0.447716 15.5 0 15.9477 0 16.5V16.5C0 17.0523 0.447715 17.5 1 17.5H13C13.5523 17.5 14 17.0523 14 16.5V16.5C14 15.9477 13.5523 15.5 13 15.5H1Z"
                      fill="black"
                    />
                  </svg>
                </a>
                <a
                  href={image.user.links.html}
                  rel="noreferrer"
                  target="_blank"
                  className="flex-row"
                >
                  <img
                    src={image.user.profile_image.medium}
                    alt={image.user.name}
                    className="rounded"
                  />
                  <div className="flex-column">
                    <span>{image.user.username}</span>
                    <span>{image.user.name}</span>
                  </div>
                </a>
              </div>
              <img src={image?.urls?.regular} alt={image.alt_description} />
            </div>
          ))}
        </Masonry>
      </InfiniteScroll>
    </div>
  );
};

export default SearchImages;
