import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addTopic } from "../../actions/topicActions";
import interested from "../../utils/interested.json";
import InterestedCard from "../InterestedCard";
import "./SettingTopics.css";

const SettingTopics = ({ initialSet, editSet }) => {
  const dispatch = useDispatch();
  const { topicVideos } = useSelector((state) => state.topic);
  const [isChange, setIsChange] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState(interested);
  const handleSaveSelectedTopic = () => {
    setIsChange(false);
    dispatch(addTopic(selectedTopic.length ? selectedTopic : interested));
  };

  // const handleAddAllTopic = () => {
  //   setSelectedTopic(interested);
  // };
  const handleRemoveAllTopic = () => {
    setSelectedTopic(topicVideos);
  };

  return (
    <div className="settingTopics">
      <div className="settingTopics__container">
        {interested.map((i, index) => (
          <InterestedCard
            selectAll={selectedTopic.length === interested.length}
            setSelectedTopic={setSelectedTopic}
            selectedTopic={selectedTopic}
            handleChanged={() => setIsChange(true)}
            img={i.img}
            category={i.category}
            key={index}
            id={i.id}
          />
        ))}
      </div>
      <br />
      {((!initialSet && !editSet) || (editSet && isChange)) && (
        <div className="settingTopics__btnContainer flex-row">
          <div className="flex-row">
            <button className="btn" onClick={handleRemoveAllTopic}>
              Cancel
            </button>
            <button
              //   className={
              //     topicVideos.length !== 0
              //       ? "btn primary settingTopics"
              //       : "btn secondary settingTopics"
              //   }
              className="btn primary"
              onClick={handleSaveSelectedTopic}
              //   onClick={topicVideos.length === 0 ? interestedAddAll : test2}
            >
              Save
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingTopics;
