import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// import { useSelector } from "react-redux";
import "./Track.css";

const Track = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [menu, setMenu] = useState("Choose your orders");
  const history = useHistory();
  const { orders } = useSelector((state) => state.orderCreate);

  console.log(orders);
  const chooseDropdown = (e) => {
    setMenu(e.target.value);
    setShowMenu(false);
  };

  useEffect(() => {
    if (orders.length === 0) {
      history.push("/shop");
    }

    document.onclick = (e) => {
      if (e.target.closest("#track-category") === null) {
        setShowMenu(false);
      }
    };
  }, [orders, history]);
  return (
    <div className="track flex-column">
      <h1>Track Your Ongoing Orders</h1>
      <span className="track__desc">
        Below is the current status of your selected order. To check the status
        of your other active orders, just switch between the orders from the
        dropdown menu below. If you face any problems with the orders, just
        contact our <Link to="/contact-support">customer support</Link> and
        we'll help you with that.
      </span>
      <div className="track__dropdownSearch" id="track-category">
        <div
          className={`dropdown-container flex-row ${showMenu && "expand"}`}
          onClick={() => setShowMenu((showMenu) => !showMenu)}
        >
          <div className="flex-row">
            <p>{menu}</p>
          </div>

          <svg
            width="12"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            className="track__dropdownSearchArrow"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 4L8 12L16 20"
              stroke="black"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div
          className={`dropdown-item-container flex-column ${
            showMenu && "expand"
          }`}
        >
          {orders.map((order, i) => (
            <label htmlFor="category-one" className={`flex-row `} key={i}>
              {order.length >= 1
                ? order.map((o) => o.title).join(", ")
                : order.title}
              <input
                type="radio"
                value={order.title}
                id={`orders-${order.title}-${Date.now()}`}
                onClick={chooseDropdown}
              />
            </label>
          ))}
        </div>
      </div>
      <ul>
        <li className="completed">
          <div>
            <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.8526 37.8157C14.5113 37.8157 15.8703 39.2036 15.8703 40.9181C15.8703 42.6121 14.5113 44 12.8526 44C11.1739 44 9.81499 42.6121 9.81499 40.9181C9.81499 39.2036 11.1739 37.8157 12.8526 37.8157ZM35.3351 37.8157C36.9938 37.8157 38.3528 39.2036 38.3528 40.9181C38.3528 42.6121 36.9938 44 35.3351 44C33.6564 44 32.2975 42.6121 32.2975 40.9181C32.2975 39.2036 33.6564 37.8157 35.3351 37.8157ZM3.55645 4.00024L3.76011 4.01748L8.5264 4.7502C9.20587 4.8747 9.70548 5.44415 9.76543 6.13809L10.1451 10.71C10.2051 11.3651 10.7247 11.855 11.3642 11.855H38.3532C39.5722 11.855 40.3716 12.2836 41.171 13.2225C41.9703 14.1613 42.1102 15.5084 41.9304 16.731L40.0318 30.1201C39.6721 32.6938 37.5138 34.5899 34.9758 34.5899H13.1728C10.5148 34.5899 8.31656 32.5101 8.09673 29.8159L6.25817 7.56681L3.24052 7.03614C2.44114 6.89327 1.88158 6.09727 2.02147 5.28087C2.16136 4.44609 2.94075 3.89297 3.76011 4.01748L3.55645 4.00024ZM31.7783 19.4047H26.2426C25.4032 19.4047 24.7438 20.0782 24.7438 20.9355C24.7438 21.7723 25.4032 22.4662 26.2426 22.4662H31.7783C32.6176 22.4662 33.2771 21.7723 33.2771 20.9355C33.2771 20.0782 32.6176 19.4047 31.7783 19.4047Z" />
            </svg>
          </div>
          <h3>Order Placed</h3>
          <span>You just placed the order</span>
        </li>
        <li>
          <div>
            <svg viewBox="0 0 49 49" xmlns="http://www.w3.org/2000/svg">
              <path d="M24.1644 4.91504C35.2244 4.91504 44.1644 13.875 44.1644 24.915C44.1644 35.975 35.2244 44.915 24.1644 44.915C13.1244 44.915 4.16437 35.975 4.16437 24.915C4.16437 13.875 13.1244 4.91504 24.1644 4.91504ZM23.4644 14.775C22.6444 14.775 21.9644 15.435 21.9644 16.275V26.375C21.9644 26.895 22.2444 27.375 22.7044 27.655L30.5444 32.335C30.7844 32.475 31.0444 32.555 31.3244 32.555C31.8244 32.555 32.3244 32.295 32.6044 31.815C33.0244 31.115 32.8044 30.195 32.0844 29.755L24.9644 25.515V16.275C24.9644 15.435 24.2844 14.775 23.4644 14.775Z" />
            </svg>
          </div>
          <h3>Processing</h3>
          <span>We're processing the order</span>
        </li>
        <li>
          <div>
            <svg viewBox="0 0 165 166" xmlns="http://www.w3.org/2000/svg">
              <path d="M123.892 83.0114V75.4339H162.727L143.543 51.4344C141.312 48.6433 137.933 47.0183 134.36 47.0183H100.591V119.573H112.355C113.644 127.169 120.274 132.985 128.23 132.985C136.187 132.985 142.817 127.188 144.105 119.573H153.243C159.736 119.573 165 114.309 165 107.816V83.0114H123.892ZM128.23 125.408C123.532 125.408 119.706 121.581 119.706 116.883V116.864V116.788C119.762 112.147 123.589 108.358 128.23 108.358C132.871 108.358 136.698 112.147 136.755 116.788V116.883C136.755 121.581 132.928 125.408 128.23 125.408Z" />
              <path d="M86.1561 33H7.42595C3.3341 33 0 36.3341 0 40.4259V112.166C0 116.258 3.3341 119.592 7.42595 119.592H24.4943C25.7824 127.188 32.4127 133.004 40.3691 133.004C48.3255 133.004 54.9558 127.207 56.244 119.592H93.0138V39.8576C93.0138 36.0878 89.9449 33 86.1561 33ZM40.3691 125.408C35.6711 125.408 31.8444 121.581 31.8444 116.883V116.864V116.788C31.9013 112.147 35.7279 108.358 40.3691 108.358C45.0103 108.358 48.837 112.147 48.8938 116.788V116.883C48.8938 121.581 45.0672 125.408 40.3691 125.408Z" />
            </svg>
          </div>
          <h3>Delivery</h3>
          <span>Delivery is on the way</span>
        </li>
        <li>
          <div>
            <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M26 62.0109L52.4946 88.5054L101 40"
                strokeWidth="16.3043"
                strokeLinecap="round"
              />
            </svg>
          </div>
          <h3>Complete</h3>
          <span>The order is complete!</span>
        </li>
      </ul>
    </div>
  );
};

export default Track;
