import { SWITCH_MODE } from "../constants/modeConstants";

export const modeReducer = (state = "student", action) => {
  switch (action.type) {
    case SWITCH_MODE:
      return {
        mode: action.payload,
      };
    default:
      return state;
  }
};
