import { useState } from "react";
import { useSelector } from "react-redux";
import TeacherCreateCard from "../TeacherCreateCard";
import TeacherTableCreate from "../TeacherTableCreate";
import "./CommunitiesTeacher.css";

const CommunitiesTeacher = () => {
  const [createCommunity, setCreateCommunity] = useState(false);
  const [selectedCommunity, setSelectedCommunity] = useState();
  const { communities } = useSelector((state) => state.community);

  const handleSelectedCommunity = (community) => {
    setCreateCommunity(true);
    setSelectedCommunity(community);
  };

  const handleCreateNewCommunity = () => {
    setCreateCommunity(true);
    setSelectedCommunity({
      name: "",
      destinationLink: "",
      description: "",
      topic: "Select community topic",
    });
  };

  const handleManageAccess = () => {
    setCreateCommunity(false);
    setSelectedCommunity({
      name: "",
      destinationLink: "",
      description: "",
      topic: "Select community topic",
    });
  };

  return (
    <div>
      {communities.length !== 0 && createCommunity && (
        <button className="btn quizTeacher__btn" onClick={handleManageAccess}>
          Manage Access
        </button>
      )}
      {communities.length === 0 || createCommunity ? (
        <TeacherCreateCard
          type="Community"
          setCreateCard={() => setCreateCommunity(false)}
          selectedRow={selectedCommunity}
        />
      ) : (
        <TeacherTableCreate
          type="Community"
          tableDatas={communities}
          handleCreateNewData={handleCreateNewCommunity}
          handleSelectedRow={handleSelectedCommunity}
        />
      )}
    </div>
  );
};

export default CommunitiesTeacher;
