import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  minusCount,
  plusCount,
  removeFromCart,
} from "../../actions/cartActions";
import CompletedSteps from "../../components/CompletedSteps";
import "./Cart.css";

const Cart = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [promoCode, setPromoCode] = useState("");
  const [pricePromo, setPricePromo] = useState(0);
  const [usePromo, setUsePromo] = useState(false);
  const { cartItems } = useSelector((state) => state.cart);

  // real price
  const price = cartItems
    .reduce((a, q) => a + q.price * q.qty - pricePromo, 0)
    .toFixed(2);
  const totalPriceDollar = price.toString().split(".")[0];
  const totalPriceCent = price.toString().split(".")[1];

  // promo
  const promo = pricePromo.toFixed(2);
  const promoDollar = promo.toString().split(".")[0];
  const promoCent = promo.toString().split(".")[1];

  const handleCheckout = () => {
    if (cartItems.length !== 0) {
      history.push("/delivery");
    }
  };

  const handlePromoCode = () => {
    if (promoCode === "T3STPR0M0") {
      setPricePromo(30);
      setUsePromo(false);
    } else if (promoCode === "IMBROKE") {
      setPricePromo(price * (90 / 100));
      setUsePromo(false);
    } else if (promoCode === "GETPR0M0") {
      setPricePromo(price * (47 / 100));
      setUsePromo(false);
    } else {
      setPromoCode("Not available!");
    }
  };

  return (
    <div className="cart">
      <CompletedSteps stepOne />
      <table>
        <thead>
          <tr>
            <th>Items</th>
            <th>Price</th>
            <th>Quantity</th>
            <th>Total Accessed</th>
          </tr>
        </thead>
        <tbody>
          {cartItems.map((item) => (
            <tr className="cart__container" key={item.id}>
              <td className="cart__item flex-row">
                <img src={item.img} alt={item.title} />
                <div className="cart__itemTitle">
                  <h3>{item.title}</h3>
                  <div className="cart__ColorsContainer flex-row">
                    <p>Color</p>
                    <span
                      className={`bg-${item.colors} cart__ColorsColor rounded`}
                    ></span>
                  </div>
                  <strong>${item.price}</strong>
                </div>
              </td>
              <td className="cart__price">
                <strong>${item.price}</strong>
              </td>
              <td>
                <div className="cart__detailsQty flex-row">
                  <div className="cart__detailsQtyCountContainer flex-row">
                    <span
                      className="cart__detailsQtyMinus"
                      onClick={() => dispatch(minusCount(item.id))}
                    >
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 12H19"
                          stroke="#757679"
                          strokeWidth="7"
                          strokeLinecap="round"
                        />
                      </svg>
                    </span>
                    <p className="cart__detailsQtyCount">{item.qty}</p>
                    <span
                      className="cart__detailsQtyPlus"
                      onClick={() => dispatch(plusCount(item.id))}
                    >
                      <svg
                        width="10"
                        height="10"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5 12H19"
                          stroke="#757679"
                          strokeWidth="7"
                          strokeLinecap="round"
                        />
                        <path
                          d="M12 19V5"
                          stroke="#757679"
                          strokeWidth="7"
                          strokeLinecap="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
              </td>
              <td className="cart__priceTotal">
                <div className="flex-row">
                  <strong>${(item.price * item.qty).toFixed(2)}</strong>
                  <div
                    className="cart__removeItem flex-row"
                    onClick={() => dispatch(removeFromCart(item.id))}
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 49 49"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M39.696 18.2241C40.522 18.2921 41.138 19.0141 41.072 19.8401C41.06 19.9761 39.976 33.3981 39.352 39.0281C38.964 42.5221 36.656 44.6481 33.172 44.7121C30.506 44.7581 27.934 44.7841 25.42 44.7841C22.71 44.7841 20.068 44.7541 17.454 44.7001C14.11 44.6341 11.796 42.4661 11.418 39.0421C10.788 33.3621 9.70996 19.9741 9.69996 19.8401C9.63196 19.0141 10.248 18.2901 11.074 18.2241C11.888 18.2021 12.624 18.7741 12.69 19.5981C12.6963 19.6849 13.137 25.1521 13.6173 30.5615L13.7137 31.641C13.9556 34.3297 14.2008 36.9133 14.4 38.7121C14.614 40.6581 15.664 41.6621 17.516 41.7001C22.516 41.8061 27.618 41.8121 33.118 41.7121C35.086 41.6741 36.15 40.6901 36.37 38.6981C36.99 33.1101 38.07 19.7341 38.082 19.5981C38.148 18.7741 38.878 18.1981 39.696 18.2241ZM29.6176 4.78467C31.4536 4.78467 33.0676 6.02267 33.5416 7.79667L34.0496 10.3187C34.2137 11.1454 34.9392 11.7492 35.7793 11.7624L42.3428 11.7627C43.1708 11.7627 43.8428 12.4347 43.8428 13.2627C43.8428 14.0907 43.1708 14.7627 42.3428 14.7627L35.8379 14.7624C35.8278 14.7626 35.8177 14.7627 35.8076 14.7627L35.7588 14.7607L15.01 14.7624C14.9939 14.7626 14.9777 14.7627 14.9616 14.7627L14.9308 14.7607L8.42676 14.7627C7.59876 14.7627 6.92676 14.0907 6.92676 13.2627C6.92676 12.4347 7.59876 11.7627 8.42676 11.7627L14.9888 11.7607L15.1908 11.7479C15.9434 11.6502 16.5688 11.0787 16.7216 10.3187L17.2076 7.88667C17.7016 6.02267 19.3156 4.78467 21.1516 4.78467H29.6176ZM29.6176 7.78467H21.1516C20.6716 7.78467 20.2496 8.10667 20.1276 8.56867L19.6616 10.9087C19.6023 11.2051 19.5161 11.4907 19.4057 11.7632H31.364C31.2535 11.4907 31.167 11.2051 31.1076 10.9087L30.6216 8.47667C30.5196 8.10667 30.0976 7.78467 29.6176 7.78467Z"
                        fill="#ea3943"
                      />
                    </svg>
                    <small>Remove</small>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="cart__subtotal flex-row">
        <div
          className={`cart__promoCode ${
            usePromo && "promo-input"
          } btn flex-row`}
        >
          {usePromo && pricePromo === 0 ? (
            <>
              <input
                type="text"
                placeholder="Enter your coupon"
                value={promoCode}
                onClick={() =>
                  promoCode === "Not available!" && setPromoCode("")
                }
                onChange={(e) => setPromoCode(e.target.value)}
                className={`${promoCode === "Not available!" && "promo-err"}`}
              />
              <button
                className={`btn ${promoCode.trim() !== "" && "primary"}`}
                onClick={handlePromoCode}
              >
                Enter
              </button>
            </>
          ) : (
            <div className="flex-row" onClick={() => setUsePromo(true)}>
              <p>Promo Code</p>
              <svg
                viewBox="0 0 14 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2L12 12L2 22"
                  stroke="black"
                  strokeWidth="4"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
        <div className="flex-column cart__discountTotal">
          <p>Discount</p>
          <strong className="flex-row">
            ${promoDollar}
            <span>{promoCent}</span>
          </strong>
        </div>
        <div className="flex-column cart__allPriceTotal">
          <p>Total</p>
          <strong className="flex-row">
            ${totalPriceDollar}
            <span>{totalPriceCent}</span>
          </strong>
        </div>
        <button className="btn primary" onClick={handleCheckout}>
          Checkout
        </button>
      </div>
    </div>
  );
};

export default Cart;
