import axios from "axios";
import { useState } from "react";
import ContentCard from "../ContentCard";
import "./VideosTeacher.css";

const VideosTeacher = () => {
  const [selectMethod, setSelectMethod] = useState("");
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState([]);

  const handleConnectChannel = async () => {
    const { token } = JSON.parse(localStorage.getItem("gsprklUserInfo"));
    try {
      setLoading(true);
      setSelectMethod("connect channel");
      const { data } = await axios.get(
        `https://genius-park-stag.herokuapp.com/api/videos/all?access_token=${token}`
      );
      setVideos(data.items);
      setLoading(false);

      console.log(data);
    } catch (err) {
      setLoading(false);
      console.error(err.response);
      return err;
    }
  };

  return (
    <div
      className={`videosTeacher ${videos.length && "list-video"} flex-column`}
    >
      {loading && (
        <div className="loading">
          <div className="ball one"></div>
          <div className="ball two"></div>
          <div className="ball three"></div>
        </div>
      )}
      {!videos.length && !selectMethod && (
        <div
          className="videosTeacher__container"
          onClick={handleConnectChannel}
        >
          <div className="videosTeacher__card" id="live-chat">
            <div className="videosTeacher__image">
              <img
                src="https://gist.githubusercontent.com/faustabmnyh/070481fe272d83fc3cc0cd704121a794/raw/e1b074dce544075a557f91449c3cd435db510582/connect-channel.svg"
                alt=""
              />
            </div>
            <h4>Connect Channel</h4>
            <span>
              The most easiest and hassle free way of importing videos where you
              just connect your youtube channel and import videos in one click.
            </span>
          </div>

          <div
            className="videosTeacher__card"
            onClick={() => setSelectMethod("channel id")}
          >
            <div className="videosTeacher__image">
              <img
                src="https://gist.githubusercontent.com/faustabmnyh/070481fe272d83fc3cc0cd704121a794/raw/e1b074dce544075a557f91449c3cd435db510582/channel-id.svg"
                alt=""
              />
            </div>
            <h4>Via Channel ID</h4>
            <span>
              If you use this method, you'll later need to contact our customer
              support and manually verify that you own the channel before
              publishing the video.
            </span>
          </div>
        </div>
      )}
      {selectMethod === "channel id" && (
        <>
          <img
            src="https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/130f05eea10b559fbf7354adc3145a0950b3cfb8/yt.svg"
            alt=""
          />
          <h1>Import Videos From Your YT Channel</h1>
          <p>
            We make things easy for you. You can just enter your youTube channel
            ID below and we’ll fetch your youTube videos automatically. Note:
            Only import videos if you own the channel.
          </p>
          <form className="flex-row">
            <input type="text" placeholder="Enter Channel ID" />
            <button className="btn primary">Import Videos</button>
          </form>
        </>
      )}
      {selectMethod === "connect channel" &&
        !loading &&
        (videos.length ? (
          <div
            className={`videosTeacher__videContainer ${
              videos.length < 4 && "less-content"
            }`}
          >
            {videos.map((video) => (
              <ContentCard
                title={video.title}
                key={video.id ? video.id : video.videoId}
                id={video.id ? video.id : video.videoId}
                img={video.img ? video.img : video.thumbnail}
                premium={video.premium || false}
                publishedAt={video.videoPublishedAt}
                channelName={
                  video?.channel?.name
                    ? video?.channel?.name
                    : "Abraham Benjamin"
                }
                imgChannel={
                  video?.channel?.img
                    ? video?.channel?.img
                    : "https://images.unsplash.com/photo-1554126807-6b10f6f6692a?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=870&q=80"
                }
              />
            ))}
          </div>
        ) : (
          <>
            <img
              src="https://gist.githubusercontent.com/faustabmnyh/070481fe272d83fc3cc0cd704121a794/raw/57206938ceff043b93641ae552f23d3e1bcb950f/404.svg"
              alt="404 error"
              className="videosTeacher__imgError"
            />
            <h1>Ooooppppss!! You don't have video yet.</h1>
            <button className="btn primary">Import Video</button>
          </>
        ))}
    </div>
  );
};

export default VideosTeacher;
