import { CHANGE_TIER } from "../constants/missionConstants";

export const missionReducer = (state = { tier: "Radiant" }, action) => {
  switch (action.type) {
    case CHANGE_TIER:
      return {
        ...state,
        tier: action.payload,
      };
    default:
      return state;
  }
};
