import { useDispatch } from "react-redux";
import { removeComunity } from "../../actions/communityActions";
import { removeQuiz } from "../../actions/quizActions";
import { truncate } from "../../utils/truncate";
import "./TeacherTableCreate.css";

const TeacherTableCreate = ({
  tableDatas,
  type,
  handleSelectedRow,
  handleCreateNewData,
}) => {
  const dispatch = useDispatch();
  const handleRemoveData = (id) => {
    if (type === "Quiz") {
      dispatch(removeQuiz(id));
    } else {
      dispatch(removeComunity(id));
    }
  };
  return (
    <div className="teacherTableCreate__table">
      <div className="flex-row">
        <h3>My {type === "Quiz" ? "Quizzes" : "Community"}</h3>
        <button className="btn quizTeacher__btn" onClick={handleCreateNewData}>
          Create A {type}
        </button>
      </div>
      <div className="teacherTableCreate__tableContainer">
        <table>
          <thead>
            <tr>
              <th>{type} Name</th>
              <th>{type} Topic</th>
              <th>{type} Status</th>
              <th>Created</th>
              <th>Delete {type}</th>
            </tr>
          </thead>
          <tbody>
            {tableDatas.map((tdata) => (
              <tr key={tdata.id} onClick={() => handleSelectedRow(tdata)}>
                <td className="teacherTableCreate__tableName flex-row">
                  <div>
                    <h4>{tdata.name}</h4>
                    <span>{truncate(tdata.description, 15)}</span>
                  </div>
                </td>
                <td className="teacherTableCreate__tableType">{tdata.topic}</td>
                <td className={`teacherTableCreate__status ${tdata.status}`}>
                  <span>{tdata.status}</span>
                </td>
                <td>{tdata.created}</td>
                <td
                  className="teacherTableCreate__delete"
                  onClick={() => handleRemoveData(tdata.id)}
                >
                  Delete
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TeacherTableCreate;
