import "./UpgradePremiumPopup.css";

const UpgradePremiumPopup = ({ closePopup }) => {
  return (
    <div className="upgradePremiumPopup">
      <div className="upgradePremiumPopup__container flex-row">
        <img
          src="https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/130f05eea10b559fbf7354adc3145a0950b3cfb8/geniusparkle.svg"
          alt="geniusparkle"
        />
        <div>
          <h3>Stay one step ahead!</h3>
          <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Aliquam
            quod nostrum reprehenderit nam distinctio illo non harum molestias
            debitis quos in, illum, sunt ad doloribus ex dicta odit. Quidem,
            quasi.
          </p>
          <button className="btn primary">Upgrade to Premium</button>
          <svg
            className="upgradePremiumPopup__close"
            viewBox="0 0 58 59"
            onClick={closePopup}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 5.5L53 53.5"
              stroke-width="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M5 53.5L53 5.5"
              stroke-width="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default UpgradePremiumPopup;
