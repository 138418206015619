import { useState } from "react";
import { useHistory } from "react-router";
import availableTasks from "../../utils/availableTasks.json";
import "./EarnMoney.css";

const EarnMoney = () => {
  const [cardNumber, setCardNumber] = useState("1029 0293 8857 9722");
  const [closeOffer, setCloseOffer] = useState(false);
  const [cardExp, setCardExp] = useState("08 / 2049");
  const [name, setName] = useState("Abraham Benjamin");
  const history = useHistory();

  const handleChangeCardNumber = (e) => {
    let value = e.target.value.replace(/\s+/g, "").replace(/[^0-9]/g, "");
    let matches = value.match(/\d{4,16}/g);

    let match = (matches && matches[0]) || "";
    let parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      setCardNumber(parts.join(" "));
    } else {
      setCardNumber(value);
    }
  };

  const handleCardExp = (e) => {
    setCardExp(
      e.target.value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*?)\..*/g, "$1")
        .replace(/\//g, "")
        .substring(0, 2) +
        (e.target.value.length > 2 ? " / " : "") +
        e.target.value.replace(/\//g, "").substring(4, 8)
    );
  };

  return (
    <div className="earnMoney">
      {!closeOffer && (
        <div className="earnMoney__offer">
          <img
            src="https://images.unsplash.com/photo-1518458028785-8fbcd101ebb9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80"
            alt="money"
          />
          <div className="earnMoney__offerText">
            <h3>No Money? Or Don't Wanna Spend Money? No Worries!</h3>
            <p>
              We have a plan for everyone. You can earn credits by completing
              different tasks below that you can redeem for anything you want.
            </p>
          </div>
          <button className="btn primary" onClick={() => setCloseOffer(true)}>
            Got it! That’s Great!
          </button>
        </div>
      )}

      <div className="earnMoney__options flex-row">
        <div className="earnMoney__card flex-column">
          <div className="earnMoney__cardHeader flex-row">
            <strong>100.00</strong>
            <h3>Credits</h3>
          </div>
          <div className="earnMoney__cardInput flex-column">
            <label htmlFor="earn-money-card-number">Card Number</label>
            <input
              placeholder="XXXX XXXX XXXX XXXX"
              value={cardNumber}
              id="earn-money-card-number"
              onChange={handleChangeCardNumber}
            />
          </div>
          <div className="earnMoney__cardDetails flex-row">
            <div className="flex-column">
              <label htmlFor="earn-money-card-name">Name</label>
              <input
                type="your name"
                value={name}
                placeholder="Enter your name"
                id="earn-money-card-name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="flex-column">
              <label htmlFor="earn-money-card-expiration">Expiration</label>
              <input
                type="text"
                id="card-number-exp"
                placeholder="MM / YY"
                value={cardExp}
                onInput={handleCardExp}
                required
              />
            </div>
          </div>
        </div>
        <div
          className="earnMoney__cardPage flex-column"
          onClick={() => history.push("/credits")}
        >
          <img src="/images/icons/credit.svg" alt="" />
          <h3>Buy Credits</h3>
        </div>
        <div className="earnMoney__cardPage flex-column">
          <img src="/images/icons/donate.svg" alt="" />
          <h3>Donate</h3>
        </div>
        <div className="earnMoney__cardPage flex-column">
          <img src="/images/icons/redeem.svg" alt="" />
          <h3>Redeem</h3>
        </div>
      </div>

      <div className="earnMoney__tasksContainer">
        <h2>Available Tasks</h2>
        <ul className="earnMoney__tasks">
          {availableTasks.map((task) => (
            <li key={task.id} className="flex-row earnMoney__task">
              <div
                className={`flex-column  earnMoney__taskScore ${
                  task.score > 90
                    ? "A"
                    : task.score > 75
                    ? "B"
                    : task.score > 60
                    ? "C"
                    : task.score > 40
                    ? "D"
                    : "E"
                }`}
              >
                <span>
                  {task.score > 90
                    ? "A"
                    : task.score > 75
                    ? "B"
                    : task.score > 60
                    ? "C"
                    : task.score > 40
                    ? "D"
                    : "E"}
                </span>
                <span>{task.score}%</span>
              </div>
              <div>
                <h3 className="earnMoney__taskTitle">{task.title}</h3>
                <p className="earnMoney__taskDesc">{task.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EarnMoney;
