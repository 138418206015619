import { useEffect } from "react";
import "./Report.css";
const Report = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: process.env.REACT_APP_HUBSPOT_PORTAL_ID,
          formId: process.env.REACT_APP_HUBSPOT_FORM_ID,
          target: "#hubspotform",
        });
      }
    };
  }, []);
  return (
    <div className="report">
      <h1>Report User</h1>
      <div id="hubspotform"></div>
    </div>
  );
};

export default Report;
