// import { truncate } from "../../utils/truncate";
import Rating from "../../Rating";
import { useHistory } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { addToCart } from "../../actions/cartActions";
import "./ProductCard.css";

const ProductCard = ({
  title,
  id,
  tags,
  description,
  img,
  sizes,
  rating,
  colors,
  price,
}) => {
  const history = useHistory();
  // const dispatch = useDispatch();
  // const addProduct = () => {
  //   dispatch(
  //     addToCart({
  //       qty: 1,
  //       title,
  //       id,
  //       description,
  //       img,
  //       sizes: sizes && sizes[0],
  //       colors: colors && colors[0],
  //       price,
  //     })
  //   );
  // };
  return (
    <div className="productCard ">
      <div
        className="productCard__img"
        onClick={() => history.push(`/shop/details/${id}`)}
      >
        <img src={img} alt={title} />
      </div>
      <div className="productCard__body">
        <div className="productCard__bodyDetails">
          <div className="flex-row">
            <h3
              className="productCard__bodyTitle"
              onClick={() => history.push(`/shop/details/${id}`)}
            >
              {title}
            </h3>
            <Rating number rating={rating} />
          </div>
          <span>{tags[0]}</span>
          {/* <p className="productCard__bodyDesc">{truncate(description, 35)}</p> */}
        </div>
        <div className="productCard__bodyBot flex-row">
          {colors && (
            <div>
              <ul className="flex-row">
                {colors.map((clr) => (
                  <li
                    className={`bg-${clr} productCard__ColorsColor rounded`}
                  ></li>
                ))}
              </ul>
            </div>
          )}
          <div className="productCard__bodyBotPrice flex-row">
            {/* <small>$48.56</small> */}
            <strong>${price}</strong>
          </div>
          {/* <div className="productCard__btnContainer">
            <button className="btn primary" onClick={addProduct}>
              Buy Now
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
