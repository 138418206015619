import { OPEN_SETTING } from "../constants/settingConstants";

export const settingReducer = (state = { isSettingOpen: false }, action) => {
  switch (action.type) {
    case OPEN_SETTING:
      return {
        isSettingOpen: action.payload,
      };
    default:
      return state;
  }
};
