import { useState } from "react";
import badges from "../../utils/badges.json";
import "./Badges.css";

const Badges = () => {
  const [showDetails, setShowDetails] = useState({});
  const allBadges = badges.slice(0, 10);
  return (
    <div className="badges">
      <div className="badges__header flex-row">
        <h3>Badges Completed</h3>
        <img
          src="https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/522c18de04bb1a19ee281781c539a75318308c5d/tick.svg"
          alt="tick"
        />
      </div>
      <div className="badges__container">
        {allBadges.map((badge) => (
          <div
            key={badge.id}
            className="badges__badge flex-column"
            onClick={() => setShowDetails(badge)}
          >
            <img src={badge.img} alt={badge.name} />
            {/* <img
              src="https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/522c18de04bb1a19ee281781c539a75318308c5d/tick.svg"
              alt="tick"
            /> */}
          </div>
        ))}
        <div
          className={`badge__details flex-column ${
            Object.keys(showDetails).length && "show"
          }`}
        >
          <div className="badge__detailsContainer flex-column">
            <i className="cross-mark" onClick={() => setShowDetails({})}></i>
            <img src={showDetails.img} alt={showDetails.name} />
            <span className="badge__detailsBadgeText">Badge</span>
            <h4>{showDetails.name}</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quos
              molestiae quas reprehenderit officia libero saepe quis. Nesciunt a
              eligendi rerum non impedit distinctio libero hic, culpa labore in
              sit sapiente.
            </p>
            <div className="flex-row">
              <span>20x</span>
              <span>Dirty place found + cleaning</span>
            </div>
          </div>
        </div>
        {/* <div className="badges__more">+{badges.length - allBadges.length}</div> */}
      </div>
    </div>
  );
};

export default Badges;
