import "./Overview.css";
import interested from "../../utils/interested.json";
import Badges from "../Badges";
import Categories from "../Categories";
import OverviewSlider from "../OverviewSlider";

const Overview = ({ setTab }) => {
  // const [categories] = useState([
  //   {
  //     id: 1,
  //     category: "Adventure",
  //     icon: "https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/0c556a08d11ebb7c5d5f32dc1338d7cf50739d92/icon-adventure.svg",
  //   },
  //   {
  //     id: 2,
  //     category: "Puzzle",
  //     icon: "https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/a69709cf7ba3b2b9db83ed597775e16a2d62818b/icon-puzzle.svg",
  //   },
  //   {
  //     id: 3,
  //     category: "Arcade",
  //     icon: "https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/a69709cf7ba3b2b9db83ed597775e16a2d62818b/icon-arcade.svg",
  //   },
  //   {
  //     id: 4,
  //     category: "AR Games",
  //     icon: "https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/a69709cf7ba3b2b9db83ed597775e16a2d62818b/icon-ar.svg",
  //   },
  //   {
  //     id: 5,
  //     category: "Strategy",
  //     icon: "https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/0c556a08d11ebb7c5d5f32dc1338d7cf50739d92/icon-strategy.svg",
  //   },
  // ]);
  return (
    <div className="overview">
      <Badges />
      <OverviewSlider />
      <Categories getCategories={interested} type="videos" relative />
      <div className="overview__hiring">
        <div>
          <span>Hiring the right candidate is easy</span>
          <h2>
            Write your own hiring success story and publish your job ad with
            GeniuSparkle.
          </h2>
          <div className="overview__hiringBtnContainer flex-row">
            <button className="btn">Use GeniuSparkle free</button>
            <button className="btn">Schedule a demo</button>
          </div>
        </div>
        <img src="/images/pictures/img1.png" alt="img" />
      </div>
      {/* <GamesFeed /> */}
    </div>
  );
};

export default Overview;
