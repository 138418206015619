import "./CompletedSteps.css";

const CompletedSteps = ({ stepOne, stepTwo, stepThree, stepFour }) => {
  return (
    <div className="completedSteps">
      <div className="completedSteps__content ">
        <div className={`completedSteps__rounded one ${stepOne && "active"}`}>
          1
        </div>
        <div className={`completedSteps__line ${stepTwo && "active"}`}></div>
      </div>
      <div className="completedSteps__content">
        <div className={`completedSteps__rounded two ${stepTwo && "active"}`}>
          2
        </div>
        <div className={`completedSteps__line ${stepThree && "active"}`}></div>
      </div>
      <div className="completedSteps__content">
        <div
          className={`completedSteps__rounded three ${stepThree && "active"}`}
        >
          3
        </div>
        <div className={`completedSteps__line ${stepFour && "active"}`}></div>
      </div>
      <div className="completedSteps__content">
        <div className={`completedSteps__rounded four ${stepFour && "active"}`}>
          4
        </div>
      </div>
    </div>
  );
};

export default CompletedSteps;
