import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { createdOrder } from "../../actions/orderActions";
import CompletedSteps from "../../components/CompletedSteps";
import "./PaymentMethod.css";

const baseURLIcon =
  "https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/a38491f5da5bf81474551e5a2e0e3be067acc665/";
const PaymentMethod = () => {
  const [paymentMethod, setPaymentMethod] = useState(
    "Choose your payment method"
  );
  const { cartItems } = useSelector((state) => state.cart);
  const { buyItemNow } = useSelector((state) => state.orderCreate);
  const [cardExp, setCardExp] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [card, setCard] = useState("all");
  const dispatch = useDispatch();
  const history = useHistory();

  const handlePayment = () => {
    if (paymentMethod !== "Choose your payment method") {
      history.push("/successful-order");
      dispatch(
        createdOrder(
          buyItemNow
            ? buyItemNow
            : cartItems.length > 1
            ? cartItems
            : cartItems[0]
        )
      );
    }
  };

  const handleCardExp = (e) => {
    setCardExp(
      e.target.value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*?)\..*/g, "$1")
        .replace(/\//g, "")
        .substring(0, 2) +
        (e.target.value.length > 2 ? "/" : "") +
        e.target.value.replace(/\//g, "").substring(2, 4)
    );
  };

  const handleInputCardNumber = (e) => {
    let value = e.target.value.replace(/\s+/g, "").replace(/[^0-9]/g, "");
    let matches;
    if (value[0] === "4") {
      setCard("visa");
      matches = value.match(/\d{4,16}/g);
    } else if (value[0] === "3") {
      setCard("american-express");
      matches = value.match(/\d{4,15}/g);
    } else if (value[0] === "5") {
      setCard("mastercards");
      matches = value.match(/\d{4,16}/g);
    } else if (value[0] === "6") {
      setCard("discovercard");
      matches = value.match(/\d{4,16}/g);
    } else {
      matches = value.match(/\d{4,16}/g);
      setCard("all");
    }
    let match = (matches && matches[0]) || "";
    let parts = [];

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

    if (parts.length) {
      setCardNumber(parts.join(" "));
    } else {
      setCardNumber(value);
    }
  };

  useEffect(() => {
    let getURL = history.location.search
      .substr(1)
      .split("&")
      .reduce((initial, item) => {
        const parts = item.split("=");
        initial[parts[0]] = parts[1];
        return initial;
      }, {});

    localStorage.setItem("gsprklUserInfo", JSON.stringify(getURL));

    if (cartItems.length === 0 && !buyItemNow && !getURL.plan) {
      history.push("/shop");
    }
  }, [cartItems, history, buyItemNow]);

  return (
    <div className="paymentMethod">
      <CompletedSteps stepOne stepTwo stepThree />
      <ul className="paymentMethod__items">
        <li
          className={`paymentMethod__item item-center ${
            paymentMethod === "credit-" && "active"
          }`}
          onClick={() => setPaymentMethod("credit-")}
        >
          <img src={`${baseURLIcon}card.svg`} alt="" />
        </li>
        <li
          className={`paymentMethod__item item-center ${
            paymentMethod === "paypal" && "active"
          }`}
          onClick={() => setPaymentMethod("paypal")}
        >
          <img src={`${baseURLIcon}paypal.svg`} alt="" />
        </li>
        <li
          className={`paymentMethod__item item-center ${
            paymentMethod === "credits" && "active"
          }`}
          onClick={() => setPaymentMethod("credits")}
        >
          <img src="/images/icons/credit.svg" alt="" />
        </li>
      </ul>

      <div>
        {paymentMethod === "credit" && (
          <div className="paymentMethod__data">
            <h3>Credit/Debit Card</h3>
            <form>
              <div className="paymentMethod__profileInputSections flex-row">
                <div className="input__container flex-column number">
                  <div className="flex-column paymentMethod__inputCardNumber">
                    <div>
                      <div className="paymentMethod__cardNumberSection">
                        <input
                          type="text"
                          id="card-number"
                          value={cardNumber}
                          onChange={handleInputCardNumber}
                          className="input"
                          placeholder="1234 5678 9012 3456"
                          required
                        />
                        <label htmlFor="card-number" className="input__label">
                          Card Number
                        </label>
                        <div className="flex-row">
                          <img
                            src={`${baseURLIcon}visa.svg`}
                            alt=""
                            className={`paymentMethod__inputContainerCard ${
                              (card === "visa" || card === "all") && "show"
                            }`}
                          />
                          <img
                            src={`${baseURLIcon}mastercards.svg`}
                            alt=""
                            className={`paymentMethod__inputContainerCard ${
                              (card === "mastercards" || card === "all") &&
                              "show"
                            }`}
                          />
                          <img
                            src={`${baseURLIcon}american-express.svg`}
                            alt=""
                            className={`paymentMethod__inputContainerCard ${
                              (card === "american-express" || card === "all") &&
                              "show"
                            }`}
                          />
                          <img
                            src={`${baseURLIcon}discovers.svg`}
                            alt=""
                            className={`paymentMethod__inputContainerCard ${
                              (card === "discovercard" || card === "all") &&
                              "show"
                            }`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="flex-row">
                      <div className="input__container">
                        <input
                          type="text"
                          id="card-number-exp"
                          placeholder="MM / YY"
                          className="input"
                          value={cardExp}
                          onInput={handleCardExp}
                          required
                        />
                        <label
                          htmlFor="card-number-exp"
                          className="input__label"
                        >
                          Exp
                        </label>
                      </div>
                      <div className="input__container">
                        <input
                          type="text"
                          id="card-number-cvv"
                          maxLength="3"
                          className="input"
                          placeholder="CVV"
                          required
                        />
                        <label
                          htmlFor="card-number-cvv"
                          className="input__label"
                        >
                          CVV
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input__container flex-column">
                  <input
                    type="text"
                    id="card-holder"
                    placeholder="Enter Card Holder Name"
                    className="input"
                    required
                  />
                  <label htmlFor="card-holder" className="input__label">
                    Card Holder Name
                  </label>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>

      <div className="paymentMethod__btnContainer flex-row">
        <button
          className="btn flex-row"
          onClick={() => history.push("/delivery")}
        >
          <svg
            width="10"
            height="10"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 4L8 12L16 20"
              stroke="black"
              strokeWidth="4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Back to Delivery
        </button>
        <button
          className={`btn ${
            paymentMethod !== "Choose your payment method"
              ? "primary active"
              : "no-active"
          } `}
          onClick={handlePayment}
        >
          pay
        </button>
      </div>
    </div>
  );
};

export default PaymentMethod;
