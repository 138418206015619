import { useSelector } from "react-redux";
import MissionCard from "../MissionCard";
import "./Mission.css";

const baseURLBadges =
  "https://gist.githubusercontent.com/faustabmnyh/159ed0168079cd4b433fe943458df03b/raw/b1827f93bf87d9bf9ec8be6cfe30168dec1f2546/";

const Mission = () => {
  const { tier } = useSelector((state) => state.mission);
  return (
    <div className="mission">
      <div className="mission__mainEmblem">
        <MissionCard
          title="Radiant"
          status="Progress"
          completed="0"
          baseURLBadges={baseURLBadges}
          emblem={`/images/emblems/radiant.png`}
          clicked
        />
        <MissionCard
          title="Genius"
          completed="0"
          status="Locked"
          baseURLBadges={baseURLBadges}
          emblem={`/images/emblems/genius.png`}
          clicked
        />
        <MissionCard
          title="Legend"
          completed="0"
          status="Locked"
          baseURLBadges={baseURLBadges}
          emblem={`/images/emblems/legend.png`}
          clicked
        />
        <MissionCard
          title="Conqueror"
          completed="0"
          status="Locked"
          baseURLBadges={baseURLBadges}
          emblem={`/images/emblems/conqueror.png`}
          clicked
        />
      </div>
      <h3 className="mission__title">Level Missions</h3>
      <div className="mission__allCategory">
        <MissionCard
          title={`${tier} 1`}
          status="Progress"
          completed="0"
          baseURLBadges={baseURLBadges}
          emblem={`${baseURLBadges}badge-${tier.toLowerCase()}-1.svg`}
        />
        <MissionCard
          title={`${tier} 2`}
          status="Progress"
          completed="0"
          baseURLBadges={baseURLBadges}
          emblem={`${baseURLBadges}badge-${tier.toLowerCase()}-2.svg`}
        />
        <MissionCard
          title={`${tier} 3`}
          completed="0"
          status="Progress"
          baseURLBadges={baseURLBadges}
          emblem={`${baseURLBadges}badge-${tier.toLowerCase()}-3.svg`}
        />
        <MissionCard
          title={`${tier} 4`}
          completed="0"
          status="Progress"
          baseURLBadges={baseURLBadges}
          emblem={`${baseURLBadges}badge-${tier.toLowerCase()}-4.svg`}
        />
        <MissionCard
          title={`${tier} 5`}
          completed="0"
          status="Progress"
          baseURLBadges={baseURLBadges}
          emblem={`${baseURLBadges}badge-${tier.toLowerCase()}-5.svg`}
        />
        <MissionCard
          completed="0"
          title={`${tier} 6`}
          status="Progress"
          baseURLBadges={baseURLBadges}
          emblem={`${baseURLBadges}badge-${tier.toLowerCase()}-6.svg`}
        />
        <MissionCard
          title={`${tier} 7`}
          completed="0"
          status="Progress"
          baseURLBadges={baseURLBadges}
          emblem={`${baseURLBadges}badge-${tier.toLowerCase()}-7.svg`}
        />
        <MissionCard
          title={`${tier} 8`}
          completed="0"
          status="Progress"
          baseURLBadges={baseURLBadges}
          emblem={`${baseURLBadges}badge-${tier.toLowerCase()}-8.svg`}
        />
        <MissionCard
          title={`${tier} 9`}
          completed="0"
          status="Progress"
          baseURLBadges={baseURLBadges}
          emblem={`${baseURLBadges}badge-${tier.toLowerCase()}-9.svg`}
        />
        <MissionCard
          title={`${tier} 10`}
          completed="0"
          status="Progress"
          baseURLBadges={baseURLBadges}
          emblem={`${baseURLBadges}badge-${tier.toLowerCase()}-10.svg`}
        />
      </div>
    </div>
  );
};

export default Mission;
