import {
  ADD_NEW_CONNECTION,
  REMOVE_CONNECTION,
} from "../constants/connectionConstants";

export const addNewConnection = (connection) => async (dispatch, getState) => {
  dispatch({
    type: ADD_NEW_CONNECTION,
    payload: connection,
  });
  localStorage.setItem(
    "gsprklConnections",
    JSON.stringify(getState().connection.connections)
  );
};

export const removeFromConnection = (productId) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_CONNECTION,
    payload: productId,
  });
  localStorage.setItem(
    "gsprklConnections",
    JSON.stringify(getState().connection.connections)
  );
};
