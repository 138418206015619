import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { cartReducer } from "./reducers/cartReducers";
import { connectionReducer } from "./reducers/connectionReducers";
import { menuReducer } from "./reducers/menuReducer";
import { modeReducer } from "./reducers/modeReducer";
import { orderCreatedReducer } from "./reducers/orderReducers";
import { quizReducer } from "./reducers/quizReducers";
import { searchImagesUnsplashReducer } from "./reducers/searchReducers";
import { settingReducer } from "./reducers/settingReducer";
import { communityReducer } from "./reducers/communityReducer";
import { topicReducer } from "./reducers/topicReducers";
import { missionReducer } from "./reducers/missionReducer";
import { categoriesReducer } from "./reducers/categoriesReducers";

const initalState = {
  topic: {
    topicVideos: localStorage.getItem("gsprklTopicVideos")
      ? JSON.parse(localStorage.getItem("gsprklTopicVideos"))
      : [],
  },
  cart: {
    cartItems: localStorage.getItem("gsprklCartItems")
      ? JSON.parse(localStorage.getItem("gsprklCartItems"))
      : [],
  },
  connection: {
    connections: localStorage.getItem("gsprklConnections")
      ? JSON.parse(localStorage.getItem("gsprklConnections"))
      : [],
  },
  orderCreate: {
    orders: localStorage.getItem("gsprklOrderItems")
      ? JSON.parse(localStorage.getItem("gsprklOrderItems"))
      : [],
  },
  quiz: {
    quizzes: localStorage.getItem("gsprklQuizzes")
      ? JSON.parse(localStorage.getItem("gsprklQuizzes"))
      : [],
  },
  community: {
    communities: localStorage.getItem("gsprklCommunities")
      ? JSON.parse(localStorage.getItem("gsprklCommunities"))
      : [],
  },
};

const reducer = combineReducers({
  topic: topicReducer,
  cart: cartReducer,
  menu: menuReducer,
  mode: modeReducer,
  setting: settingReducer,
  connection: connectionReducer,
  orderCreate: orderCreatedReducer,
  unsplashImages: searchImagesUnsplashReducer,
  quiz: quizReducer,
  community: communityReducer,
  mission: missionReducer,
  categories: categoriesReducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initalState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
