import { ADD_NEW_QUIZ, REMOVE_QUIZ } from "../constants/quizConstants";

export const quizReducer = (state = { quizzes: [] }, action) => {
  switch (action.type) {
    case ADD_NEW_QUIZ:
      return {
        ...state,
        quizzes: [...state.quizzes, action.payload],
      };
    case REMOVE_QUIZ:
      return {
        ...state,
        quizzes: state.quizzes.filter((quiz) => quiz.id !== action.payload),
      };
    default:
      return state;
  }
};
