import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { buyNow } from "../../actions/orderActions";
import "./TopupGiftPopup.css";

const TopupGiftPopup = ({ setOpenPopup, type }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [topup, setTopup] = useState(0);
  const rangeInput = useRef(null);
  const thumb = useRef(null);
  const range = useRef(null);
  const rangeValue = useRef(null);
  const [valueRangeInput, setValueRangeInput] = useState(0);
  const handleSliderInput = (e) => {
    let percent = Number(e.target.value);
    thumb.current.style.left = percent / 10 + "%";
    rangeValue.current.style.left = `${percent / 10}%`;
    thumb.current.style.transform = `translateY(-50%) translateX(-${
      percent / 10
    }%)`;
    rangeValue.current.style.transform = `translateX(-${percent / 10}%)`;
    rangeValue.current.classList.add("show");
    setTopup(0);
    setValueRangeInput(percent);
  };

  const handleClose = (e) => {
    if (e.target !== e.currentTarget) return;
    setOpenPopup(false);
  };

  const handleChangeTopup = (name) => {
    setTopup(name);
    setValueRangeInput(0);
    thumb.current.style.left = "0";
    rangeValue.current.style.left = "0";
    thumb.current.style.transform = `translateY(-50%) translateX(0)`;
    rangeValue.current.style.transform = `translateX(0)`;
  };

  const handleCheckout = () => {
    // send to payment, and also send the amount that want to be paid
    history.push("/payment");
    if (topup !== 0 || valueRangeInput !== 0) {
      setOpenPopup(false);
      dispatch(
        buyNow({
          id: Date.now(),
          title: "Topup balance",
          descrption:
            type === "Topup"
              ? `Adding $${valueRangeInput || topup} to my balance account`
              : `Give $${valueRangeInput || topup} to Teacher Jane`,
          price: valueRangeInput || topup,
        })
      );
      setValueRangeInput(0);
      setTopup(0);
    }
    // if not show the error
  };

  return (
    <div className="topupGiftPopup" onClick={handleClose}>
      <div className="topupGiftPopup__container flex-column">
        <h1>
          {type === "Topup"
            ? "Add Balance To Your Account"
            : "Send A Gift For Appreciation"}
        </h1>
        <div className="topupGiftPopup__radio flex-row">
          <label
            htmlFor="topup-first"
            className={`flex-column ${topup === 25 && "checked"}`}
          >
            <span className="topupGiftPopup__radioTitle">Monthly {type}</span>
            <div className="flex-row">
              <input
                type="radio"
                className="topupGiftPopup__radioInput"
                id="topup-first"
                onChange={() => handleChangeTopup(25)}
                checked={topup === 25}
                name="topupGiftPopup"
              />
              <span className="topupGiftPopup__radioControl"></span>
              <div className="topupGiftPopup__radioText flex-row">
                <strong>$25 </strong> <span> / Month</span>
              </div>
            </div>
          </label>
          <label
            htmlFor="topup-second"
            className={`flex-column ${topup === 5 && "checked"}`}
          >
            <span className="topupGiftPopup__radioTitle">Monthly {type}</span>
            <div className="flex-row">
              <input
                type="radio"
                className="topupGiftPopup__radioInput"
                id="topup-second"
                onChange={() => handleChangeTopup(5)}
                checked={topup === 5}
                name="topupGiftPopup"
                data-value={5}
              />
              <span className="topupGiftPopup__radioControl"></span>
              <div className="topupGiftPopup__radioText flex-row">
                <strong>$5</strong> <span> / Month</span>
              </div>
            </div>
          </label>
        </div>
        <div className="topupGiftPopup__sliderSection flex-column">
          <h3>Or, use the slider for one time {type}</h3>
          <div className="topupGiftPopup__sliderPrice">
            <input
              type="range"
              max="1000"
              ref={rangeInput}
              value={valueRangeInput}
              onInput={handleSliderInput}
              onBlur={() => rangeValue.current.classList.remove("show")}
              className="topupGiftPopup__slider"
            />
            <div className="topupGiftPopup__sliderContainer">
              <div className="topupGiftPopup__track">
                <div className="topupGiftPopup__range" ref={range}></div>
                <div>
                  <div className="topupGiftPopup__thumb" ref={thumb}></div>
                  <div className="topupGiftPopup__value" ref={rangeValue}>
                    ${valueRangeInput}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-row">
            <span>$0</span>
            <span>$1000</span>
          </div>
        </div>
        <button
          className={`btn ${
            (topup !== 0 || valueRangeInput !== 0) && "primary"
          } flex-row`}
          onClick={handleCheckout}
        >
          {type === "Topup" ? "Continue to checkout" : "Send Gift To Teacher"}
          <svg
            width="8"
            height="12"
            viewBox="0 0 16 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 2L13.5 13L2.5 24"
              stroke="white"
              stroke-opacity="0.9"
              stroke-width="5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default TopupGiftPopup;
