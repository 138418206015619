import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import "./SettingAccount.css";

const SettingAccount = () => {
  const [fullName, setFullName] = useState("Abraham Benjamin");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [discordServerLink, setDiscordServerLink] = useState("");
  const [showCountry, setShowCountry] = useState(false);
  const [saveChangesFullName, setSaveChangesFullName] = useState(false);
  const [saveChangesEmail, setSaveChangesEmail] = useState(false);
  const [saveChangesUsername, setSaveChangesUsername] = useState(false);
  const [saveChangesPhoneNumber, setSaveChangesPhoneNumber] = useState(false);
  const [saveChangesGender, setSaveChangesGender] = useState(false);
  const [showLanguageKnow, setShowLanguageKnow] = useState(false);
  const [saveChangesCountry, setSaveChangesCountry] = useState(false);
  const [saveChangesLanguagesKnow, setSaveChangesLanguagesKnow] =
    useState(false);
  const [saveChangesLanguagesInterested, setSaveChangesLanguagesInterested] =
    useState(false);
  const [saveChangesDiscordServerLink, setSaveChangesDiscordServerLink] =
    useState(false);
  const [selectedLanguagesKnow, setSelectedLanguagesKnow] = useState([]);
  const [selectedLanguagesInterested, setSelectedLanguagesInterested] =
    useState([]);
  const [showLanguageInterested, setShowLanguageInterested] = useState(false);
  const [active, setActive] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [gender, setGender] = useState("");
  const [showGender, setShowGender] = useState(false);
  const [errors, setErrors] = useState({});

  const languageOptions = [
    {
      id: "1",
      language: "English",
    },
    {
      id: "2",
      language: "Spanish",
    },
    {
      id: "3",
      language: "French",
    },
    {
      id: "4",
      language: "Hindi",
    },
    {
      id: "5",
      language: "Bengali",
    },
    {
      id: "6",
      language: "Arabic",
    },
    {
      id: "7",
      language: "Portuguese",
    },
    {
      id: "8",
      language: "Russian",
    },
    {
      id: "9",
      language: "Japanese",
    },
  ];

  const handleChooseCountry = (name) => {
    setCountry(name);
    setShowCountry(false);
  };

  const handleSelectedLanguagesKnow = (language) => {
    setSaveChangesLanguagesKnow(true);
    setActive("");
    setSelectedLanguagesKnow((selectedLanguages) => {
      const existLanguage = selectedLanguages.find(
        (selectedLanguage) => selectedLanguage.language === language.language
      );
      if (existLanguage) {
        return selectedLanguages.filter(
          (selectedLanguage) => selectedLanguage.id !== language.id
        );
      }
      return [...selectedLanguages, language];
    });
  };

  const handleSelectedLanguagesInterested = (language) => {
    setSaveChangesLanguagesInterested(true);
    setActive("");
    setSelectedLanguagesInterested((selectedLanguages) => {
      const existLanguage = selectedLanguages.find(
        (selectedLanguage) => selectedLanguage.language === language.language
      );
      if (existLanguage) {
        return selectedLanguages.filter(
          (selectedLanguage) => selectedLanguage.id !== language.id
        );
      }
      return [...selectedLanguages, language];
    });
  };

  // get countries
  async function fetchData() {
    const request = await axios.get("https://restcountries.com/v3.1/all");
    setCountries(request.data);
  }

  const handleChangeCountry = (e) => {
    setCountry(e.target.value);
    fetchData();
    const items = document.querySelectorAll(".settingAccount__countryLists li");
    const inputValue = e.target.value.toUpperCase();
    items.forEach((item, i) => {
      let txtValue = item.textContent || item.innerText;
      if (txtValue.toUpperCase().indexOf(inputValue) > -1) {
        item.style.display = "";
      } else {
        item.style.display = "none";
      }
    });
  };

  const handleSelectGender = (gender) => {
    setShowGender(false);
    setGender(gender);
    setSaveChangesGender(true);
    setActive("");
  };

  const handleSave = (e) => {
    setActive(e.currentTarget.id);
  };

  const handleGender = (e) => {
    handleSave(e);
    setShowGender(true);
  };

  const handleLanguagesKnow = (e) => {
    handleSave(e);
    setShowLanguageKnow(true);
  };
  const handleLanguagesInterested = (e) => {
    handleSave(e);
    setShowLanguageInterested(true);
  };

  // handle change input
  const handleDiscord = (e) => {
    const regexDiscordLink =
      /(https?:\/\/)?(www\.)?(discord\.(gg|io|me|li)|discordapp\.com\/invite)\/.+[a-z]/g;
    if (!e.target.value.match(regexDiscordLink)) {
      setErrors({
        destinationLink:
          "Your link not matches, link must be discord.gg/abcdef",
      });
    } else {
      setErrors({});
    }
    return setDiscordServerLink(e.target.value);
  };

  useEffect(() => {
    if (saveChangesFullName) {
      setTimeout(() => {
        setSaveChangesFullName(false);
      }, 1000);
    }

    if (saveChangesPhoneNumber) {
      setTimeout(() => {
        setSaveChangesPhoneNumber(false);
      }, 1000);
    }

    if (saveChangesEmail) {
      setTimeout(() => {
        setSaveChangesEmail(false);
      }, 1000);
    }

    if (saveChangesGender) {
      setTimeout(() => {
        setSaveChangesGender(false);
      }, 1000);
    }
    if (saveChangesLanguagesKnow) {
      setTimeout(() => {
        setSaveChangesLanguagesKnow(false);
      }, 1000);
    }
    if (saveChangesLanguagesInterested) {
      setTimeout(() => {
        setSaveChangesLanguagesInterested(false);
      }, 1000);
    }

    if (saveChangesCountry) {
      setTimeout(() => {
        setSaveChangesCountry(false);
      }, 1000);
    }
    if (saveChangesUsername) {
      setTimeout(() => {
        setSaveChangesUsername(false);
      }, 1000);
    }
    if (saveChangesDiscordServerLink) {
      setTimeout(() => {
        setSaveChangesDiscordServerLink(false);
      }, 1000);
    }

    window.onclick = (e) => {
      if (active === "full-name") {
        if (e.target.closest("#full-name") === null) {
          setSaveChangesFullName(true);
          setActive("");
        }
      }

      if (active === "setting-languages-know") {
        if (
          e.target.closest(".settingAccount__languagesInputContainer") === null
        ) {
          setActive("");
          setSaveChangesLanguagesKnow(true);
          setShowLanguageKnow(false);
        }
      }
      if (active === "setting-languages-interested") {
        if (
          e.target.closest(
            ".settingAccount__languagesInputContainer.interested-in"
          ) === null
        ) {
          setActive("");
          setSaveChangesLanguagesInterested(true);
          setShowLanguageInterested(false);
        }
      }
      if (active === "setting-account-gender") {
        if (e.target.closest(".settingAccount__gender") === null) {
          setShowGender(false);
          setSaveChangesGender(true);
          setActive("");
        }
      }

      if (active === "email") {
        if (e.target.closest("#email") === null) {
          setSaveChangesEmail(true);
          setActive("");
        }
      }
      if (active === "phone-number") {
        if (e.target.closest("#phone-number") === null) {
          setSaveChangesPhoneNumber(true);
          setActive("");
        }
      }
      if (active === "username") {
        if (e.target.closest("#username") === null) {
          setSaveChangesUsername(true);
          setActive("");
        }
      }

      if (active === "setting-country") {
        if (e.target.closest("#setting-country") === null) {
          setShowCountry(false);
          setSaveChangesCountry(true);
          setActive("");
        }
      }

      if (active === "discord-server-link") {
        if (e.target.closest("#discord-server-link") === null) {
          setSaveChangesDiscordServerLink(true);
          setActive("");
        }
      }
    };
  }, [
    saveChangesFullName,
    saveChangesEmail,
    active,
    saveChangesLanguagesKnow,
    saveChangesLanguagesInterested,
    saveChangesGender,
    saveChangesDiscordServerLink,
    saveChangesUsername,
    saveChangesCountry,
    saveChangesPhoneNumber,
  ]);

  return (
    <div className="settingAccount">
      <form className="settingAccount__formContainer">
        <div className="settingAccount__profileInputSections flex-row">
          <div class="input__container">
            <input
              type="text"
              class="input"
              required
              id="full-name"
              placeholder="Enter your full name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              onClick={handleSave}
            />
            <label for="full-name" class="input__label">
              Full Name
            </label>
            {saveChangesFullName && (
              <small className="settinAccount__changes">Change saved</small>
            )}
          </div>
          <div className="input__container flex-column">
            <input
              type="text"
              id="username"
              value={username}
              className="input"
              placeholder="Enter your username"
              required
              onClick={handleSave}
              onChange={(e) => setUsername(e.target.value)}
            />
            <label htmlFor="username" className="input__label">
              Username
            </label>
            {saveChangesUsername && (
              <small className="settinAccount__changes">Change saved</small>
            )}
          </div>
        </div>

        <div className="settingAccount__profileInputSections flex-row">
          <div className="input__container readOnly">
            <input
              type="text"
              id="user-age"
              value={15}
              maxLength="2"
              placeholder="How old are you?"
              className="input"
              required
            />
            <label htmlFor="user-age" className="input__label">
              What's your Age
            </label>
          </div>
          <div
            className={`input__container ${showGender && "open-dropdown"} ${
              gender !== "" && "selected-gender"
            } settingAccount__gender`}
          >
            <div
              onClick={handleGender}
              id="setting-account-gender"
              className="input settingAccount__dropdown"
            >
              {gender}
            </div>
            <ul
              className={`settingAccount__dropdownLists ${
                showGender && "show"
              }`}
            >
              <li>
                <input type="radio" name="gender" id="setting-gender-male" />
                <label
                  onClick={() => handleSelectGender("male")}
                  htmlFor="setting-gender-male"
                >
                  Male
                </label>
              </li>
              <li>
                <input type="radio" id="setting-gender-female" name="gender" />
                <label
                  onClick={() => handleSelectGender("female")}
                  htmlFor="setting-gender-female"
                >
                  Female
                </label>
              </li>
            </ul>

            <label
              htmlFor="languages-one"
              className="input__label"
              onClick={() => setShowGender(true)}
            >
              {gender !== "" && (
                <img
                  src={`https://gist.githubusercontent.com/faustabmnyh/62245f19337b13c5195999e7acbb525d/raw/ae63ef6b6f0607c839c3f0dfae2f4b70a517fccb/${gender}.svg`}
                  alt={gender}
                />
              )}
              Choose your gender
            </label>
            {saveChangesGender && (
              <small className="settinAccount__changes">Change saved</small>
            )}
          </div>
        </div>

        <div className="settingAccount__profileInputSections flex-row">
          <div className="input__container flex-column">
            <input
              type="text"
              id="email"
              value={email}
              className="input"
              onClick={handleSave}
              placeholder="Enter your email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="email" className="input__label">
              Email
            </label>
            {saveChangesEmail && (
              <small className="settinAccount__changes">Change saved</small>
            )}
          </div>
          <div className="input__container settingAccount__country">
            <input
              type="text"
              placeholder="Enter your country name"
              required
              value={country}
              className="input"
              onChange={handleChangeCountry}
              id="setting-country"
              onClick={handleSave}
              onKeyPress={() => setShowCountry(true)}
            />
            <label htmlFor="setting-country" className="input__label">
              Country
            </label>
            <ul
              className={`settingAccount__countryLists ${
                showCountry && "show"
              }`}
            >
              {countries.map((country) => (
                <li
                  key={country.car.cca2}
                  onClick={() => handleChooseCountry(country.name.common)}
                >
                  {country.name.common}
                </li>
              ))}
            </ul>
            {saveChangesCountry && (
              <small className="settinAccount__changes">Change saved</small>
            )}
          </div>
        </div>

        <div className="settingAccount__profileInputSections flex-row">
          <div
            className={`input__container ${
              showLanguageKnow && "open-language"
            } ${
              selectedLanguagesKnow.length && "selected-language"
            } settingAccount__languagesInputContainer`}
            id="setting-languages-know"
            onClick={handleLanguagesKnow}
          >
            <div className="input settingAccount__dropdown">
              <ul className="settingAccount__selectedLanguages">
                {selectedLanguagesKnow.map((selectedLanguage) => (
                  <li className="settingAccount__selectedLanguage">
                    {selectedLanguage.language}
                  </li>
                ))}
              </ul>
            </div>
            <ul
              className={`settingAccount__dropdownLists ${
                showLanguageKnow && "show"
              }`}
            >
              {languageOptions.map((languageOption) => (
                <li key={languageOption.id}>
                  <input
                    type="checkbox"
                    id={`setting-${languageOption.language}-${languageOption.id}`}
                  />
                  <label
                    onClick={() => handleSelectedLanguagesKnow(languageOption)}
                    htmlFor={`setting-${languageOption.language}-${languageOption.id}`}
                  >
                    <span></span>
                    {languageOption.language}
                  </label>
                </li>
              ))}
            </ul>

            <label htmlFor="setting-languages-one" className="input__label">
              Language you know
            </label>
            {saveChangesLanguagesKnow && (
              <small className="settinAccount__changes">Change saved</small>
            )}
          </div>
          <div
            className={`input__container ${
              showLanguageInterested && "open-language"
            } ${
              selectedLanguagesInterested.length && "selected-language"
            } settingAccount__languagesInputContainer interested-in`}
            id="setting-languages-interested"
            onClick={handleLanguagesInterested}
          >
            <div className="input settingAccount__dropdown">
              <ul className="settingAccount__selectedLanguages">
                {selectedLanguagesInterested.map((selectedLanguage) => (
                  <li className="settingAccount__selectedLanguage">
                    {selectedLanguage.language}
                  </li>
                ))}
              </ul>
            </div>
            <ul
              className={`settingAccount__dropdownLists ${
                showLanguageInterested && "show"
              }`}
            >
              {languageOptions.map((languageOption) => (
                <li key={languageOption.id}>
                  <input
                    type="checkbox"
                    id={`setting-${languageOption.language}-${languageOption.id}-interested-in`}
                  />
                  <label
                    onClick={() =>
                      handleSelectedLanguagesInterested(languageOption)
                    }
                    htmlFor={`setting-${languageOption.language}-${languageOption.id}-interested-in`}
                  >
                    <span></span>
                    {languageOption.language}
                  </label>
                </li>
              ))}
            </ul>

            <label htmlFor="setting-languages-two" className="input__label">
              Language you interested in
            </label>
            {saveChangesLanguagesInterested && (
              <small className="settinAccount__changes">Change saved</small>
            )}
          </div>
        </div>

        <div className="settingAccount__profileInputSections flex-row">
          <div className="input__container">
            <input
              type="text"
              id="discord-server-link"
              onClick={handleSave}
              onChange={handleDiscord}
              value={discordServerLink}
              className="input"
              placeholder="Invitation Link (Set to never expire)"
              required
            />
            <label htmlFor="discord-server-link" className="input__label">
              Discord Server Link
            </label>
            {saveChangesDiscordServerLink && (
              <small className="settinAccount__changes">Change saved</small>
            )}
            {errors && errors.destinationLink && (
              <span className="text-input-error">{errors.destinationLink}</span>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default SettingAccount;
