import { ADD_NEW_COMMUNITY, REMOVE_COMMUNITY } from "../constants/communityConstants";

export const communityReducer = (state = { communities: [] }, action) => {
  switch (action.type) {
    case ADD_NEW_COMMUNITY:
      return {
        ...state,
        communities: [...state.communities, action.payload],
      };
    case REMOVE_COMMUNITY:
      return {
        ...state,
        communities: state.communities.filter((community) => community.id !== action.payload),
      };
    default:
      return state;
  }
};
