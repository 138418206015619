import {
  ADD_NEW_CONNECTION,
  REMOVE_CONNECTION,
} from "../constants/connectionConstants";

export const connectionReducer = (state = { connections: [] }, action) => {
  switch (action.type) {
    case ADD_NEW_CONNECTION:
      const connect = action.payload;
      const existProduct = state.connections.find(
        (connection) => connection.id === connect.id
      );
      if (existProduct) {
        return {
          ...state,
          connections: state.connections.map((connection) =>
            connection.id === existProduct.id ? connect : connection
          ),
        };
      } else {
        return {
          ...state,
          connections: [...state.connections, connect],
        };
      }
    case REMOVE_CONNECTION:
      return {
        ...state,
        connections: state.connections.filter(
          (connection) => connection.id !== action.payload
        ),
      };
    default:
      return state;
  }
};
